import { capitalize, upperFirst } from 'lodash';
import DefaultSizeBrandGrid from '../../brand/DefaultSizeBrandGrid';
import { SmallBrandGrid, LargeBrandGrid } from '../../brand/OtherBrands';
import Collection from '../../../containers/collection/Collection';
import Tag from '../../../containers/tag/Tag';
import Ad from '../../../containers/ad';
import FreeProductCard from '../../Product/FreeProductCard';
import LargeProductGrid from '../../Product/LargeProductGrid';
import DefaultSizeProductGrid from '../../Product/DefaultSizeProductGrid';
import { DefaultSizeVariantGrid, LargeVariantGrid, SmallVariantGrid } from '../../variants';
import UserPost from '../../../containers/myProfile/UserPost';
import SearchOffer from '../../../containers/offer/SearchOffer';
import { DefaultSizeVideoGrid } from '../../media';
import { SmallVideoGrid, LargeVideoGrid } from '../../media/OtherMediaCards';
import { DefaultSizeArtistGrid } from '../../Artist';
import {
  SmallArtistGrid,
  LargeArtistGrid,
} from '../../Artist/OtherArtistCards';
import IngredientCollectionCard from '../../Collection/IngredientCollectionCard';
import Utility from '../../../utils/Utility';
import { isDesktop } from '../../../utils/BooleanUtility';
import SlimProductGrid from '../../Product/SlimProductGrid';
import DynamicProductGrid from '../../Product/DynamicProductGrid';
import DynamicVariantGrid from '../../variants/DynamicVariantGrid';
import ShopifyProductGrid from '../../ShopifyProduct/ShopifyProductGrid';
import ShopifyVariantGrid from '../../ShopifyProduct/ShopifyVariantGrid';
import FeatureCard from '../../feature/FeatureCard';

const Components = {
  product_category: Collection,
  list: Collection,
  rectangularGrid: Collection,
  brand: Collection,
  ingredient: IngredientCollectionCard,
  tag: Tag,
  ad: Ad,
  userPost: UserPost,
  offer: SearchOffer,
  DefaultSizeProductGrid,
  DefaultSizeVariantGrid,
  LargeProductGrid,
  SmallProductGrid: DefaultSizeProductGrid,
  SlimProductGrid,
  LargeVariantGrid,
  SmallVariantGrid,
  DefaultSizeVideoGrid,
  SmallVideoGrid,
  LargeVideoGrid,
  DefaultSizeArtistGrid,
  SmallArtistGrid,
  LargeArtistGrid,
  DefaultSizeBrandGrid,
  SmallBrandGrid,
  LargeBrandGrid,
  DynamicProductGrid,
  DynamicVariantGrid,
  shopify_product: ShopifyProductGrid,
  shopify_variant: ShopifyVariantGrid,
  feature: FeatureCard,
};

export const getGridComponent = (
  cardType,
  content,
  size,
  freeProductScreen,
) => {
  let ContainerComponent = Components[content];
  if (
    content === 'product' ||
    content === 'sku' ||
    content === 'media' ||
    content === 'artist' ||
    content === 'brand'
  ) {
    ContainerComponent =
      Components[`${upperFirst(size)}${capitalize(cardType)}Grid`];
  }

  if (content === 'mixed') {
    ContainerComponent = Components[cardType];
  }

  if (freeProductScreen) {
    ContainerComponent = FreeProductCard;
  }

  return ContainerComponent;
};

export const getCardHeight = (
  cardType,
  content,
  size,
  freeProductScreen,
  columns = 2,
  options = {},
) => {
  const ContainerComponent = getGridComponent(
    cardType,
    content,
    size,
    freeProductScreen,
  );

  switch (ContainerComponent) {
    case Collection:
    case DefaultSizeBrandGrid:
      return Utility.getCardDimentions(columns)[1] + 8;
    case DefaultSizeProductGrid:
    case SmallVariantGrid:
    case DefaultSizeVariantGrid:
      return Utility.getDynamicWidthForGrid(isDesktop() ? 4 : 2, 16) + 124;
    case DynamicProductGrid:
    case DynamicVariantGrid:
      return ContainerComponent.getComponentHeight(options);
    case DefaultSizeVideoGrid:
    case SmallVideoGrid:
    case LargeVideoGrid:
    case SlimProductGrid:
    case LargeProductGrid:
    case LargeVariantGrid:
    case SearchOffer:
    case FreeProductCard:
    case Tag:
      return ContainerComponent.getComponentHeight(columns);
    case DefaultSizeArtistGrid:
    case SmallArtistGrid:
    case LargeArtistGrid:
      return Utility.getDynamicWidthForGrid(isDesktop() ? columns * 2 : columns, 24) + 35;
    case UserPost:
      return (9 / 16) * Utility.getDynamicWidthForGrid(isDesktop() ? 4 : 2, 16) + 44;
    case Ad:
      return Utility.getDynamicWidthForGrid(3, 24);
    case ShopifyVariantGrid:
    case ShopifyProductGrid:
      return ContainerComponent.getComponentHeight(size);
    default:
      return 0;
  }
};
