import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CLOUDFRONT_IDENTIFIER } from '../../config/Constants';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import withNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop, isPresent, isWebMobile } from '../../utils/BooleanUtility';

class PostCard extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { image_url = '', image_webp_url = '', desktop_image_webp_url: desktopImageUrl = '' } = {} } =
      this.props;
    let image = Utility.isPresent(image_url) ? image_url : image_webp_url;
    image = isDesktop() && isPresent(desktopImageUrl) ? desktopImageUrl : image;

    this.debouncedNavigate = _.debounce(this.onActionPressed, 200, {
      leading: true,
      trailing: false,
    });

    this.newImageUrl = getMinifiedImage(image, styles.postBannerImage.width, styles.postBannerImage.height);
  }

  navigateToScreen = ({ route, slug, extra = {} }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      params: {
        destination: slug, // webview reads data from destination
        slug,
        extra,
      },
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      previousScreen,
      listIndex = 0,
      itemData: { destination, dynamic_destination, slug, external_link, title = '' } = {},
      allVideosId = '',
      listContent,
      listName,
      listSlug = '',
      listId = '',
      listDisplay = '',
      index,
    } = this.props;

    const url = Utility.isPresent(external_link) ? external_link : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listDisplay,
        Utility.isPresent(listName) ? listName : title,
        listIndex,
        '',
        '',
        listContent,
        id,
        slug,
        listSlug,
      );
    }

    if (Utility.isBlank(dynamic_destination)) {
      DynamicLinkManager.handleDynamicLink(
        url,
        this.navigateToScreen,
        destination,
      );
    } else {
      navigation.push('PaginatedList', {
        id: allVideosId,
        dynamicDestinationSlug: dynamic_destination,
      });
    }
  };

  render() {
    const { itemData = {}, itemData: { image_url = '', heading } = {} } =
      this.props;
    const { title, body, cta_text, cta_color } = itemData;

    if (Utility.isBlank(itemData) || Utility.isBlank(image_url)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.body}>{body}</Text>
          </View>
          <FastImageView
            resizeMode='cover'
            source={this.newImageUrl}
            style={styles.postBannerImage}
          />
        </View>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(PostCard);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: 12,
    backgroundColor: colors.white,
    marginTop: 8,
    marginLeft: isWebMobile() ? 32 : 12,
    height: isDesktop() ? (Utility.getScreenWidth() - 24) / 2.5 : (Utility.getScreenWidth() - 24) / 1.7,
    borderRadius: 16,
  },
  title: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    lineHeight: 28.13,
  },
  body: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 16.41,
    marginTop: 8,
    width: getScreenWidth() * 0.4,
  },
  contentContainer: {
    width: getScreenWidth() * 0.5,
    paddingTop: 8,
    paddingHorizontal: 12,
  },
  postBannerImage: {
    height: isDesktop() ? (Utility.getScreenWidth() - 24) / 2.5 : (Utility.getScreenWidth() - 24) / 1.7,
    overflow: 'hidden',
    borderColor: colors.border,
    width: getScreenWidth() * 0.48,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
});
