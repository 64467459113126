import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Animated,
  Clipboard,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import Toast from 'react-native-easy-toast';
import Share from '../../libraries/ReactNativeShare';
import { debounce } from 'lodash';
import { Dropdown } from 'react-native-material-dropdown';
import {
  LAYOUT,
  AVAILABLE_BUILD_TYPES,
  EMPTY_LIST_URL_FOR_SHARE,
} from '../../config/Constants';
import { ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { withMaybe } from '../../lib/Monads';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FollowArtistButton from '../Artist/FollowArtistButton';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { BagIcon } from '../header';
import FastImageView from '../FastImageView';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';

class CustomNavigationHeader extends Component {
  constructor(props) {
    super(props);
    this.navigationHeaderHeight = Utility.isIOS() ? 44 + Utility.topInset : 56;
    this.dropDownOption = [
      { type: 'share', value: 'Share' },
      { type: 'claim', value: 'Claim Profile' },
      { type: 'report', value: 'Report Abuse' },
    ];
    this.dropdownRef = React.createRef();
    this.searchButtonContainerStyle = [
      ProductDetailStyles.navigationShareButtonContainer,
      { right: 12 },
    ];
    const { screenName } = this.props;
    const cartButtonRightSpace =
      screenName === SCREEN_CONSTANTS.NOTIFICATION_CENTER ? 12 : 50;
    this.cartButtonContainerStyle = [
      ProductDetailStyles.navigationShareButtonContainer,
      { right: cartButtonRightSpace },
    ];

    this.scanButtonStyle = [
      ProductDetailStyles.navigationShareButtonContainer,
      { right: 10 },
    ];
  }

  showLikeButtonCondition = (props) =>
    props.previousScreen === 'dashboard' ||
    Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
    props.previousScreen === SCREEN_CONSTANTS.COLLECTION ||
    props.previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
    props.morePageDisplay === 'full-page-banner';

  likeButton = withMaybe(this.showLikeButtonCondition)(LikeButton);

  logAnalyticsEvent = (...meta) => {
    const [
      screen_name = '',
      id,
      name = '',
      contentType,
      medium = '',
      skuId = '',
    ] = meta;

    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: screen_name,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: name,
      [EventParameterKey.ITEM_TYPE]: contentType,
      [EventParameterKey.MEDIUM]: medium,
    });
    AnalyticsManager.logFirebaseEvent(EventType.googleRemarketingEvents.SHARE, {
      [EventParameterKey.CONTENT_TYPE]: contentType,
      [EventParameterKey.ID]: Utility.isPresent(skuId) ? skuId : id,
    });
  };

  onSocialShareOptionTapped = (medium) => {
    const {
      shareUrl,
      isProduct = false,
      name,
      image,
      brand,
      previousScreen,
      id,
      screenName,
      content,
      skuId,
      fromPersonalizedPage,
      utmSource: utm_source = 'share',
      utmMedium: utm_medium = 'share',
      utmCampaign: utm_campaign = '',
    } = this.props;
    // screenName={SCREEN_CONSTANTS.CATEGORY}
    let sharedUrl = shareUrl; // to change url when triggered from offer detail page
    let title = name;
    let message = '';

    if (fromPersonalizedPage) {
      sharedUrl = `${sharedUrl}?fromPersonalizedPage=true`;
    }

    if (isProduct) {
      title = `${name} ${brand.name}`;
    }
    if (screenName === SCREEN_CONSTANTS.ARTIST_DETAILS) {
      message = `Follow ${name} to catch their latest videos on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      message = `Check out ${name} on ${Config.APP_NAME}`;
    } else if (screenName === SCREEN_CONSTANTS.BRAND_DETAILS) {
      message = `Check out top product and offers of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.MORE_PAGE) {
      message = `Check out this collection on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      message = `Check out this offer of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.CATEGORY) {
      message = `Check out this category of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    }

    if (Utility.isBlank(sharedUrl)) {
      this.toast.show('An unexpected error occurred');
      return;
    }

    const linkOptions = {
      title,
      imageUrl: getMinifiedImage(image, 200, 200),
      description: '',
      trackingParams: { utm_source, utm_medium, utm_campaign },
    };

    if (isWeb()) {
      if (navigator.share) {
        navigator.share({
          title,
          text: message,
          url: shareUrl,
        }).then(() => console.log('Successful share! 🎉'))
          .catch(err => console.error(err));
      } else {
        Clipboard.setString(shareUrl);
        this.toast.show('Copied to clipboard.', 2000);
      }
    }

    try {
      const getDynamicLink = DynamicLinkManager.getShortenUrl(
        sharedUrl,
        linkOptions,
      );
      getDynamicLink
        .then((link) => {
          const options = {
            title: Config.APP_NAME,
            message,
            url: link,
            // social: Share.Social[_.upperCase(medium)],
          };
          if (Utility.isPresent(id) && Utility.isPresent(content)) {
            let contentType = content;
            let screen_name = screenName;
            this.logAnalyticsEvent(
              screen_name,
              id,
              name,
              contentType,
              medium,
              skuId,
            );
          }
          Share.open(options)
            .then((res) => {})
            .catch((err) => {});
        })
        .catch((error) => {
          console.tron.log('Error', error);
        });
    } catch (e) {
      console.tron.log('Error', e);
    }
  };

  goBack = debounce(
    () => {
      AnalyticsUtility.fireGoBackEvent();
      const { resetStatusBar } = this.props;
      if (resetStatusBar) resetStatusBar();
      requestAnimationFrame(() => {
        this.props.goBack({ animation: 'none' });
      });
    },
    1000,
    {
      leading: true,
      trailing: false,
    },
  );


  showShareModal = () => {
    const {
      shareUrl,
      isProduct = false,
      name,
      image,
      brand,
      previousScreen,
      id,
      screenName,
      content,
      skuId,
    } = this.props;
    this.logAnalyticsEvent(screenName, id, name, content, 'start', skuId);
    this.onSocialShareOptionTapped();
  };

  updateLikeOnDoubleTap = () => {
    console.tron.log('update like on double tap');
    this.likeButtonRef.updateLikeOnDoubleTap();
  };

  onLike = () => {
    this.toast.show('Added to My Kit');
  };

  dropDownOptionSelected = (type) => {
    const typ = type.toLowerCase();
    if (typ === 'share') {
      setTimeout(() => {
        this.showShareModal();
      }, 500);
    } else if (typ === 'claim profile') {
      const { navigateToProfile } = this.props;
      setTimeout(() => {
        navigateToProfile();
      }, 500);
    } else if (typ === 'report abuse') {
      Utility.openWhatsApp('');
    }
  };

  menuButtonPressed = () => {
    const { previousScreen, onClickMenu } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.ARTIST) {
      this.dropdownRef.current.focus();
    } else {
      onClickMenu();
    }
  };

  conditionalDivider = (props) => {
    let {
      dividerInitialOpacity,
      previousScreen,
      morePage = false,
      quickFiltersAvailable,
    } = props;
    if (previousScreen === SCREEN_CONSTANTS.ARTIST && !morePage) {
      dividerInitialOpacity = 0;
    }
    const inputRange = quickFiltersAvailable ? [100, 130] : [150, 200];
    if (Utility.isIOS) {
      return (
        <Animated.View
          style={[
            ProductDetailStyles.navigationDivider,
            {
              opacity: this.props.scrollY?.interpolate({
                inputRange: inputRange,
                outputRange: [dividerInitialOpacity, 1],
              }),
              useNativeDriver: true,
            },
          ]}
        />
      );
    }
    return null;
  };

  likeButtonRef = (ref) => {
    this.likeButtonRef = ref;
  };

  backButtonContainer = () => {
    const {
      hideRichDescriptionVisibility = {},
      isRichDescriptionVisible = false,
    } = this.props;
    const action = isRichDescriptionVisible
      ? hideRichDescriptionVisibility
      : this.goBack;
    return (
      <TouchableOpacity
        onPress={action}
        style={ProductDetailStyles.navigationBackButtonContainer}
        hitSlop={Utility.getHitSlop('backButton')}
      >
        <Image
        testID="back-Button"
        accessibilityLabel="back-Button"
          source={Images.ic_back_product}
          style={ProductDetailStyles.navbarIconDefaultStyle}
        />
      </TouchableOpacity>
    );
  };

  editButtonContainer = (props) => {
    const { showEditButton, onEditTap } = this.props;
    if (!showEditButton || !onEditTap) return null;
    return (
      <TouchableOpacity
        onPress={onEditTap}
        style={ProductDetailStyles.navigationEditButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.edit_new}
          style={ProductDetailStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  searchButtonContainer = (props) => {
    const { showSearchButton, shareButtonRightSpace, onSearchIconTap } = props;
    const { showEditButton } = this.props;
    if (!showSearchButton || !onSearchIconTap || showEditButton) return null;
    return (
      <TouchableOpacity
        onPress={onSearchIconTap}
        style={this.searchButtonContainerStyle}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.ic_search_product}
          style={ProductDetailStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  shareButtonContainer = (props) => {
    const { showShareButton, shareButtonRightSpace } = props;
    const { shareUrl } = this.props;
    if (shareUrl === EMPTY_LIST_URL_FOR_SHARE) return null;
    if (!showShareButton) return null;
    return (
      <TouchableOpacity
        onPress={this.showShareModal}
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: 86 },
        ]}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.ic_share_product}
          style={{ width: 20, height: 20, resizeMode: 'contain' }}
        />
      </TouchableOpacity>
    );
  };

  likeButtonContainer = () => {
    const { id, type, slug, content, name, screenName } = this.props;

    if (!this.showLikeButtonCondition(this.props)) {
      return null;
    }
    return (
      <LikeButton
        ref={this.likeButtonRef}
        id={id}
        type={type}
        slug={slug}
        layout={LAYOUT.SCREEN}
        content={content}
        onLike={this.onLike}
        itemName={name}
        screenName={screenName}
      />
    );
  };

  followButtonContainer = (props) => {
    const { showFollowButton } = props;
    const { id, slug } = this.props;
    if (!showFollowButton) return null;

    return (
      <FollowArtistButton
        id={id}
        followButtonLayout={LAYOUT.NAVIGATION_HEADER}
        slug={slug}
      />
    );
  };

  cartButtonContainer = (props) => {
    const { showCartIcon, cartButtonRightSpace, onPressCart } = props;
    if (!showCartIcon) return null;
    return (
      <View style={this.cartButtonContainerStyle}>
        <BagIcon handleTap={onPressCart} showCart />
      </View>
    );
  };

  menuButtonContainer = (props) => {
    const { shareButtonRightSpace, showMenuButton } = props;
    if (!showMenuButton) return null;

    return (
      <TouchableOpacity
        // onPress={onClickMenu}
        onPress={this.menuButtonPressed}
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: shareButtonRightSpace },
        ]}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={images.overflow_menu}
          style={{ height: 35, width: 35 }}
        />
      </TouchableOpacity>
    );
  };

  dropDownContainer = (props) => {
    const { showDropdown } = props;
    if (!showDropdown) return null;
    return (
      <Dropdown
        ref={this.dropdownRef}
        label={null}
        data={this.dropDownOption}
        dropdownPosition={-2}
        pickerStyle={{
          position: 'absolute',
          width: 110,
        }}
        containerStyle={{
          right: 110,
          position: 'absolute',
          width: 0,
          height: 0,
          top: this.navigationHeaderHeight,
        }}
        renderAccessory={null}
        textColor={colors.foxyBlack}
        hitSlop={{ top: 20, right: 20, left: 20 }}
        onChangeText={(type) => this.dropDownOptionSelected(type)}
        fontSize={12}
        itemColor={colors.foxyBlack}
        selectedItemColor={colors.foxyBlack}
      />
    );
  };

  scanButtonContainer = (props) => {
    const { showScannerButtonContainer, onScanButtonTap } = props;
    if (!showScannerButtonContainer) return null;

    return (
      <TouchableOpacity
        onPress={onScanButtonTap}
        style={this.scanButtonStyle}
        hitSlop={Utility.getHitSlop()}
      >
        <FastImageView
          source={images.retailStore.qrIcon}
          style={ProductDetailStyles.scanIcon}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      liked = 'false',
      content,
      id,
      type,
      slug,
      image,
      isProduct,
      name,
      sp,
      mrp,
      discount,
      brand,
      subHeading,
      showMenuButton,
      onClickMenu,
      previousScreen,
      screenName,
      morePage = false,
      morePageDisplay,
      showCartIcon,
      onPressCart,
      quickFiltersAvailable,
      showScannerButtonContainer = false,
      onScanButtonTap,
    } = this.props;
    let { showShareButton, showSearchButton, onSearchIconTap } = this.props;
    let shareButtonRightSpace = 60;
    const cartButtonRightSpace = 16;
    if (content === 'artist') {
      shareButtonRightSpace = 52; // for artist page there are three icons in navigation header
    }
    if (
      previousScreen === 'dashboard' ||
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
      previousScreen === SCREEN_CONSTANTS.COLLECTION ||
      (previousScreen === SCREEN_CONSTANTS.ARTIST && morePage) ||
      previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
      morePageDisplay === 'full-page-banner'
    ) {
      showShareButton = false;
    }
    let navigationHeaderInitialColor = 'rgba(255, 255, 255,0.0)';
    let navigationHeaderInitialElevation = 0;
    let headerImageInitialOutput = 0;
    let paddingTop = 0;
    let position = 'relative';

    const showDropdown = previousScreen === SCREEN_CONSTANTS.ARTIST;
    const showFollowButton =
      previousScreen === SCREEN_CONSTANTS.ARTIST && !morePage;
    const deviceSpecificInputRang = Utility.isAndroid()
      ? [125, 155]
      : [100, 130];
    const nonFilteredDeviceSpecificINputRange = Utility.isAndroid()
      ? [150, 200]
      : [140, 180];
    let inputRange = quickFiltersAvailable
      ? deviceSpecificInputRang
      : nonFilteredDeviceSpecificINputRange;

    if (!Utility.isBlank(previousScreen)) {
      if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
        navigationHeaderInitialColor = 'rgba(255,255,255,1)';
        navigationHeaderInitialElevation = 2;
        headerImageInitialOutput = 1;
      }
    }

    if (
      screenName === SCREEN_CONSTANTS.MORE_PAGE ||
      screenName === SCREEN_CONSTANTS.ARTIST_DETAILS
    ) {
      navigationHeaderInitialColor = 'rgba(255,255,255,1)';
    } else if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      navigationHeaderInitialColor = 'rgba(255,255,255,1)';
      navigationHeaderInitialElevation = 2;
      headerImageInitialOutput = 1;
    } else if (
      screenName === SCREEN_CONSTANTS.BRAND_DETAILS ||
      screenName === SCREEN_CONSTANTS.CATEGORY
    ) {
      inputRange = [0, 0];
      headerImageInitialOutput = 1;
    }

    return (
      <>
        <Animated.View
          style={[
            ProductDetailStyles.navigationHeader,
            {
              backgroundColor: this.props.scrollY?.interpolate({
                inputRange: inputRange,
                outputRange: [
                  navigationHeaderInitialColor,
                  'rgba(255,255,255,1)',
                ],
              }),
              elevation: this.props.scrollY?.interpolate({
                inputRange: inputRange,
                outputRange: [navigationHeaderInitialElevation, 2],
                extrapolate: 'clamp',
              }),
              height: this.navigationHeaderHeight,
              useNativeDriver: true,
              paddingTop,
              //position: position,
            },
          ]}
        >
          <Animated.View
            style={[
              ProductDetailStyles.headerImageBackground,
              {
                opacity: this.props.scrollY?.interpolate({
                  inputRange: inputRange,
                  outputRange: [headerImageInitialOutput, 1],
                }),
                useNativeDriver: true,
              },
            ]}
          />
          {/* {screenName === SCREEN_CONSTANTS.BRAND_DETAILS ? (
            <Animated.View
              style={[
                ProductDetailStyles.navigationHeaderChildView,
                {
                  opacity: this.props.scrollY.interpolate({
                    inputRange: inputRange,
                    outputRange: [headerImageInitialOutput, 1],
                  }),
                  useNativeDriver: true,
                },
              ]}
            >
              {this.props.children}
            </Animated.View>
          ) : ( */}
          <View style={ProductDetailStyles.navigationHeaderChildView}>
            {this.props.children}
          </View>
          {/* )} */}

          <this.backButtonContainer />
          <this.likeButtonContainer />
          <this.followButtonContainer showFollowButton={showFollowButton} />
          <this.cartButtonContainer
            showCartIcon
            onPressCart={onPressCart}
            cartButtonRightSpace={cartButtonRightSpace}
          />
          {!isDesktop() && (
            <this.shareButtonContainer
              showShareButton={isWeb() ? false : showShareButton}
              shareButtonRightSpace={shareButtonRightSpace}
            />
          )}
          {!isDesktop() && (
            <this.searchButtonContainer
              showSearchButton={showSearchButton}
              shareButtonRightSpace={shareButtonRightSpace}
              onSearchIconTap={onSearchIconTap}
            />
          )}
          <this.editButtonContainer
            showSearchButton={showSearchButton}
            shareButtonRightSpace={shareButtonRightSpace}
            onSearchIconTap={onSearchIconTap}
          />
          <this.menuButtonContainer
            showMenuButton={showMenuButton}
            shareButtonRightSpace={shareButtonRightSpace}
          />

          <this.scanButtonContainer
            showScannerButtonContainer={showScannerButtonContainer}
            onScanButtonTap={onScanButtonTap}
          />
          <this.dropDownContainer showDropdown={false} />
          {morePage && (
            <this.conditionalDivider
              dividerInitialOpacity={headerImageInitialOutput}
              previousScreen={previousScreen}
              morePage={morePage}
            />
          )}
        </Animated.View>
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

CustomNavigationHeader.propTypes = {
  slug: PropTypes.string,
  shareUrl: PropTypes.string,
  showShareButton: PropTypes.bool,
  showMenuButton: PropTypes.bool,
  onClickMenu: PropTypes.func,
  screenName: PropTypes.string,
};
CustomNavigationHeader.defaultProps = {
  slug: '',
  shareUrl: '',
  showShareButton: true,
  showMenuButton: false,
  screenName: '',
};

export default CustomNavigationHeader;
