// Dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text } from 'react-native';
import CardView from '../../libraries/ReactNativeCardView';
import LinearGradient from 'react-native-linear-gradient';
import ScaleAnimate from '../shared/ScaleAnimate';

// Components
import { COLLECTION } from '../../config/LayoutConstants/CollectionConfig';
import CollectionCardStyle from '../Collection/styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { fetchTagList } from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  AnalyticsUtilty,
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class CollectionCard extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
  };

  constructor(props) {
    super(props);
    this.item = this.props.itemData.objects;
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceCollectionCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.navigationSource = getNavigationSource(this, this.props);
  }

  collectionDescription = (props) => {
    const { itemData } = props;
    let mediaCount = '';
    if (itemData.media_count !== 0 && itemData.media_count !== '0') {
      mediaCount = `${itemData.media_count} Videos`;
    }
    return (
      <View style={CollectionCardStyle.descriptionContainer}>
        <View>
          <Text numberOfLines={1} style={CollectionCardStyle.ListHeadingText}>
            {_.capitalize(itemData.name)}
          </Text>
        </View>
      </View>
    );
  };

  showDescriptionConditionFn = (props) => !(props.layout === 'list');

  collectionDescriptionWithCondition = withMaybe(
    this.showDescriptionConditionFn,
  )(this.collectionDescription);

  SeparatorStyle = (props) => {
    const { layout } = props;
    if (layout === 'list') {
      return <View style={CollectionCardStyle.listSeparatorStyle} />;
    }
    return null;
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id = '', type = '', name = '', slug = '' },
      index,
      layout,
      listData: {
        name: listName = '',
        content: listContent = '',
        slug: listSlug = '',
      },
      listIndex,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
    } = this.props;

    //FIXME: this section should be a common function which will accept props, and calculated event meta itself
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: type,
        [EventParameterKey.ITEM_NAME]: name,
        [EventParameterKey.ITEM_ID]: id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceCollectionCardClick(meta);
    }
    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        id,
        type,
        name,
        index,
        id,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }

    let promptData = {
      type: 'list',
      id: itemData.id,
      offer: { slug: itemData.slug },
    };
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'OfferDetail',
      navigationSource: this.navigationSource,
      params: { promptData },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  navigateToMedia = () => {
    const { navigation, itemData = {}, fetchTagList } = this.props;
    const index = 0;
    const relatedContentSlug = itemData.slug?.replace(
      '.json',
      '/related-content.json',
    );

    fetchTagList(relatedContentSlug, 0, (success, data) => {
      if (success) {
        navigation.push('ContentModal', {
          listId: null,
          index: 0,
          slug: relatedContentSlug,
          listData: data,
          itemData: data.objects[0],
        });
      }
    });
  };

  getStyles = ({ layout, size = 'defaultSize' }) => {
    const { listData, previousScreen } = this.props;
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    let { viewwidth, viewheight, imagewidth, imageheight } =
      COLLECTION.CARD[layout][layoutSize];
    if (
      Utility.isPresent(listData) &&
      previousScreen !== 'more_page' &&
      layout === 'grid'
    ) {
      const noOfColumns = listData.columns || 2;
      [viewwidth, viewheight, imagewidth, imageheight] =
        Utility.getCardDimentions(noOfColumns, 'collectionCard');
    }

    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    let imageStyle = {
      height: imageheight,
      width: imagewidth,
      borderRadius: 4,
      backgroundColor: colors.white,
    };
    let cardStyle = [CollectionCardStyle.cardContainer, cardDimensions];
    if (layout === 'list') {
      cardStyle = [CollectionCardStyle.cardContainerList, cardDimensions];
      imageStyle = [imageStyle, { marginRight: 10, marginLeft: 10 }];
    }

    return {
      cardStyle,
      imageStyle,
    };
  };

  alternativeCartImage = () => {
    const { itemData: collectionData = {}, layout } = this.props;
    const { imageStyle } = this.getStyles(this.props);

    if (Utility.isBlank(collectionData)) {
      return null;
    }

    const alternativeCardImage =
      Utility.isPresent(collectionData.first_item_url) &&
      getMinifiedImage(
        collectionData.first_item_url,
        imageStyle.width,
        imageStyle.height,
      );

    if (Utility.isBlank(alternativeCardImage)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        {alternativeCardImage !== '' && (
          <FastImageView source={alternativeCardImage} style={imageStyle} />
        )}
        <View style={CollectionCardStyle.collectionCardEmptyImageContainer}>
          {layout !== 'listing' && (
            <Text
              style={CollectionCardStyle.collectionCardEmptyImageCardText}
              numberOfLines={2}
            >
              {collectionData.name}
            </Text>
          )}

          <LinearGradient
            colors={[
              '#ffffff00',
              '#ffffff00',
              '#ffffff00',
              '#E4E4E400',
              '#E4E4E4AA',
              '#E4E4E4',
            ]}
            style={[
              CollectionCardStyle.collectionCardEmptyImageGradient,
              { height: imageStyle.height / 2 },
            ]}
          />
        </View>
      </ScaleAnimate>
    );
  };

  render() {
    const { orientation, itemData: collectionData, layout } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    let LayoutComponent = CardView;
    if (collectionData === undefined) {
      return null;
    }

    const cardImage = getMinifiedImage(
      collectionData.card_image,
      imageStyle.width,
      imageStyle.height,
    );
    let backgroundColor = colors.white;
    if (Utility.isBlank(cardImage) && layout !== 'list') {
      backgroundColor = 'black';
    }

    LayoutComponent = Card;

    if (Utility.isBlank(cardImage)) {
      return <this.alternativeCartImage />;
    }
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <LayoutComponent
          {...CollectionCard.cardLayoutStyle[orientation]}
          style={cardStyle}
        >
          <FastImageView
            source={cardImage}
            additionalBackgroundColor={backgroundColor}
            style={imageStyle}
          />

          <this.collectionDescriptionWithCondition
            itemData={collectionData}
            layout={layout}
            style={[imageStyle, { backgroundColor }]}
          />
        </LayoutComponent>
        <this.SeparatorStyle layout={layout} />
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchTagList }, dispatch),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(CollectionCard),
);
