import React, { PureComponent } from 'react';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grayscale } from '../../../libraries/ReactNativeColorMatrixImageFilters';
import colors from '../../../theme/Colors';
import OfferCouponLayout from '../../prompts/OfferCouponLayout';
import List from '../../../containers/List/List';
import FastImageView from '../../FastImageView';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import { withEither } from '../../../lib/Monads';
import FullWidthShimmer from '../../../lib/FullWidthShimmer';
import { AnalyticsUtilty } from '../../../analytics';
import { fetchListDetail } from '../../../actions/ActionTypes';
import images from '../../../theme/Images';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';
import { isNative } from '../../../utils/BooleanUtility';

const { ids, styles } = StyleSheet.create({
  fullWidthShimmer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    height: '99%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 8,
    paddingBottom: 6,
  },
  flatListContainer: { paddingRight: 12 },
  flatList: { paddingHorizontal: 12, marginVertical: 8 },
  tab: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
    width: 72,
    '@media (min-width: 768px)': {
      width: 110,
    },
  },
  imageForYou: {
    height: 40,
    width: 40,
    borderRadius: 20,
    '@media (min-width: 768px)': {
      width: 80,
      height: 80,
    },
  },
  tabImage: {
    height: 40,
    width: 40,
    borderRadius: 0,
    '@media (min-width: 768px)': {
      width: 80,
      height: 80,
    },
  },
  tabTitleContainer: {
    height: 52,
    '@media (min-width: 768px)': {
      height: 70,
    },
  },
  tabTitleSelected: {
    fontSize: 12,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginTop: 6,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      marginTop: 50,
      fontSize: 14,
      marginLeft: 30,
    },
  },
  tabTitleUnSelected: {
    fontSize: 12,
    color: '#A4A4A4',
    fontFamily: 'Roboto-Regular',
    marginTop: 6,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      marginTop: 50,
      fontSize: 14,
      marginLeft: 30,
    },
  },
});

export class Tabbed extends PureComponent {
  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
      tabbedObject: item[0],
      startLoading: false,
      items: item,
    };
    this.key = 1;
    this.index = 1;
  }

  componentDidMount() {
    this.getObjects(this.index);
  }

  isNotActive = ({ item }) => {
    const { tabbedObject } = this.state;
    if (tabbedObject?.id !== item?.id) return true;
    return false;
  };

  conditionalView = withEither(this.isNotActive, Grayscale)(View);

  fireEvent = (item, index) => {
    const {
      listId,
      previousScreen,
      listIndex,
      listData: {
        name: listName = '',
        display: listDisplay = '',
        content: listContent = '',
        slug: listSlug = '',
      },
      parentListsData = [],
    } = this.props;
    const { id = '', name = '', type = '', slug = '' } = item;
    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
      AnalyticsUtilty.getParentsList(parentListsData),
    );
  };

  keyExtractor = (_, index) => index.toString();

  onTabPress = (item, index) => {
    this.setState({
      tabbedObject: item || {},
      startLoading: true,
    });
    setTimeout(() => {
      this.setState({
        startLoading: false,
      });
    }, 500);
    this.fireEvent(item, index);
  };

  getObjects = (index) => {
    const { fetchListDetail } = this.props;
    const { items } = this.state;
    if (index === 0 || index >= items.length) {
      return;
    }
    fetchListDetail(items[index]?.slug, 0, (success, response) => {
      const newObject = [...this.state.items];

      newObject[index] = response;

      this.setState({ items: newObject }, () => {
        this.getObjects(++this.index);
      });
    });
  };

  renderItem = ({ item = {}, index }) => {
    const { tabbedObject } = this.state;
    const imageStyle =
      item?.name === 'For You' ? styles.imageForYou : styles.tabImage;
    const imageDataSet = {
      media: item?.name === 'For You' ? ids.imageForYou : ids.tabImage,
    };
    const {
      entity_details: { image_url = '' } = {},
      name = '',
      image_url: imageUrl = '',
    } = item;
    const { selfieImageUrl } = this.props;
    let image = {
      uri: getMinifiedImage(image_url, imageStyle.width, imageStyle.height),
    };
    if (Utility.isBlank(image_url)) {
      image = {
        uri: getMinifiedImage(imageUrl, imageStyle.width, imageStyle.height),
      };
    }
    if (item?.entity_details?.masked === 'circle') {
      if (Utility.isPresent(selfieImageUrl)) {
        image = {
          uri: getMinifiedImage(
            selfieImageUrl,
            imageStyle.width,
            imageStyle.height,
          ),
        };
      } else {
        image = images.for_you_placeholder.uri;
      }
    }

    return (
      <TouchableOpacity
        onPress={() => this.onTabPress(item, index)}
        style={styles.tab}
        dataSet={{ media: ids.tab }}
      >
        <this.conditionalView item={item}>
          <FastImageView
            style={imageStyle}
            dataSet={imageDataSet}
            source={image}
          />
        </this.conditionalView>

        <View
          style={styles.tabTitleContainer}
          dataSet={{ media: ids.tabTitleContainer }}
        >
          <Text
            style={
              item.name === tabbedObject?.name
                ? styles.tabTitleSelected
                : styles.tabTitleUnSelected
            }
            dataSet={{
              media:
                item.name === tabbedObject?.name
                  ? ids.tabTitleSelected
                  : ids.tabTitleUnSelected,
            }}
            allowFontScaling={false}
            numberOfLines={2}
          >
            {name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  onTabPress = (item, index) => {
    this.setState({
      tabbedObject: item || {},
      startLoading: true,
    });
    setTimeout(() => {
      this.setState({
        startLoading: false,
      });
    }, 500);
    this.fireEvent(item, index);
  };

  render() {
    const {
      navigation,
      listData,
      listData: { id: listId = '', listSlug = '' },
      previousScreen,
      selfieImageUrl,
      listIndex,
      parentListsData = [],
    } = this.props;
    const { listData: { name = '' } = {}, listName } = this.props;
    const flatListName = name || listName;
    const { tabbedObject, startLoading, items } = this.state;
    if (Utility.isBlank(items)) {
      return null;
    }
    const updatedParentListData = Utility.isPresent(parentListsData)
      ? parentListsData.concat({ listId, listSlug, item_position: listIndex })
      : [{ listId, listSlug, item_position: listIndex }];
    return (
      <>
        <FlatList
          data={items}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          horizontal
          style={styles.flatList}
          contentContainerStyle={styles.flatListContainer}
          showsHorizontalScrollIndicator={false}
          extraData={this.state}
        />

        {listData?.entity_details?.type === 'offer' && (
          <OfferCouponLayout data={listData?.entity_details} />
        )}
        {!startLoading && (
          <List
            itemData={tabbedObject}
            index={listIndex}
            parentListsData={updatedParentListData}
            navigation={navigation}
            previousScreen={previousScreen}
            avoidFlashList={isNative()}
          />
        )}
        {startLoading && (
          <View>
            <List
              itemData={tabbedObject}
              index={listIndex}
              navigation={navigation}
              previousScreen={previousScreen}
              avoidFlashList={isNative()}
            />

            <FullWidthShimmer style={styles.fullWidthShimmer} />
          </View>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchListDetail,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Tabbed),
);
