import React, { PureComponent, memo } from 'react';
import { Image, Text, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import RemoteConfig from '../../utils/RemoteConfig';
import {
  LOGIN_MODAL_MESSAGES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { getUserTodayDeals } from '../../actions/ActionTypes';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import SelfieUtilities from '../../utils/SelfieUtilities';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import Marquee from './Marquee';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import CountDown from '../../helpers/Counter';
import FastImageView from '../FastImageView';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import NavigationService from '../../navigator/NavigationService';
import ErrorBoundaryComponent from '../shared/ErrorBoundaryComponent';
import Config from '../../libraries/ReactNativeConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const widthOfImage = 80;

class Masonry extends PureComponent {
  constructor(props) {
    super(props);
    this.image_array = [];
    this.state = {
      hideFeature: false,
    };
    this.wishlist_deals_feature_card = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.wishlist_deals_feature_card),
    );
    this.timeToShow = ['H', 'M', 'S'];
    this.timeLabels = { h: 'hrs', m: 'min', s: 'sec' };
    const { todayDeals: { skus = {} } = {} } = this.props;
    _.forEach(skus, (e) => {
      const mini = getMinifiedImage(e.image_url, styles.imageRow.width, styles.imageRow.height);
      this.image_array.push(mini);
    });

    this.timeToShow = ['H', 'M', 'S'];
    this.timeLabels = { h: 'hrs', m: 'min', s: 'sec' };

    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    this.refreshTodayDeals();
  }

  refreshTodayDeals = () => {
    const { getUserTodayDeals } = this.props;

    getUserTodayDeals();
  };

  onFinish = () => {
    this.setState({
      hideFeature: true,
    });
  };

  navigate = () => {
    const {
      selfieImageUrl,
      itemData,
      previousScreen,
      listIndex,
      itemData: { destination, external_link, title } = {},
      listData: {
        id: listId = '',
        slug: listSlug = '',
        name: listName = '',
        type: listType = '',
      } = {},
      listContent,
      index = 0,
      navigation,
      facialProperties,
      setPhoneNumberModalInfo,
      authToken,
      parentListsData,
    } = this.props;

    const url = external_link || destination || listSlug;

    const listNames = title || url?.split('/')[url.split('/').length - 1];
    if (Utility.isPresent(itemData)) {
      const { id, type, slug = '' } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listType,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
        AnalyticsUtility.getParentsList(parentListsData),
      );
    }

    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('WISHLIST_VIEW'));
      NavigationService.renderOnboarding({
        parentSkipAction: this.navigateToWishlist,
        furtherAction: this.navigateToWishlist,
        onPressOutsideAction: this.navigateToWishlist,
      });
      Utility.invokeSelfieFlowAfterLoginForGuest(false);
      return;
    }
    this.navigateToWishlist();
  };

  navigateToWishlist = () => {
    const { navigation } = this.props;
    if (Config.TABS?.includes('Wishlist')) {
      navigation.navigate(this.navigationSource, 'Wishlist');
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: SCREEN_CONSTANTS.TODAY_DEALS,
      navigationSource: this.navigationSource,
    });
  };

  imagesRow = (e) => {
    return (
      <FastImageView source={e} style={styles.imageRow} resizeMode='contain' key={e} dataSet={{ media: ids.imageRow }} />
    );
  };

  marquee = (index) => {
    return (
      <Marquee duration={250000} loop repeatSpacer={0} key={index}>
        <View style={styles.row}>
          {this.image_array.slice(index).map(this.imagesRow)}
        </View>
      </Marquee>
    );
  };

  marqueeRows = memo(() => {
    const { itemData: { arguments: args = {} } = {} } = this.props;
    let numberOfRows = 0;

    // arguments are null sometimes, direct structuring was causing crash.
    if (Utility.isPresent(args)) {
      numberOfRows = args.number_of_rows;
    }
    const masonryRows = numberOfRows > 0 ? numberOfRows : 3;
    // Create an array with a length determined by the number of rows
    // and insert the index values into the array.
    return Array.from({ length: masonryRows }, (_, index) => index).map(
      (index) => this.marquee(index * 5),
    );
  });

  render() {
    const {
      todayDeals: { skus = {}, ends_at },
      selfieImageUrl,
      facialProperties,
      authToken,
      itemData = {},
      listData = {},
      previousScreen = '',
    } = this.props;
    const { hideFeature } = this.state;

    if (Utility.isBlank(skus)) {
      return null;
    }

    let imageComponentSource = images.for_you_placeholder;
    if (Utility.isPresent(this.props?.selfieImageUrl)) {
      imageComponentSource = {
        uri: getMinifiedImage(selfieImageUrl, styles.selfieImage.width, styles.selfieImage.height),
      };
    }

    const {
      title: verifiedUserTitle = '',
      guest_user_title = '',
      subtitle: verifiedUserSubtitle = '',
      guest_user_subtitle = '',
    } = this.wishlist_deals_feature_card;
    const title = Utility.isPresent(authToken)
      ? verifiedUserTitle
      : guest_user_title;
    const subTitle = Utility.isPresent(authToken)
      ? verifiedUserSubtitle
      : guest_user_subtitle;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.debouncedNavigate}
      >
        <ErrorBoundaryComponent
          itemData={itemData}
          listData={listData}
          screenName={previousScreen}
        >
          <View style={styles.container}>
            {!!Utility.isPresent(selfieImageUrl) && (
              <FastImageView
                source={imageComponentSource.uri}
                style={styles.selfieImage}
              />
            )}
            <View>
              <Text style={styles.title} allowFontScaling={false}>
                {title}
              </Text>
              <Text
                style={styles.subtitle}
                allowFontScaling={false}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {subTitle}
              </Text>
            </View>
          </View>
          <this.marqueeRows />
          <View style={styles.bottomContainer}>
            <CountDown
              size={14}
              until={Utility.calculateTimeDifferenceForOffer(ends_at)}
              endsAt={ends_at}
              onFinish={this.onFinish}
              digitStyle={styles.digitStyle}
              digitTxtStyle={styles.digitTxtStyle}
              timeLabelStyle={styles.timeLabelStyle}
              separatorStyle={styles.separatorStyle}
              timeToShow={this.timeToShow}
              timeLabels={this.timeLabels}
              showSeparator
              hideFeature={hideFeature}
            />
            <View style={styles.touchable}>
              <Text style={styles.buttonName}>Explore</Text>
              <Image
                source={images.whiteRightArrow}
                style={styles.rightArrow}
              />
            </View>
          </View>
        </ErrorBoundaryComponent>
      </DebouncedTouchableOpacity>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 32,
    marginLeft: 12,
    marginBottom: 8,
  },
  selfieImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
    overflow: 'hidden',
    marginRight: 4,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginLeft: 8,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginLeft: 8,
  },
  row: { flexDirection: 'row' },
  imageRow: {
    width: widthOfImage,
    height: widthOfImage,
    margin: 4,
    borderRadius: 16,
    backgroundColor: '#fff',
    '@media (min-width: 768px)': {
      width: widthOfImage * 1.5,
      height: widthOfImage * 1.5,
    },
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 0,
    marginBottom: 12,
    '@media (min-width: 768px)': {
      paddingHorizontal: 16,
    }
  },
  digitStyle: {
    backgroundColor: '#000',
    borderRadius: 4,
  },
  digitTxtStyle: {
    color: '#fff',
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    alignSelf: 'flex-end',
    marginRight: 4,
  },
  separatorStyle: {
    color: '#000000',
    marginBottom: 12,
  },
  touchable: {
    backgroundColor: colors.black,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    flexDirection: 'row',
    padding: 4,
    paddingHorizontal: 12,
  },
  buttonName: {
    fontSize: 15,
    color: colors.white,
    alignSelf: 'center',
    fontFamily: 'Roboto-Bold',
  },
  rightArrow: {
    width: 17.5,
    height: 17.5,
    resizeMode: 'contain',
    marginLeft: 5,
  },
});

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  todayDeals: state.todayDeals,
  facialProperties: state.UserAccountInfo?.facialAnalysis?.facialProperties,
  name: state.UserAccountInfo.profile.name,
  authToken: state.UserAccountInfo.authToken,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getUserTodayDeals,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Masonry),
);
