import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { useSelector } from 'react-redux';
import Config from '../../libraries/ReactNativeConfig';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { ScaleAnimate } from '../shared';
import { isBlank } from '../../utils/BooleanUtility';
import moment from 'moment';

const MilestoneBullets = ({ currentMilestone, totalMilestones }) => {
  const bulletArray = new Array(totalMilestones).fill(0);
  return (
    <View style={styles.milestoneBulletsContainer}>
      {bulletArray.map((item, index) => {
        const miltestoneStartBulletStyle =
          index <= currentMilestone ? styles.mileStoneBullet : styles.milestoneBulletDisabled;
        const milestoneEndBulletStyle =
          index + 1 === currentMilestone ? styles.mileStoneBullet : styles.milestoneBulletDisabled;
        const mileStoneLineStyle = index < currentMilestone ? styles.mileStoneLine : styles.mileStoneLineDisabled;
        return (
          <View style={styles.milestoneBulletContainer}>
            <View style={miltestoneStartBulletStyle} />
            <>
              <View style={mileStoneLineStyle} />
            </>

            {index === bulletArray.length - 1 && <View style={milestoneEndBulletStyle} />}
          </View>
        );
      })}
    </View>
  );
};

const MilestoneComponent = ({ remainingToNextMilestone, currentMilestone, totalMilestones }) => {
  return (
    <View>
      <Text style={styles.milestoneTextPrimary}>
        {CURRENCY_SYMBOL}
        {remainingToNextMilestone}{' '}
        <Text style={styles.milestoneTextSecondary}>spends away from your next milestone</Text>
      </Text>
      <MilestoneBullets currentMilestone={currentMilestone} totalMilestones={totalMilestones} />
    </View>
  );
};

const MilestoneProgress = ({ itemData = {}, previousScreen = '' }) => {
  const navigation = useNavigation();
  const mobileNumber = useSelector((state) => state.UserAccountInfo.mobileNumber);
  const callingCode = useSelector((state) => state.UserAccountInfo.callingCode);
  const pureNumber = mobileNumber?.replace(`+${callingCode}`, '');

  const openRewardsPassModal = () => {
    navigation.navigate('MilestoneProgressModal', {
      pureNumber,
      previousScreen,
    });
  };

  const { arguments: args = {}, body = '', cta_text = '' } = itemData;
  const itemArguments = isBlank(args) ? {} : args;
  const {
    remaining_to_next_milestone = 0,
    member_since = new Date(),
    current_milestone = 0,
    total_milestones = 1,
  } = itemArguments;

  return (
    <ScaleAnimate onPress={openRewardsPassModal}>
      <View style={styles.containerAuthorized}>
        <Text style={styles.title}>Member since {moment(member_since).format('MMMM YYYY')}</Text>
        <MilestoneComponent
          amountSpent={4000}
          currentMilestone={current_milestone}
          remainingToNextMilestone={remaining_to_next_milestone}
          totalMilestones={total_milestones}
        />
        <Text style={styles.subtitle}>{body}</Text>
        <TouchableOpacity onPress={openRewardsPassModal} style={styles.ctaContainer}>
          <FastImageView source={images.barcode_icon} style={styles.barcodeIcon} />
          <Text style={styles.ctaText}>{cta_text}</Text>
        </TouchableOpacity>
      </View>
    </ScaleAnimate>
  );
};

export default MilestoneProgress;

const styles = StyleSheet.create({
  containerAuthorized: {
    paddingVertical: 12,
    paddingTop: 18,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    backgroundColor: '#F8F8F8',
    justifyContent: 'center',
    marginVertical: 12,
    borderRadius: 16,
  },
  container: {
    paddingVertical: 4,
    paddingHorizontal: 24,
    backgroundColor: colors.white,
    justifyContent: 'center',
    marginVertical: 12,
  },
  title: {
    color: colors.black,
    marginBottom: 4,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginBottom: 12,
  },
  subtitle: {
    color: colors.black,
    marginBottom: 12,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  barcode: {
    marginTop: 12,
  },
  ctaText: {
    color: colors.black,
    alignSelf: 'center',
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  bullet: {
    color: colors.black,
    marginTop: 12,
    fontFamily: 'Roboto',
    fontSize: size.h3,
  },
  row: {
    flexDirection: 'row',
  },
  ctaContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.black,
  },
  milestoneTextPrimary: {
    color: '#D02C39',
    fontFamily: 'Roboto-Bold',
    fontSize: size.h0,
  },
  milestoneTextSecondary: {
    color: '#8B8B8B',
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  mileStoneBullet: {
    height: 8,
    width: 8,
    borderRadius: 12,
    backgroundColor: '#D02C39',
    zIndex: 1,
  },
  mileStoneLine: {
    width: '100%',
    marginHorizontal: -2,
    height: 2,
    backgroundColor: '#D02C39',
  },
  milestoneBulletsContainer: {
    flexDirection: 'row',
    width: '100%',
    marginVertical: 12,
  },
  milestoneBulletContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  milestoneBulletDisabled: {
    height: 8,
    width: 8,
    borderRadius: 12,
    backgroundColor: '#D5D4D4',
    zIndex: 1,
  },
  mileStoneLineDisabled: {
    width: '100%',
    marginHorizontal: -2,
    height: 2,
    backgroundColor: '#D5D4D4',
  },
  barcodeIcon: {
    width: 36,
    height: 24,
    resizeMode: 'contain',
    marginRight: 12,
  },
});
