// Dependencies
import React, { PureComponent, Suspense } from 'react';
import { View, NativeModules, ImageBackground, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import { capitalize, max, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import {
  LAYOUT,
  ORIENTATION,
  REMOTE_CONFIG_KEYS,
} from '../../../config/Constants';
import Utility from '../../../utils/Utility';

import styles from './styles';
import { LAYOUT_CONFIG } from '../../../config/LayoutConstants/LayoutConfig';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { Viewport } from '../../../libraries/Viewport';

import { HorizontalProductRail } from '../../Product';

import { getRailComponent, getRailHeight } from './RailHelper';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop, isPresent } from '../../../utils/BooleanUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';
import { isIOS } from '../../../utils/BooleanUtility';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';

const allowedContentTypes = ['product', 'sku', 'feature', 'offer_prompt', 'list', 'routine', 'dermat_profile'];
class Rail extends PureComponent {
  static getComponentHeight(item) {
    return getRailHeight(item);
  }

  constructor(props) {
    super(props);
    const { item = {}, content, size, display, listData } = props;
    const { UIManager } = NativeModules;
    UIManager.setLayoutAnimationEnabledExperimental &&
      UIManager.setLayoutAnimationEnabledExperimental(true);
    this.ContainerComponent = getRailComponent(
      item[0]?.type,
      content,
      size,
      display,
    );
    this.state = {
      currentOffset: 0,
      scrollViewWidth: 0,
      showLeftArrow: false,
      showRightArrow: true,
    };
    this.cardHeight = getRailHeight(listData);
    this.viewedItemIds = [];
    this.itemData = item;
    this.memoizedEmptyCardWidth = [];
    this.flatListRef = React.createRef();
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      size,
      navigation,
      content,
      showRating,
      toggleCartVisibility,
      search = false,
      listIndex,
      searchQuery,
      elementItemCounts,
      listName,
      display,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' },
      onPress,
      maxFreeItemsToSelect,
      showToast,
      listContent,
      onItemPress = () => {},
      routineActivityId = '',
      extraEventParameters = {},
      parentListsData = [],
      currentSku,
      fromProductPage,
    } = this.props;

    const CardContainer = getRailComponent(
      item.type,
      content,
      size,
      display,
      listData?.objects?.length,
    );

    if (!CardContainer) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <Suspense>
          <CardContainer
            id={item.id}
            listName={listName}
            listContent={listContent}
            itemData={item}
            item={item}
            data={item}
            layout={LAYOUT.RAIL}
            navigation={navigation}
            size={size}
            type={item.type}
            orientation={ORIENTATION.VERTICAL}
            listId={id || listData?.id}
            index={index}
            showRating={showRating}
            toggleCartVisibility={toggleCartVisibility}
            listIndex={listIndex}
            search={search}
            searchQuery={searchQuery}
            elementItemCounts={elementItemCounts}
            previousScreen={previousScreen}
            listSlug={listSlug}
            listData={listData}
            onPress={onPress}
            refreshOfferStrip={this.props.refreshOfferStrip}
            maxFreeItemsToSelect={maxFreeItemsToSelect}
            showToast={showToast}
            display={display}
            onItemPress={onItemPress}
            routineActivityId={routineActivityId}
            parentListsData={parentListsData}
            extraEventParameters={extraEventParameters}
            currentSku={currentSku}
            fromProductPage={fromProductPage}
            key={item.id}
          />
        </Suspense>
      </ErrorBoundaryComponent>
    );
  };

  singleHorizontalProductRailCard = () => {
    const {
      id,
      size,
      navigation,
      content,
      showRating,
      toggleCartVisibility,
      search = false,
      listIndex,
      searchQuery,
      elementItemCounts,
      listName,
      display,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      onPress,
      maxFreeItemsToSelect,
      showToast,
      listContent,
      item,
    } = this.props;
    return (
      <View style={styles.railViewSingleCard}>
        <HorizontalProductRail
          id={item.id}
          listName={listName}
          listContent={listContent}
          listSlug={listSlug}
          itemData={item[0]}
          data={item}
          layout={LAYOUT.RAIL}
          navigation={navigation}
          size={size}
          type={item.type}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={0}
          showRating={showRating}
          toggleCartVisibility={toggleCartVisibility}
          listIndex={listIndex}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          previousScreen={previousScreen}
          listData={listData}
          onPress={onPress}
          refreshOfferStrip={this.props.refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          isSingleItem={true}
        />
      </View>
    );
  };

  emptyCardView = () => {
    const {
      listData: {
        options: { rail_offset_percentage: railOffsetPercentage = '' } = {},
      } = {},
    } = this.props;
    let absoluteWidth = (railOffsetPercentage / 100) * Utility.getScreenWidth();
    if (isDesktop()) {
      absoluteWidth /= 2;
    }
    return <View style={this.getMemoizedEmptyCardWidth(absoluteWidth)} />;
  };

  getMemoizedEmptyCardWidth = (width) => {
    if (!this.memoizedEmptyCardWidth[width]) {
      this.memoizedEmptyCardWidth[width] = [
        styles.emptyCardView,
        { width: width },
      ];
    }
    return this.memoizedEmptyCardWidth[width];
  };

  renderItems = ({ item, index }) => this.getComponent(item, index);

  keyExtractor = (item, index) => `${index}_${item.id}_rail`;

  listKey = (item, index) => `${listKey}${index}_${item.id}_rail`;

  getItemLayout = (item, index) => {
    return { length: this.cardHeight, offset: this.cardHeight * index, index };
  };

  handleScroll = (event) => {
    const { contentOffset = { x: 0 }, contentSize = {} } = event.nativeEvent;
    const currentOffset = typeof contentOffset.x === 'number' ? Math.round(contentOffset.x) : 0;
    const { scrollViewWidth = 0 } = this.state;
    const contentWidth = contentSize.width || 0;

    this.setState({ currentOffset });
    this.setState({ showLeftArrow: currentOffset > 0 });
    if (currentOffset + scrollViewWidth >= contentWidth) {
      this.setState({ showRightArrow: false });
    } else {
      this.setState({ showRightArrow: true });
    }
  };
  
  rightArrow = () => {
    const { scrollViewWidth = 0, currentOffset = 0 } = this.state;
    if (!this.flatListRef || !this.flatListRef.current) return;
    const { contentWidth } = this.flatListRef.current.props;
  
    const eachItemOffset = scrollViewWidth / 2;
    let newOffset = currentOffset + eachItemOffset;
    if (newOffset + scrollViewWidth > contentWidth) {
      newOffset = contentWidth - scrollViewWidth;
    }
  
    this.setState({ currentOffset: newOffset, showLeftArrow: true }, () => {
      this.flatListRef.current.scrollToOffset({ offset: newOffset, animated: true });
    });
    if (newOffset + scrollViewWidth >= contentWidth) {
      this.setState({ showRightArrow: false });
    }
  };
  
  
  leftArrow = () => {
    const { scrollViewWidth = 0, currentOffset = 0 } = this.state;
    if (!this.flatListRef || !this.flatListRef.current) return;
    const eachItemOffset = scrollViewWidth / 2;
    let newOffset = currentOffset - eachItemOffset;
    if (newOffset < 0) {
      newOffset = 0;
    }
  
    this.setState({ currentOffset: newOffset }, () => {
      this.flatListRef.current.scrollToOffset({ offset: newOffset, animated: true });
      if (newOffset <= 0) {
        this.setState({ showLeftArrow: false });
      }
    });
  };
  
  
  onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    this.setState({ scrollViewWidth: width });
  };

  SliderArrowButton = ({ isVisible, buttonStyle, onPress, imageSource, imageStyle, listData ,isShow}) => {
    const isAllowedContentType = allowedContentTypes.includes(listData?.content);
    if (!isDesktop() || !isVisible || !isAllowedContentType || !isShow) return null;
    return (
        <TouchableOpacity style={buttonStyle} onPress={onPress}>
          <FastImageView source={imageSource} style={imageStyle} />
        </TouchableOpacity>
      );
    };
  
  render() {
    const {
      feed,
      previousScreen,
      ignoreMinCount,
      listKey,
      content = '',
      search,
      size,
      listData,
      listData: {
        background_image_url: backGroundImageUrl = '',
        desktop_background_image_url: desktopBackgroundImageUrl,
      } = {},
      item = {},
      navigation,
      listName,
    } = this.props;

    let displayCount = feed
      ? max([LAYOUT_CONFIG.minRailCount, this.props.displayCount || 0])
      : this.props.displayCount || 0;

    if (
      Utility.isBlank(this.itemData) ||
      (!ignoreMinCount &&
        this.itemData.length < LAYOUT_CONFIG.minRailCount &&
        listData?.content !== 'tall_banner')
    ) {
      if (
        previousScreen === SCREEN_CONSTANTS.FEED ||
        previousScreen === SCREEN_CONSTANTS.STORE
      ) {
        return null;
      }
    }

    if (
      listData?.content === 'tall_banner' &&
      this.itemData.length < LAYOUT_CONFIG.minGridCount
    ) {
      return null;
    }

    if (
      displayCount > LAYOUT_CONFIG.maxRailCount ||
      (displayCount === 0 && Utility.isPresent(this.itemData))
    ) {
      displayCount = LAYOUT_CONFIG.maxRailCount;
    }
    if (content === 'mixed' && search) {
      displayCount = this.props.displayCount;
    }

    const componentType = `${upperFirst(size)}${capitalize(
      this.itemData[0]?.type,
    )}Rail`;

    if (componentType === 'HorizontalProductRail' && displayCount === 1) {
      const item = this.itemData[0];
      return <this.singleHorizontalProductRailCard />;
    }

    const flatlistStyle =
      previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE
        ? styles.flatListOnRoutinePageStyle
        : styles.flatlistStyle;
    const containerStyle =
      previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE
        ? styles.routineRailContainerStyle
        : styles.railContainerStyle;
    let backgroundImageUri = backGroundImageUrl;

    let resizeMode = 'cover';
    let initialNumToRender = content === 'tag' ? 5 : 3;
    let windowSize = content === 'tag' ? 5 : 7;

    if (isDesktop()) {
      displayCount *= 2;
      initialNumToRender *= 2;
      windowSize *= 2;
      resizeMode = isPresent(desktopBackgroundImageUrl) ? 'cover' : 'repeat';
      backgroundImageUri = isPresent(desktopBackgroundImageUrl) ? desktopBackgroundImageUrl : backGroundImageUrl;
    }
    const backgroundImage = {
      uri: getMinifiedImage(backgroundImageUri, getScreenWidth()),
    };

    const { showLeftArrow, showRightArrow } = this.state;
    const showArrows = this.itemData.length >= 4;
    return (
      <ImageBackground
        source={backgroundImage}
        style={styles.backgroundImage}
        imageStyle={{ resizeMode }}
      >
        <View style={styles.railView}>
          <Viewport.Tracker>
          <React.Fragment>
              <this.SliderArrowButton 
              isVisible={showLeftArrow}
              buttonStyle={styles.leftArrowButton}
              onPress={this.leftArrow}
              imageSource={images.leftArrow}
              imageStyle={styles.arrowImage}
              listData={listData}
              isShow={showArrows}
              />
            <FlatList
              ref={this.flatListRef}
              onScroll={this.handleScroll}
              onLayout={this.onLayout}
              data={this.itemData.slice(0, displayCount)}
              horizontal
              style={flatlistStyle}
              bounces={false}
              showsHorizontalScrollIndicator={false}
              renderItem={this.renderItems}
              keyExtractor={this.keyExtractor}
              listKey={this.listKey} // added to prevent virtuaized list crash
              ListHeaderComponent={this.emptyCardView}
              contentContainerStyle={containerStyle}
              getItemLayout={this.getItemLayout}
              initialNumToRender={initialNumToRender}
              windowSize={isIOS() ? 7 : windowSize}
              maxToRenderPerBatch={10}
              removeClippedSubviews={isIOS()}
            />
              <this.SliderArrowButton
                isVisible={showRightArrow}
                buttonStyle={styles.rightArrowButton}
                onPress={this.rightArrow}
                imageSource={images.rightArrow}
                imageStyle={styles.arrowImage}
                listData={listData}
                isShow={showArrows}
              />
            </React.Fragment>
          </Viewport.Tracker>
        </View>
      </ImageBackground>
    );
  }
}

// PropTypes
Rail.propTypes = {
  feed: PropTypes.bool,
  ignoreMinCount: PropTypes.bool,
  listKey: PropTypes.string,
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
  item: PropTypes.array,
  onPress: PropTypes.func,
};

Rail.defaultProps = {
  feed: false,
  ignoreMinCount: false,
  listKey: null,
  list: {
    objects: [],
  },
  item: [],
  onPress: null,
};
export default Rail;
