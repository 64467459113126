import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';

const GridStyles = StyleSheet.create({
  gridContainer: {
    marginVertical: 8,
  },
  gridFlatlistStyles: {
    marginHorizontal: 8,
    overflow: 'visible',
  },
});

export default GridStyles;
