import React, { useEffect, useRef, useState } from 'react';
import { View, FlatList, Animated, Easing } from 'react-native';
import { FlatListPerformanceView } from '../../libraries/ReactNativePerformanceShopify';
import List from '../List/List';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { styles } from './styles';
import ErrorBoundaryComponent from '../../components/shared/ErrorBoundaryComponent';
import TabGroup from './TabGroup';
import ListPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import { getItemName } from '../../utils/PerfUtility';
import { SEP } from '../../config/Constants';

const RenderShimmer = ({ display }) => {
  return (
    <View style={styles.shimmer}>
      <ListPlaceHolder columnCount={1} />
      {display === 'grid' && (
        <>
          <ListPlaceHolder columnCount={1} />
        </>
      )}
    </View>
  );
};

const MultiLevelTabs = (props) => {
  const {
    item = [],
    previousScreen = '',
    listData = {},
    parentListContent = '',
    parentListIndex = '',
    listIndex = '',
    parentListDisplay = '',
    tabListWidth,
  } = props;
  const [selectedList, setSelectedList] = useState({});
  const [tabs, setTabsData] = useState(item);
  const [defaultList, setdefaultList] = useState(item[1]);
  const [isListLoading, setListLoading] = useState(false);
  const [verticalOffsetAnimator, setVerticalOffsetAnimator] = useState(
    new Animated.Value(0),
  );
  const [requiredUserAttributes, setRequiredUserAttributes] = useState({});
  let scrollY = useRef(0).current; // Track previous scroll position

  const animatedStyle = {
    transform: [
      {
        translateY: verticalOffsetAnimator.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -350],
        }),
      },
    ],
  };

  const handleScroll = (event) => {
    const currentScrollY = event?.nativeEvent?.contentOffset?.y;
    if (currentScrollY <= scrollY || currentScrollY <= 120 || scrollY === 0) {
      // Scrolling Up
      Animated.timing(verticalOffsetAnimator, {
        toValue: 0,
        duration: 80,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(verticalOffsetAnimator, {
        toValue: scrollY <= 0 || currentScrollY <= 0 ? 0 : 1,
        duration: 80,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    }
    scrollY = currentScrollY;
  };

  useEffect(() => {
    handleScroll();
  });

  useEffect(() => {
    updateStateFromProps();
  }, [selectedList]);

  const updateStateFromProps = () => {
    tabs[1] = selectedList;
  };
  const flatListRef = useRef(null);
  if (isBlank(tabs)) return null;
  if (isPresent(selectedList)) {
    tabs[1] = selectedList;
  } else {
    tabs[1] = defaultList;
  }

  const setSelectedListData = (selectedData = {}) => {
    setSelectedList(selectedData);
    scrollToTop();
  };

  const setListLoadingState = (loadingState) => {
    setListLoading(loadingState);
  };

  const setListRef = (ref) => {
    flatListRef.current = ref;
  };

  const scrollToTop = () => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
  };

  const renderItem = ({ item, index }) => {
    if (isBlank(item) && isBlank(item?.objects)) return null;
    if (item?.type === 'list') {
      const { required_user_attributes = {} } = item;
      setRequiredUserAttributes(required_user_attributes);
      if (isListLoading && previousScreen !== SCREEN_CONSTANTS.MORE_PAGE) {
        // if the tablist comes under the middle of the list then this product card shimmer will show, otherwise List.js will handle the shimmer for mixed list
        return <RenderShimmer display={defaultList.display} />;
      }
      // added this to fix the vertical scroll jerk issue when multi level tablist is curated at the bottom of the list.
      const listContainerStyle =
        parentListIndex >= 1 &&
        parentListContent === 'mixed' &&
        defaultList.display === 'grid'
          ? styles.gridListContainer
          : styles.listContainer;
      return (
        <View style={listContainerStyle}>
          <List
            itemData={selectedList}
            setListRef={setListRef}
            previousScreen={previousScreen}
            listData={listData}
          />
        </View>
      );
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <TabGroup
          itemData={item?.objects}
          display={item?.display}
          setSelectedTabList={setSelectedListData}
          index={index}
          defaultList={defaultList}
          listData={listData}
          previousScreen={previousScreen}
          setListLoadingState={setListLoadingState}
          animatedStyle={animatedStyle}
          parentListContent={parentListContent}
          listIndex={listIndex}
          parentListDisplay={parentListDisplay}
          requiredUserAttributes={requiredUserAttributes}
          tabListWidth={tabListWidth}
        />
      </ErrorBoundaryComponent>
    );
  };

  if (isBlank(tabs)) return null;
  const keyExtractor = (item, index) => `${index}_${item?.type}`;
  return (
    <View style={styles.rootContainer}>
      <FlatList
        data={tabs}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        stickyHeaderIndices={[0]}
        ref={setListRef}
        extraData={isListLoading}
        onScroll={handleScroll}
        bounces={false}
      />
    </View>
  );
};

export default MultiLevelTabs;
