import AnalyticsManager from '../analytics/AnalyticsManager';
import Keychain from '../libraries/ReactNativeKeychain';
import BGNetworkTaskManager from '../utils/BGNetworkTaskManager';
import { isIOS, isWeb } from '../utils/BooleanUtility';

// Constants
export const CHANGE_COUNTRY_CODE = 'CHANGE_COUNTRY_CODE';
export const ADD_MOBILE_NUMBER = 'ADD_MOBILE_NUMBER';
export const INSERT_MOBILE_NUMBER = 'INSERT_MOBILE_NUMBER';
export const REQUEST_OTP = 'REQUEST_OTP';
export const TOGGLE_AUTOFILL = 'TOGGLE_AUTOFILL';
export const ADD_PROFILE_DETAILS = 'ADD_PROFILE_DETAILS';
export const ADD_PROFILE_DETAILS_SUCCESS = 'ADD_PROFILE_DETAILS_SUCCESS';
export const ADD_PROFILE_DETAILS_FAILURE = 'ADD_PROFILE_DETAILS_FAILURE';
export const ADD_PROFILE_PIC = 'ADD_PROFILE_PIC';
export const GET_INTERESTS_LIST = 'GET_INTERESTS_LIST';
export const TOGGLE_INTEREST = 'TOGGLE_INTEREST';
export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
export const VERIFY_HANDLE = 'VERIFY_HANDLE';
export const VERIFY_HANDLE_SUCCESS = 'VERIFY_HANDLE_SUCCESS';
export const VERIFY_HANDLE_FAILURE = 'VERIFY_HANDLE_FAILURE';
export const CLEAR_HANDLE_STATES = 'CLEAR_HANDLE_STATES';
export const CLEAR_OTP_ERROR = 'CLEAR_OTP_ERROR';
export const GET_LOCATION = 'GET_LOCATION';
export const ADD_LOCALITY = 'ADD_LOCALITY';
export const ADD_LAT_LONG = 'ADD_LAT_LONG';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_VERIFIED = 'USER_VERIFIED';
export const USER_SUSPICIOUS = 'USER_SUSPICIOUS';
export const AUTOCOMPLETE_LOCALITY = 'AUTOCOMPLETE_LOCALITY';
export const POST_SELECTED_INTEREST = 'POST_SELECTED_INTEREST';
export const TOGGLE_PRODUCT_SEARCHING_STATUS =
  'TOGGLE_PRODUCT_SEARCHING_STATUS';
export const UPDATE_CURRENT_POST_ID = 'UPDATE_CURRENT_POST_ID';
export const TOGGLE_UPDATE_CURRENT_POST_STATUS =
  'TOGGLE_UPDATE_CURRENT_POST_STATUS';
export const CLOSE_PRODUCT_TUTORIAL_VIDEO = 'CLOSE_PRODUCT_TUTORIAL_VIDEO';
export const CLOSE_SCAN_PRODUCT_TUTORIAL_VIDEO =
  'CLOSE_SCAN_PRODUCT_TUTORIAL_VIDEO';
export const UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const MARK_MEDIA_UPLOAD_BEGIN = 'MARK_MEDIA_UPLOAD_BEGIN';
export const SHOW_UPLOAD_TRAY_ON_FEED = 'SHOW_UPLOAD_TRAY_ON_FEED';
export const UPLOAD_MEDIA_TO_S3 = 'UPLOAD_MEDIA_TO_S3';
export const ABORT_UPLOAD = 'ABORT_UPLOAD';
export const MARK_MEDIA_UPLOAD_SUCCESSFUL = 'MARK_MEDIA_UPLOAD_SUCCESSFUL';
export const BLOT_MEDIA_UPLOAD_SUCCESSFUL = 'BLOT_MEDIA_UPLOAD_SUCCESSFUL';
export const GET_USER_INSTAGRAM_PROFILE = 'GET_USER_INSTAGRAM_PROFILE';
export const START_FACIAL_ANALYSIS = 'START_FACIAL_ANALYSIS';
export const PROFILE_PIC_UPLOAD_SUCCESSFUL = 'PROFILE_PIC_UPLOAD_SUCCESS';
export const UPLOAD_IMAGE_TO_S3 = 'UPLOAD_IMAGE_TO_S3';
export const STORE_CDN_PROFILE_PIC_URL = 'STORE_CDN_PROFILE_PIC_URL';
export const ANALYSE_FACE = 'ANALYSE_FACE';
export const GET_FACIAL_LANDMARKS_BY_ID = 'GET_FACIAL_LANDMARKS_BY_ID';

export const STORE_INSTAGRAM_DATA = 'STORE_INSTAGRAM_DATA';
export const MARK_CREATING_DIRECTORY = 'MARK_CREATING_DIRECTORY';
export const MARK_PRODUCT_TUTORIAL_VIDEO = 'MARK_PRODUCT_TUTORIAL_VIDEO';
export const MARK_SCANNING_TUTORIAL_VIDEO = 'MARK_SCANNING_TUTORIAL_VIDEO';
export const MARK_LOADING_MEDIA_CATEGORIES = 'MARK_LOADING_MEDIA_CATEGORIES';
export const MARK_CREATING_WATERMARK = 'MARK_CREATING_WATERMARK';
export const MARK_DOWNLOADING_LOGO = 'MARK_DOWNLOADING_LOGO';
export const STORE_MEDIA_CATEGORIES = 'STORE_MEDIA_CATEGORIES';
export const DISABLE_UPLOAD_TRAY_FROM_FEED = 'DISABLE_UPLOAD_TRAY_FROM_FEED';
export const STORE_CURRENT_OTP = 'STORE_CURRENT_OTP';
export const REQUESTED_FROM_HINT = 'REQUESTED_FROM_HINT';
export const SMS_RETRIEVER_STATUS = 'SMS_RETRIEVER_STATUS';
export const GET_USER_FACIAL_ATTRIBUTES = 'GET_USER_FACIAL_ATTRIBUTES';
export const GENERAL_USER_ATTRIBUTES = 'GENERAL_USER_ATTRIBUTES';
export const SAVE_GUEST_AUTH_TOKEN = 'SAVE_GUEST_AUTH_TOKEN';
export const SAVE_AUTH_TOKEN = 'SAVE_AUTH_TOKEN';
export const GUEST_FIRST_APP_OPEN = 'GUEST_FIRST_APP_OPEN';
export const SAVE_GUEST_STATE = 'SAVE_GUEST_STATE';
export const SELFIE_ANALYSIS_STATE = 'SELFIE_ANALYSIS_STATE';
export const SAVE_SELFIE_RESPONSE = 'SAVE_SELFIE_RESPONSE';
export const FETCH_INTERESTS = 'FETCH_INTERESTS';
export const NEW_USER = 'NEW_USER';
export const UPDATE_USER_ATTRIBUTE = 'UPDATE_USER_ATTRIBUTE';
export const IMAGE_UPLOAD_PERCENTAGE = 'IMAGE_UPLOAD_PERCENTAGE';
export const SHOW_IMAGE_PROGRESSBAR = 'SHOW_IMAGE_PROGRESSBAR';
export const USER_FACIAL_PROPERTIES = 'USER_FACIAL_PROPERTIES';
export const SHOW_POPOVER = 'SHOW_POPOVER';
export const LOGIN_MODAL_STATE = 'LOGIN_MODAL_STATE';
export const SET_PHONE_NUMBER_MODAL_INFO = 'SET_PHONE_NUMBER_MODAL_INFO';
export const SEND_OTP_OVER_CALL = 'SEND_OTP_OVER_CALL';
export const ARE_ATTRIBUTES_REVIEWED = 'ARE_ATTRIBUTES_REVIEWED';
export const SEND_OTP_OVER_WHATSAPP = 'SEND_OTP_OVER_WHATSAPP';
export const HAS_SELFIE = 'HAS_SELFIE';
export const SAVE_MY_SELFIE_ATTRIBUTES = 'SAVE_MY_SELFIE_ATTRIBUTES';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const APP_LAST_OPEN_AT = 'APP_LAST_OPEN_AT';
export const GET_ALLOWED_ATTRIBUTES = 'GET_ALLOWED_ATTRIBUTES';
export const SAVE_FACE_ANALYSIS_DATA = 'SAVE_FACE_ANALYSIS_DATA';
export const DELETE_FACE_ANALYSIS_DATA = 'DELETE_FACE_ANALYSIS_DATA';
export const VERIFY_TRUECALLER = 'VERIFY_TRUECALLER';
export const RETRY_SELFIE_ANALYSIS_PROCESS = 'RETRY_SELFIE_ANALYSIS_PROCESS';
export const SAVE_LOCAL_PROFILE_IMAGE_URI = 'SAVE_LOCAL_PROFILE_IMAGE_URI';

export const RESUME_SELFIE_ATTRIBUTE_FETCH = 'RESUME_SELFIE_ATTRIBUTE_FETCH';
export const RESUME_SELFIE_ATTRIBUTE_MAPPING =
  'RESUME_SELFIE_ATTRIBUTE_MAPPING';

export const SAVE_APP_KILL_REASON = 'SAVE_APP_KILL_REASON';
export const SET_GENDER_USER_ATTRIBUTE = 'SET_GENDER_USER_ATTRIBUTE';
export const SET_FORCE_LOGGED_OUT_FLOW = 'SET_FORCE_LOGGED_OUT_FLOW';
export const ADD_INSTALLED_APPS_LIST = 'ADD_INSTALLED_APPS_LIST';

export const SET_APP_INSTALL_SOURCE = 'SET_APP_INSTALL_SOURCE';
export const UPDATE_USERS_DATA = 'UPDATE_USERS_DATA';
export const POST_VIDEO_VIEW = 'POST_VIDEO_VIEW';
export const SHOW_PROFILE_SHIMMER = 'SHOW_PROFILE_SHIMMER';
export const IS_USER_LOGGED_OUT_ONCE = 'IS_USER_LOGGED_OUT_ONCE';
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const UNLINK_DEVICE = 'UNLINK_DEVICE';
export const SAVE_ACTIVE_GROUP_DEALS = 'SAVE_ACTIVE_GROUP_DEALS';
export const HAS_PREVIOUS_ASSOCIATED_ACCOUNT =
  'HAS_PREVIOUS_ASSOCIATED_ACCOUNT';

export const RESTORE_PREVIOUS_ACCOUNT = 'RESTORE_PREVIOUS_ACCOUNT';
export const REGISTER_GUEST_USER = 'REGISTER_GUEST_USER';
export const SAVE_PUBLIC_ID = 'SAVE_PUBLIC_ID';
export const UPDATE_USER_AUTHORIZED = 'UPDATE_USER_AUTHORIZED';
export const ADD_WEB_TOKENS = 'ADD_WEB_TOKENS';
export const REFRESH_PROFILE_DATA = 'REFRESH_PROFILE_DATA';

export function changeCountryCode(code) {
  return {
    type: CHANGE_COUNTRY_CODE,
    countryCode: code.countryCode,
    callingCode: code.callingCode,
  };
}

export function toggleAutofill() {
  return {
    type: TOGGLE_AUTOFILL,
  };
}

export function addMobileNumber(
  mobileNumber,
  deviceInfo,
  callback,
  fromHintPrompt = false,
  callingCode,
  countryCode,
) {
  return {
    type: ADD_MOBILE_NUMBER,
    mobileNumber,
    callback,
    deviceInfo,
    fromHintPrompt,
    callingCode,
    countryCode,
  };
}

export function insertMobileNumber(mobileNumber, deviceInfo) {
  return {
    type: INSERT_MOBILE_NUMBER,
    mobileNumber,
    deviceInfo,
  };
}

export function requestOTP() {
  return {
    type: REQUEST_OTP,
  };
}

export function addProfileDetails(userinfo, callback) {
  return {
    type: ADD_PROFILE_DETAILS,
    userinfo,
    callback,
  };
}

export function addProfileDetailsSuccess(name, email, handle) {
  return {
    type: ADD_PROFILE_DETAILS_SUCCESS,
    name,
    email,
    handle,
  };
}

export function addProfileDetailsFailure(errorCode, errorMessage) {
  return {
    type: ADD_PROFILE_DETAILS_FAILURE,
    errorCode,
    errorMessage,
  };
}

export function addProfilePic(imageUri) {
  return {
    type: ADD_PROFILE_PIC,
    imageUri,
  };
}

export function getInterestsList(interestsList) {
  return {
    type: GET_INTERESTS_LIST,
    interestsList,
  };
}

export function toggleInterest(isInterestSelected, interestID) {
  return {
    type: TOGGLE_INTEREST,
    isInterestSelected,
    interestID,
  };
}

export function sendOtp() {
  return {
    type: SEND_OTP,
  };
}

export function verifyOtp(otp, callback) {
  return {
    type: VERIFY_OTP,
    otp,
    callback,
  };
}

export function verifyOtpSuccess(user) {
  const { auth_token, guest_token } = user;
  if (isWeb()) {
    localStorage.setItem('guest_token', guest_token);
    localStorage.setItem('auth_token', auth_token);
  }

  if (!isWeb()) {
    Keychain.setGenericPassword('guestToken', guest_token, {
      service: 'guestToken',
    })
      .then((res) => {
        if (isIOS()) {
          AnalyticsManager.setUserId(guest_token);
        }
        console.tron.log('SUCCESS Guest Token ', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });

    Keychain.setGenericPassword('authToken', auth_token, {
      service: 'authToken',
    })
      .then((res) => {
        console.tron.log('SUCCESS Auth Token ', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });
  }

  return {
    type: VERIFY_OTP_SUCCESS,
    user,
  };
}

export function refreshProfileData(user) {
  return {
    type: REFRESH_PROFILE_DATA,
    user,
  };
}

export function updateUserData(user) {
  return {
    type: UPDATE_USERS_DATA,
    user,
  };
}

export function updateUserAuthorized(authorized) {
  return {
    type: UPDATE_USER_AUTHORIZED,
    authorized,
  };
}

export function verifyOtpFailure(error) {
  return {
    type: VERIFY_OTP_FAILURE,
    error,
  };
}

export function clearOTPError() {
  return {
    type: CLEAR_OTP_ERROR,
  };
}

export function verifyHandle(handle) {
  return {
    type: VERIFY_HANDLE,
    handle,
  };
}

export function verifyHandleSuccess() {
  return {
    type: VERIFY_HANDLE_SUCCESS,
  };
}

export function verifyHandleFailure(error) {
  return {
    type: VERIFY_HANDLE_FAILURE,
    error,
  };
}

export function clearHandleStates() {
  return {
    type: CLEAR_HANDLE_STATES,
  };
}

export function getLocation() {
  return {
    type: GET_LOCATION,
  };
}

export function addLocality(location) {
  return {
    type: ADD_LOCALITY,
    location,
  };
}

export function addLatLong(latitude, longitude, callback) {
  return {
    type: ADD_LAT_LONG,
    latitude,
    longitude,
    callback,
  };
}

export function userRegistered() {
  return {
    type: USER_REGISTERED,
  };
}

export function userAuthenticated(authenticated) {
  return {
    type: USER_AUTHENTICATED,
    authenticated,
  };
}

export function userVerified() {
  return {
    type: USER_VERIFIED,
  };
}

export function userSuspicious() {
  return {
    type: USER_SUSPICIOUS,
  };
}

export function autoCompleteLocality(localityHint, callback) {
  return {
    type: AUTOCOMPLETE_LOCALITY,
    localityHint,
    callback,
  };
}

export function postSelectedInterest() {
  return {
    type: POST_SELECTED_INTEREST,
  };
}

export function postVideoView(videoId) {
  return {
    type: POST_VIDEO_VIEW,
    id: videoId,
  };
}

export function toggleProductSearchingStatus(status) {
  return {
    type: TOGGLE_PRODUCT_SEARCHING_STATUS,
    status,
  };
}

export function updateCurrentPostID(postID) {
  return {
    type: UPDATE_CURRENT_POST_ID,
    postID,
  };
}

export function toggleUpdateCurrentPostStatus(status) {
  return {
    type: TOGGLE_UPDATE_CURRENT_POST_STATUS,
    status,
  };
}

export function closeProductTutorialVideo() {
  return {
    type: CLOSE_PRODUCT_TUTORIAL_VIDEO,
  };
}

export function closeScanProductTutorialVideo() {
  return {
    type: CLOSE_SCAN_PRODUCT_TUTORIAL_VIDEO,
  };
}

export function updateOnboardingStatus(status) {
  return {
    type: UPDATE_ONBOARDING_STATUS,
    status,
  };
}

export function logoutUser() {
  BGNetworkTaskManager.deleteAuthToken();
  if (isWeb()) {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('guest_token');
  }
  if (!isWeb()) {
    Keychain.resetGenericPassword({
      service: 'guestToken',
    })
      .then((res) => {
        console.tron.log('SUCCESS delete Guest Token ', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });

    Keychain.resetGenericPassword({
      service: 'authToken',
    })
      .then((res) => {
        console.tron.log('SUCCESS delete authToken', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });
  }

  return {
    type: LOGOUT_USER,
  };
}

export function markMediaUploadBegin() {
  return {
    type: MARK_MEDIA_UPLOAD_BEGIN,
  };
}

export function showUploadTrayOnFeed(showUploadTray) {
  return {
    type: SHOW_UPLOAD_TRAY_ON_FEED,
    showUploadTray,
  };
}

export function uploadMediaToS3(
  formData,
  fileUri,
  postUrl,
  callback,
  markUploadingDone = false,
) {
  return {
    type: UPLOAD_MEDIA_TO_S3,
    formData,
    fileUri,
    postUrl,
    callback,
    markUploadingDone,
  };
}

export function abortMediaUpload() {
  return {
    type: ABORT_UPLOAD,
  };
}

export function markMediaUploadSuccessful() {
  return {
    type: MARK_MEDIA_UPLOAD_SUCCESSFUL,
  };
}

export function blotMediaUploadSuccessful(
  mediaFileName,
  tusFilePath,
  callback,
) {
  return {
    type: BLOT_MEDIA_UPLOAD_SUCCESSFUL,
    mediaFileName,
    tusFilePath,
    callback,
  };
}

export function getUserInstagramProfile(code, callback) {
  return {
    type: GET_USER_INSTAGRAM_PROFILE,
    code,
    callback,
  };
}

export function startFacialAnalysis(imageName, imageUri, imageType, callback) {
  return {
    type: START_FACIAL_ANALYSIS,
    imageName,
    imageUri,
    callback,
    imageType,
  };
}

export function postImageToS3(formData, fileUri, postUrl, action) {
  return {
    type: UPLOAD_IMAGE_TO_S3,
    formData,
    fileUri,
    postUrl,
    action,
  };
}

export function markProfilePicUploadSuccess(profilePicName, action) {
  return {
    type: PROFILE_PIC_UPLOAD_SUCCESSFUL,
    profilePicName,
    action,
  };
}

export function saveCDNlinktoRedux(cdnPath) {
  return {
    type: STORE_CDN_PROFILE_PIC_URL,
    cdnPath,
  };
}

export function analyseFace(source, callback) {
  return {
    type: ANALYSE_FACE,
    source,
    callback,
  };
}

export function getFaceLandmarksById(imgUid, callback) {
  return {
    type: GET_FACIAL_LANDMARKS_BY_ID,
    imgUid,
    callback,
  };
}
export function saveInstagramProfile(instagramProfile) {
  return {
    type: STORE_INSTAGRAM_DATA,
    instagram_profile: instagramProfile,
  };
}

export function markCreatingDirectory() {
  return {
    type: MARK_CREATING_DIRECTORY,
  };
}

export function markLoadingMediaCategories() {
  return {
    type: MARK_LOADING_MEDIA_CATEGORIES,
  };
}

export function markProductTutorialVideo() {
  return {
    type: MARK_PRODUCT_TUTORIAL_VIDEO,
  };
}

export function markScanningTutorialVideo() {
  return {
    type: MARK_SCANNING_TUTORIAL_VIDEO,
  };
}

export function markCreatingWatermark() {
  return {
    type: MARK_CREATING_WATERMARK,
  };
}

export function markDownloadingLogo() {
  return {
    type: MARK_DOWNLOADING_LOGO,
  };
}

export function storeMediaCategories(mediaCategories) {
  return {
    type: STORE_MEDIA_CATEGORIES,
    mediaCategories,
  };
}

export function disableUploadTrayFromFeed() {
  return {
    type: DISABLE_UPLOAD_TRAY_FROM_FEED,
  };
}

export function storeCurrentOtp(otp) {
  return {
    type: STORE_CURRENT_OTP,
    otp,
  };
}

export function isRequestedFromHintPrompt(isHintRequest) {
  return {
    type: REQUESTED_FROM_HINT,
    isHintRequest,
  };
}
export function smsRetrieverStatus(status) {
  return {
    type: SMS_RETRIEVER_STATUS,
    status,
  };
}

export function getUserFacialAttributes(source, callback) {
  return {
    type: GET_USER_FACIAL_ATTRIBUTES,
    source,
    callback,
  };
}

export function getUserAttributes(callback) {
  return {
    type: GENERAL_USER_ATTRIBUTES,
    callback,
  };
}

export function saveGuestAuthToken(guestAuthToken) {
  if (isWeb()) {
    localStorage.setItem('guest_token', guestAuthToken);
  }
  if (!isWeb()) {
    Keychain.setGenericPassword('guestToken', guestAuthToken, {
      service: 'guestToken',
    })
      .then((res) => {
        if (isIOS()) {
          AnalyticsManager.setUserId(guestAuthToken);
        }
        console.tron.log('SUCCESS Guest Token ', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });
  }

  return {
    type: SAVE_GUEST_AUTH_TOKEN,
    guestAuthToken,
  };
}

export function saveAuthTokenToDevice(authToken) {
  if (!isWeb()) {
    Keychain.setGenericPassword('authToken', authToken, {
      service: 'authToken',
    })
      .then((res) => {
        console.tron.log('SUCCESS Auth', res);
      })
      .catch((err) => {
        console.tron.log('ERROR', err);
      });
  }

  return {
    type: SAVE_AUTH_TOKEN,
    authToken,
  };
}

export function guestFirstAppOpenTime(firstOpenTime) {
  return {
    type: GUEST_FIRST_APP_OPEN,
    firstOpenTime,
  };
}

export function availableApps(appList) {
  return {
    type: ADD_INSTALLED_APPS_LIST,
    appList,
  };
}

export function saveUserState(currentState) {
  return {
    type: SAVE_GUEST_STATE,
    currentState,
  };
}

export function selfieAnalysisState(currentState) {
  return {
    type: SELFIE_ANALYSIS_STATE,
    currentState,
  };
}
export function selfieImageData(dataObject) {
  return {
    type: SAVE_SELFIE_RESPONSE,
    dataObject,
  };
}
export function fetchInterests(callback) {
  return {
    type: FETCH_INTERESTS,
    callback,
  };
}

export function newUser(isNewUser, has_selfie) {
  return {
    type: NEW_USER,
    isNewUser,
    has_selfie,
  };
}

export function updateUserAttributeRemote(data) {
  return {
    type: UPDATE_USER_ATTRIBUTE,
    data,
  };
}

export function imageUploadPercentage(uploadPercentage) {
  return {
    type: IMAGE_UPLOAD_PERCENTAGE,
    uploadPercentage,
  };
}
export function showImageProgressBar(isVisible) {
  return {
    type: SHOW_IMAGE_PROGRESSBAR,
    showImageProgressBar: isVisible,
  };
}
export function userFacialProperties(userFacialProperties) {
  return {
    type: USER_FACIAL_PROPERTIES,
    userFacialProperties,
  };
}

export function showPopover(showPopOver) {
  return {
    type: SHOW_POPOVER,
    showPopOver,
  };
}

export function changeLoginModalState(loginModalState) {
  return {
    type: LOGIN_MODAL_STATE,
    loginModalState,
  };
}

export function setPhoneNumberModalInfo(loginModalInfo) {
  return {
    type: SET_PHONE_NUMBER_MODAL_INFO,
    loginModalInfo,
  };
}

export function updateAttributeLocal(attributeValues) {
  return {
    type: UPDATE_ATTRIBUTE,
    attributeValues,
  };
}

export function sendOtpOverCall(callback) {
  return {
    type: SEND_OTP_OVER_CALL,
    callback,
  };
}

export function areAttributesReviewed(attributeReviewed) {
  return {
    type: ARE_ATTRIBUTES_REVIEWED,
    attributeReviewed,
  };
}
export function sendOtpOverWhatsapp(callback) {
  return {
    type: SEND_OTP_OVER_WHATSAPP,
    callback,
  };
}

export function saveMySelfieAttributes(attributes) {
  return {
    type: SAVE_MY_SELFIE_ATTRIBUTES,
    attributes,
  };
}

export function appLastOpenAt(app_last_open_at) {
  return {
    type: APP_LAST_OPEN_AT,
    app_last_open_at,
  };
}

export function getAllowedAttributes() {
  return {
    type: GET_ALLOWED_ATTRIBUTES,
  };
}

export function saveFaceAnalysisData(data) {
  return { type: SAVE_FACE_ANALYSIS_DATA, data };
}

export function deleteFaceAnalysisData() {
  return { type: DELETE_FACE_ANALYSIS_DATA };
}

export function verifyTrueCaller(data, callback) {
  return {
    type: VERIFY_TRUECALLER,
    data,
    callback,
  };
}

export function retrySelfieAnalysis(analysisReviewPopupRequired) {
  return {
    type: RETRY_SELFIE_ANALYSIS_PROCESS,
    analysisReviewPopupRequired,
  };
}

export function saveLocalFileImageUri(local_image_uri) {
  return {
    type: SAVE_LOCAL_PROFILE_IMAGE_URI,
    local_image_uri,
  };
}

export function resumeSelfieFromUserAttributeFetching(
  image_id,
  analysisReviewPopupRequired,
) {
  return {
    type: RESUME_SELFIE_ATTRIBUTE_FETCH,
    image_id,
    analysisReviewPopupRequired,
  };
}

export function resumeSelfieFromUserAttributesMapping(user_attributes) {
  return { type: RESUME_SELFIE_ATTRIBUTE_MAPPING, user_attributes };
}

export function setAppKillReason(reason) {
  return {
    type: SAVE_APP_KILL_REASON,
    reason,
  };
}

export function setGenderUserAttribute(object) {
  return {
    type: SET_GENDER_USER_ATTRIBUTE,
    object,
  };
}

export function setForcedLogoutFlow(isForced) {
  return {
    type: SET_FORCE_LOGGED_OUT_FLOW,
    isForced,
  };
}

export function setAppInstallSource(installSource) {
  return {
    type: SET_APP_INSTALL_SOURCE,
    installSource,
  };
}

export function showProfileShimmer(show) {
  return {
    type: SHOW_PROFILE_SHIMMER,
    show,
  };
}

export function isUserLoggedOutOnce(loggedOut) {
  return {
    type: IS_USER_LOGGED_OUT_ONCE,
    isUserLogoutOnce: loggedOut,
  };
}

export function updateMobileNumber(mobileNumber) {
  return {
    type: UPDATE_PHONE_NUMBER,
    mobileNumber,
  };
}

export function unlinkDevice() {
  return {
    type: UNLINK_DEVICE,
  };
}

export function saveActiveGroupDeals(activeGroupDeals) {
  return {
    type: SAVE_ACTIVE_GROUP_DEALS,
    activeGroupDeals,
  };
}

export function setPreviousAssociatedAccount(accountExist) {
  return {
    type: HAS_PREVIOUS_ASSOCIATED_ACCOUNT,
    accountExist,
  };
}

export function restorePreviousAccount() {
  return {
    type: RESTORE_PREVIOUS_ACCOUNT,
  };
}

export function registerGuestUser(source) {
  return {
    type: REGISTER_GUEST_USER,
    source,
  };
}

export function savePublicId(publicId) {
  return {
    type: SAVE_PUBLIC_ID,
    publicId,
  };
}

export function addWebTokens(guestToken, authToken) {
  return {
    type: ADD_WEB_TOKENS,
    guestToken,
    authToken,
  };
}
