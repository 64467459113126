import React, { useCallback } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { getDynamicWidthForGrid } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import { memoize } from 'lodash';
import { isBlank } from '../../utils/BooleanUtility';
import { useNavigation } from '@react-navigation/native';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { navigateToScreen } from '../../utils/NavigationUtility';

export default function MilestoneRewardsCard(props) {
  const {
    itemData: {
      title = '',
      body = '',
      arguments: args = {},
      image_url: imageUrl = '',
      background_color: backgroundColor = '#FFF6F6',
      destination = '',
      id = '',
      type = '',
      display = '',
    } = {},
    previousScreen = '',
  } = props;
  const navigation = useNavigation();
  const itemArguments = isBlank(args) ? {} : args;
  const { rewards_count = 0, rewards_amount = '' } = itemArguments;

  const handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: route,
      params: { slug, extra },
    });
  };

  const navigateToDestination = useCallback(() => {
    if (isBlank(destination)) return;
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: previousScreen,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_TYPE]: type,
      [EventParameterKey.ITEM_NAME]: title,
      [EventParameterKey.DISPLAY]: display,
    });
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      destination,
      handleRouteFromLink,
    );
  }, [destination]);

  return (
    <View
      style={memoize(
        () => [styles.container, { backgroundColor }],
        () => [backgroundColor],
      )()}
    >
      <Text style={styles.title} numberOfLines={1} ellipseMode='tail'>
        {title}
      </Text>
      <View style={styles.rewardContainer}>
        <FastImageView source={imageUrl} style={styles.image} />
        <Text style={styles.reward_amount}>{rewards_count}</Text>
      </View>
      {rewards_count > 0 ? (
        <View>
          <Text style={styles.subtitle} numberOfLines={1} ellipseMode='tail'>
            {body}
          </Text>
          <Text style={styles.reward_amount}>
            {CURRENCY_SYMBOL}
            {rewards_amount}
          </Text>
        </View>
      ) : (
        <TouchableOpacity onPress={navigateToDestination} style={styles.ctaContainer}>
          <Text style={styles.ctaText}>SHOP NOW</Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: getDynamicWidthForGrid(2, 16),
    backgroundColor: '#FFF6F6',
    margin: 4,
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    color: colors.black,
    fontFamily: 'Roboto-Medium',
  },
  subtitle: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Medium',
  },
  reward_amount: {
    fontSize: 20,
    color: colors.black,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    alignSelf: 'center',
  },
  rewardContainer: {
    flexDirection: 'row',
    marginVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 44,
    width: 44,
    resizeMode: 'contain',
    marginRight: 4,
  },
  ctaText: {
    fontSize: 12,
    color: '#6D6A6A',
    fontFamily: 'Roboto-Medium',
  },
  ctaContainer: {
    borderWidth: 1,
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderColor: colors.subtitle,
    marginTop: 4,
  },
});
