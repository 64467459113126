// Dependencies
import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Animated,
  Easing,
  StyleSheet,
  Clipboard,
} from 'react-native';
import Modal from 'react-native-modal';
import { ScrollView } from 'react-native-gesture-handler';
import Toast from 'react-native-easy-toast';
import { ProductDetailStyles } from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import ActionButton from '../shared/ActionButton';
import { MAX_LINES, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { PromoCodeStyles } from '../cart/styles';
import RemoteConfig from '../../utils/RemoteConfig';
import _ from 'lodash';

class OfferTermsModal extends Component {
  constructor(props) {
    super(props);
    this.scrollViewRef = React.createRef();
    this.bottomDockHeight = 70;
  }
  initialModalHeight = 520;
  state = {
    modalHeight: new Animated.Value(this.initialModalHeight),
  };

  expanded = false;

  closeModal = () => {
    this.expanded = false;
    this.props.cancelOffer();
    this.setState({
      modalHeight: new Animated.Value(this.initialModalHeight),
    });
  };

  handleOnScroll = (event) => {
    if (!this.expanded) {
      this.expanded = true;
      this.expandView();
    }
    this.setState({
      scrollOffset: event.nativeEvent.contentOffset.y,
    });
  };

  handleScrollTo = (value) => {
    if (this.scrollViewRef) {
      this.scrollViewRef.current.scrollTo(value);
    }
  };

  copyCodePressed = (item) => {
    Clipboard.setString(item.coupon_code);
    this.toast.show('Coupon code copied');
  };

  expandView = () => {
    Animated.timing(this.state.modalHeight, {
      toValue: Utility.getWindowHeight() - Utility.topInset,
      duration: 300,
      easing: Easing.linear,
    }).start();
  };

  bottomButtonDock = (props) => {
    const { onClaimOffer } = this.props;
    const styles = ProductDetailStyles;
    const buttonText = 'Claim Offer';
    return (
      <View
        style={[
          styles.actionBottonContainer,
          { height: this.bottomDockHeight + Utility.bottomInset },
        ]}
      >
        <ActionButton
          onActionPress={onClaimOffer}
          title={buttonText}
          actionButtonColor={colors.black}
        />
      </View>
    );
  };

  tapToApplyCouponCode = (props) => {
    const { item } = props;
    if (item.offer_type && item.offer_type === 'offer_discount') {
      return null;
    }
    const stylePromocodeContainer = { maxWidth: Utility.getScreenWidth() / 2 };
    return (
      <TouchableOpacity
        style={ProductDetailStyles.couponCodeView}
        onPress={() => this.copyCodePressed(item)}
      >
        <View
          style={[PromoCodeStyles.promoIDContainer, stylePromocodeContainer]}
        >
          <Text
            style={PromoCodeStyles.promoIdText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.coupon_code}
          </Text>
        </View>
        <Text style={ProductDetailStyles.tapToCopyCouponCodeText}>
          TAP TO COPY CODE
        </Text>
      </TouchableOpacity>
    );
  };

  couponCodeView = (props) => {
    const { item } = props;
    const styles = ProductDetailStyles;

    return (
      <View style={{ paddingLeft: 15, paddingRight: 15, marginTop: 10 }}>
        <View style={[styles.horizontalLine, { marginTop: 8 }]} />
        <this.tapToApplyCouponCode item={item} />
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  descriptionText = (props) => {
    const { short_description } = props;
    if (Utility.isBlank(short_description)) {
      return null;
    }
    return (
      <Text
        style={[
          ProductDetailStyles.offerName,
          {
            fontSize: 14,
            color: colors.subtitle,
          },
        ]}
      >
        {short_description}
      </Text>
    );
  };

  bulletPoint = ({ title }) => {
    return (
      <View style={TermsModalStyles.bulletPointContainer}>
        <Text style={TermsModalStyles.bullet}>{'  •   '}</Text>
        <Text style={TermsModalStyles.bulletPoint}>{title}</Text>
      </View>
    );
  };

  render() {
    const { isOfferModalVisible } = this.props;

    const mild_delay_offer_terms = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.mild_delay_offer_terms),
    );
    const styles = ProductDetailStyles;
    return (
      <Modal
        isVisible={isOfferModalVisible}
        onBackdropPress={this.closeModal}
        style={styles.offerModal}
        swipeDirection="down"
        onSwipeComplete={this.closeModal}
        scrollTo={this.handleScrollTo}
        scrollOffset={this.state.scrollOffset}
        scrollOffsetMax={100}
        onRequestClose={this.closeModal}
      >
        <View style={styles.offerTerms}>
          <View style={styles.offerTermsHeading}>
            <Text
              style={styles.offerTermsHeadingText}
              numberOfLines={MAX_LINES.single}
            >
              Terms & Conditions
            </Text>
          </View>

          <ScrollView
            ref={this.scrollViewRef}
            onScroll={this.handleOnScroll}
            scrollEventThrottle={16}
          >
            <View style={styles.termsAndConditionText}>
              {_.map(mild_delay_offer_terms, (mdot) => {
                return <this.bulletPoint title={mdot} />;
              })}
            </View>
          </ScrollView>
          <this.bottomButtonDock />
        </View>
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </Modal>
    );
  }
}

const TermsModalStyles = StyleSheet.create({
  bulletPointContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
    flexShrink: 1,
  },
  bullet: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  bulletPoint: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
});
export default OfferTermsModal;
