import React, { PureComponent } from 'react';
import { View, InteractionManager } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sortBy, shuffle, debounce, camelCase } from 'lodash';
import {
  AddressBeforeLoginAppConfig,
  CATEGORY_BROWSING_PAGINATION_API_LIMIT,
  EMPTY_OBJECT,
  EMPTY_STRING,
  LAYOUT,
  ORIENTATION,
  PAGINATION_API_LIMIT,
} from '../../config/Constants';
import {
  fetchList,
  callbackPaginatedList,
  toggleDynamicListTitleVisibility,
} from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ScreenPlaceholder from '../../components/shared/ScreenPlaceholder';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import Vertical from '../../components/layout/Vertical/Vertical';
import GridScreenPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import styles from './styles';
import SlimRailShimmer from '../../components/shared/SlimRailShimmer';

import FilterUtils, {
  canOpenSortingModal,
} from '../../components/sort-filter-options/FilterUtils';
import { Header } from '../../components/header';
import { getListRefHelper } from './listRefHelper';
import WebFooter from '../../components/webFooter/WebFooter';
import { isWeb, isPresent } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

const DEFAULT_MAX_PAGINATED_ITEMS = 500;
class List extends PureComponent {
  constructor(props) {
    super(props);
    const { showDynamicListShimmer = false } = this.props;
    this.state = {
      callbackData: {},
      isLoading: showDynamicListShimmer,
      loadActivityIndicator: false,
      listPageApiLoadFailure: false,
      timeStamp: 0,
      serverError: false,
      connectionError: false,
      retryLoading: false,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      filtersEnabled: false,
      filteredSlug: '',
      isDynamic: false,
      filtersList: {},
    };
    this.page = 0;
    this.shouldLoadMore = true;
    this.debounceHandleLodeMore =
      typeof this.props.handleLoadMore === 'function'
        ? debounce(this.props.handleLoadMore, 500)
        : () => {};
    this.paginatedPageNumber = 1;
    this.paginatedListSlug = '';
    const { previousScreen = '' } = this.props;
    const { listData: { display: listDisplay = '' } = {} } = this.props;
    this.callPaginatedList = previousScreen !== SCREEN_CONSTANTS.MORE_PAGE || (listDisplay === 'vertical' || listDisplay === 'section'); // added previous screen check to resolve double pagination on More Items Page in case paginate_last_item is true for the list.
    this.displayCountPaginatedItems = 0;
    this.appliedFilterCounter = 0; // indicates if any filter is applied. helpful to maintain data when filter is applied but previous callback merge data
    this.paginatingLastItem = false;
    this.componentOffsetList = {};
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.prevAppliedFiltersFromModal = [];
    if (
      this.props.previousScreen === SCREEN_CONSTANTS.FEED &&
      this.props.args?.length > 0 &&
      this.props.args[0]?.value === 'recentlyViewedProductIds'
    ) {
      Utility.refreshDynamicListFn = this.onRefreshDynamicList;
    }
  }

  componentDidMount() {
    const {
      setListPaginatedCallApiRef,
      setFnReloadDynamicList,
      onRef,
      navigation,
    } = this.props;
    if (onRef != null) {
      onRef(this);
    }
    if (setFnReloadDynamicList) {
      setFnReloadDynamicList(this.onRefreshDynamicList);
    }
    this.didFocusListener = navigation.addListener('focus', () => {
      this.onRefreshDynamicList();
    });
    this.hitApiForList();
    this.checkForLastPaginatedItem();
    if (setListPaginatedCallApiRef) {
      setListPaginatedCallApiRef(this.autoHitPaginatedAPI);
    }
  }
 
  componentDidUpdate(prevProps) {
    const { cartPrompts, previousScreen } = this.props;
    if (
      !AddressBeforeLoginAppConfig ||
      previousScreen !== SCREEN_CONSTANTS.LIST_TAB
    )
      return;
    if (JSON.stringify(prevProps.cartPrompts) !== JSON.stringify(cartPrompts)) {
      this.onRefresh();
    }
  }

  componentWillUnmount() {
    this.didFocusListener();
  }

  checkForLastPaginatedItem = () => {
    const {
      itemData,
      paginate,
      totalItemsInList = 0,
      index = 0,
      paginateLastItem,
      filteredSlug,
      parentListsData: {
        paginate_last_item: parentListPaginateLastItem = false,
      } = {},
    } = this.props;
    const shouldPaginateLastItemInList =
      paginateLastItem && totalItemsInList === index;
    if (
      Utility.isBlank(itemData) ||
      (!paginate &&
        !parentListPaginateLastItem &&
        !shouldPaginateLastItemInList)
    ) {
      return;
    }
    if (!parentListPaginateLastItem) {
      this.paginatingLastItem = paginateLastItem;
    }
    this.paginatedListSlug =
      filteredSlug && !paginate ? filteredSlug : itemData.slug;
    this.callbackPaginatedValues();
  };

  callbackPaginatedValues = () => {
    const {
      callbackPaginatedList,
      use_modified_product_list = false,
      hideOosProduct = false,
    } = this.props;
    callbackPaginatedList(
      this.paginatedListSlug,
      this.paginatedPageNumber,
      this.paginatedApiListCallback,
      this.appliedFilterCounter,
      use_modified_product_list,
      hideOosProduct,
    );
  };

  navigateToSearch = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  paginatedApiListCallback = (
    success,
    paginatedData,
    prevAppliedFilterCounter,
  ) => {
    let {
      itemData,
      paginate,
      paginateLastItem,
      parentListsData: {
        paginate_last_item: parentListPaginateLastItem = false,
      } = {},
    } = this.props;
    if (prevAppliedFilterCounter !== this.appliedFilterCounter) {
      return;
    }
    let allObjects = itemData.objects;
    const { objects: paginatedObjects = [] } = paginatedData;
    const { objects = [] } = itemData;
    if (
      success &&
      (paginateLastItem || parentListPaginateLastItem) && //added paginate_last_item from itemData since on home page we're using just slug and not sending paginateLastItem as prop.
      paginatedObjects.length > 0 &&
      this.callPaginatedList
    ) {
      if (Utility.isPresent(paginatedData)) {
        //FIXME: This is a hack for category page as of now. Creating a new issue to fix all the pagination on list page
        if (itemData.display === 'vertical') {
          const sizeOfObject = itemData.objects.length - 1;
          allObjects[sizeOfObject].objects = [
            ...allObjects[sizeOfObject].objects,
            ...paginatedObjects,
          ];
          const listObject = allObjects;
          const updatedItemData = { ...itemData, objects: listObject };
          this.setState(
            { callbackData: updatedItemData, loadActivityIndicator: true },
            this.autoHitAndSetTimeStamp,
          );
        } else {
          const newObjects = [...allObjects, ...paginatedObjects];
          itemData.objects = newObjects;
          const updatedItemData = { ...itemData, objects: newObjects };
          this.setState(
            { callbackData: updatedItemData, loadActivityIndicator: true },
            this.autoHitAndSetTimeStamp,
          );
        }
      }
    } else if (
      paginate &&
      paginatedObjects.length > 0 &&
      this.callPaginatedList
    ) {
      let newObjects = [];
      newObjects = [...objects, ...paginatedObjects];
      itemData.objects = newObjects;
      this.setState(
        { callbackData: { ...itemData }, loadActivityIndicator: true },
        this.autoHitAndSetTimeStamp,
      );
      this.loadMoreValues = true;
    } else if (paginate && paginatedObjects.length <= 0) {
      this.callPaginatedList = false;
      this.setState({ loadActivityIndicator: false }, () => {});
    } else {
      const { errorType = '' } = paginatedData;
      this.callPaginatedList = false;
      if (errorType === 'api_failure') {
        this.callPaginatedList = true;
        this.setState({
          listPageApiLoadFailure: true,
          loadActivityIndicator: false,
        });
        return;
      }

      this.setState({ loadActivityIndicator: false });
      this.callPaginatedList = false;
    }
  };

  autoHitAndSetTimeStamp = () => {
    const { previousScreen } = this.props;

    if (
      previousScreen === SCREEN_CONSTANTS.CATEGORY_BROWSING &&
      this.paginatedPageNumber % CATEGORY_BROWSING_PAGINATION_API_LIMIT === 0
    ) {
      this.setState({ loadActivityIndicator: false });
    } else if (
      previousScreen !== SCREEN_CONSTANTS.CATEGORY_BROWSING &&
      this.paginatedPageNumber % PAGINATION_API_LIMIT === 0
    ) {
      this.setState({ loadActivityIndicator: false });
    } else {
      this.autoHitPaginatedAPI();
    }
  };

  apiPaginationCallback = () => {
    const {
      callbackPaginatedList,
      use_modified_product_list = false,
      hideOosProduct = false,
    } = this.props;

    if (this.callPaginatedList) {
      this.paginatedPageNumber += 1;
      callbackPaginatedList(
        this.paginatedListSlug,
        this.paginatedPageNumber,
        this.paginatedApiListCallback,
        this.appliedFilterCounter,
        use_modified_product_list,
        hideOosProduct,
      );
    }
  };

  autoHitPaginatedAPI = (slug = '', pageNo = '', resetCallbackData = false) => {
    if (Utility.isPresent(slug)) {
      if (resetCallbackData) {
        this.setState({
          callbackData: {},
        });
      }
      this.appliedFilterCounter += 1;
      this.paginatedListSlug = slug;
      this.paginatedPageNumber = 0;
      this.callPaginatedList = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
    this.callPaginatedList = true;
    this.timer = setTimeout(() => {
      this.apiPaginationCallback();
    }, 500);
  };

  autoHitFeedPageApi = () => {
    const { previousScreen, list } = this.props;
    setTimeout(() => {
      Utility.setPageLoadEnd(previousScreen, this.page);
      this.page += 1;
      if (
        (this.list?.is_dynamic ||
          this.list.display === 'multi-level-tabs' ||
          list?.display === 'multi-level-tabs' ||
          list?.is_dynamic) &&
        this.page >= 1
      ) {
        this.shouldLoadMore = false;
        this.setState({
          loadActivityIndicator: false,
        });
        return;
      }
      this.hitApiForList();
    });
  };

  refetchListDataForNoInternet = () => {
    const { previousScreen, loadMoreDetails } = this.props;

    InteractionManager.runAfterInteractions(() => {
      this.hitApiForList();
    });
    this.setState({
      loadActivityIndicator: true,
      listPageApiLoadFailure: false,
    });
  };

  refetchListData = () => {
    const { previousScreen, loadMoreDetails } = this.props;
    if (
      previousScreen === SCREEN_CONSTANTS.FEED ||
      previousScreen === SCREEN_CONSTANTS.STORE
    ) {
      InteractionManager.runAfterInteractions(() => {
        this.hitApiForList();
      });

      this.setState({
        loadActivityIndicator: true,
        listPageApiLoadFailure: false,
      });
      return;
    }
    if (Utility.isPresent(loadMoreDetails)) {
      loadMoreDetails();
    } else {
      this.callbackPaginatedValues();
    }
    this.setState({
      loadActivityIndicator: true,
      listPageApiLoadFailure: false,
    });
  };

  hitApiForListCallback = (success, homePageCallbackData = {}) => {
    const {
      previousScreen,
      setTitleVisibility = () => {},
      toggleDynamicListTitleVisibility,
    } = this.props;
    this.setState({
      isLoading: false,
      retryLoading: false,
    });
    const { objects = [] } = homePageCallbackData;
    if (success) {
      if (homePageCallbackData?.is_dynamic && objects.length > 0) {
        toggleDynamicListTitleVisibility(true);
      }
      this.setState({
        serverError: false,
        connectionError: false,
      });
      if (Utility.isPresent(homePageCallbackData) && objects.length > 0) {
        let data = {};
        if (this.page === 0) {
          const { stopTrace = () => {} } = this.props;
          stopTrace();
          data = homePageCallbackData;
        } else {
          this.state.callbackData.objects = [
            ...this.state.callbackData.objects,
            ...objects,
          ];
          data = this.state.callbackData;
        }
        const {
          list: listObjects,
          position: stickyPosition,
          slug,
        } = Utility.createFilterObjectIfExists(
          data?.objects,
          true,
          false,
          true,
        );
        this.slug = slug;
        data.objects = listObjects;
        const { filters, quickFilters } = FilterUtils.getFiltersFromList(data);
        this.setState(
          {
            callbackData: data,
            loadActivityIndicator: true,
            listPageApiLoadFailure: false,
            filters,
            quickFilters,
            showShimmer: false,
            stickyPosition,
          },
          this.autoHitFeedPageApi,
        );
        const { setHomePageInteractive } = this.props;
        if (setHomePageInteractive) {
          setHomePageInteractive(this.page);
        }
      } else {
        this.setState(
          { loadActivityIndicator: false }, //, callbackData: data },
        );
        this.shouldLoadMore = false;
      }
    } else {
      if (homePageCallbackData.errorType === 'api_failure') {
        Utility.clearPageLoadTimer();
        this.shouldLoadMore = true;
        this.setState({
          listPageApiLoadFailure: true,
          loadActivityIndicator: false,
          serverError: homePageCallbackData.status === 'server_error',
          connectionError: homePageCallbackData.status === 'connection_error',
        });
        return;
      }

      this.setState({ loadActivityIndicator: false });
    }
    if (previousScreen === 'feed') {
      try {
        Utility.feedLoadEnd = new Date();
        AnalyticsManager.logEvent(EventType.appLifeCycleEvents.IMPRESSION, {
          [EventParameterKey.APP_STATE]: 'feed_load_end',
          [EventParameterKey.TIME_STAMP]: Utility.feedLoadEnd,
          [EventParameterKey.DIFF_JS_LOAD]: Utility.getTimeDiffInMs(
            Utility.feedLoadEnd,
            Utility.appLoadJs,
          ),
          [EventParameterKey.DIFF_FEED_LOAD_START]: Utility.getTimeDiffInMs(
            Utility.feedLoadEnd,
            Utility.feedLoadStart,
          ),
          [EventParameterKey.PAGE_NO]: this.page,
        });
      } catch (e) {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            [EventParameterKey.SOURCE]: 'List.js: hitApiForListCallback',
          },
        );
      }
    }
  };

  onFiltersPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.props.hideOosProduct,
  ) => {
    const { navigation, onPressHideOosCheckBox } = this.props;
    const {
      callbackData,
      callbackData: { id = '', name = '', slug: listSlug = '' },
      selectedFilters,
      filters,
    } = this.state;
    const { list } = FilterUtils.getList(callbackData);
    this.setState({
      filtersList: list,
    });
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: listSlug,
        screenName: SCREEN_CONSTANTS.FEED,
        listData: callbackData,
        entityName: name,
        onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }
    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.FEED,
      name,
      hideOosProduct,
    );
    this.slug = slug;
    this.page = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    callbackData.objects = Utility.addShimmerInListObject(callbackData);
    this.filtersEnabled = this.slug.includes('&');
    this.setState({
      selectedFilters: groupedSelectedFilters,
      callbackData,
      filteredSlug: this.slug,
    });
    this.fetchFilteredList(this.slug, hideOosProduct);
  };

  fetchFilteredList = (slug, hideOosProduct = this.props.hideOosProduct) => {
    const { fetchList } = this.props;
    this.page = 0;
    // FIXME: Buggy Code, need to fix
    fetchList({ slug, hideOosProduct }, this.page, (success, data) => {
      const { callbackData } = this.state;
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        slug,
        data,
        callbackData,
      );
      this.setState({
        callbackData: { ...listData }, // spreading : this will create a new object ref and will re-render component. Otherwise we are just changing list of main object. Id of previous object will be same and component will not get re-render
      });
    });
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  resetFilters = () => {
    const { navigation } = this.props;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];
    this.fetchFilteredList(this.slug);
  };

  hitApiForList = () => {
    const { slug, fetchList, itemData, previousScreen, args } = this.props;
    if (Utility.isBlank(itemData) && !Utility.isBlank(slug)) {
      if (
        (this.props.list?.is_dynamic || this.list?.is_dynamic) &&
        this.state.retryLoading
      ) {
        this.page = 0;
        this.shouldLoadMore = true;
      }
      const data = { slug, refreshing: false, args };
      const currentPage = this.page;
      if (!this.shouldLoadMore) {
        // dont call api unnecessarily if end is reached
        return;
      }
      AnalyticsUtility.recordTime(
        {
          screen_name: previousScreen,
          page_number: this.page,
        },
        this.hitApiForListCallback,
        fetchList,
        data,
        currentPage,
      );
    } else {
      this.setState({
        loadActivityIndicator: false,
      });
    }
  };

  listCallBack = () => {};

  shouldShuffle = (display) => {
    return false;
  };

  setItems = (propsList, display) => {
    if (Utility.isBlank(this.list)) {
      // Freely shuffle for the first time
      this.shuffleItems(propsList, display);
    } else {
      // Use the previous order of shuffle otherwise the UI changes while the user is viewing the page.
      this.list = propsList;
      this.item = sortBy(propsList.objects, [
        (item) => {
          for (let i = 0; i < this.item.length; i += 1) {
            if (!Utility.isBlank(item) && !Utility.isBlank(item[i])) {
              if (this.item[i].id === item.id) {
                return i;
              }
            }
          }
          return this.item.length + 1;
        },
      ]);
    }
    if (display === 'vertical') {
      const { getListComponentHeight, getVerticalListOffsets } =
        getListRefHelper();
      this.componentOffsetList = getVerticalListOffsets(
        this.item,
        getListComponentHeight,
      );
    }
  };

  shuffleItems = (propsList, display) => {
    this.list = propsList;
    this.item = propsList.objects;
    if (this.shouldShuffle(display)) {
      this.item = shuffle(propsList.objects);
    }
  };

  onTimerFinish = () => {
    const { fetchList, itemData } = this.props;
    const listSlug = itemData?.slug;
    console.tron.log('onTimerFinish', listSlug);
    const data = { slug: listSlug, refreshing: true };
    this.page = 0;
    this.shouldLoadMore = true;
    this.setState({
      isLoading: true,
    });
    fetchList(data, 0, (success, callbackData) => {
      if (success) {
        this.setCallbackData(callbackData);
      }
    });
  };

  onRefresh = () => {
    const {
      fetchList,
      slug,
      itemData,
      disableRefresh = false,
      search,
      onRefreshSearchResult,
      onRefresh = undefined,
    } = this.props;
    if (disableRefresh) return;

    if (search && onRefreshSearchResult) {
      onRefreshSearchResult();
      return;
    }
    if (disableRefresh) return;
    if (onRefresh) {
      onRefresh();
      return;
    }
    let listSlug = slug;
    if (Utility.isBlank(listSlug)) {
      listSlug = itemData?.slug;
    }
    const data = { slug: listSlug, refreshing: true };
    this.page = 0;
    this.shouldLoadMore = true;
    this.setState({
      isLoading: true,
    });
    fetchList(data, 0, (success, callbackData) => {
      if (success) {
        this.setCallbackData(callbackData);
      }
    });
  };

  setCallbackData = (callbackData) => {
    this.setState({ callbackData, isLoading: false }, this.autoHitFeedPageApi);
  };

  endReached = () => {
    const {
      previousScreen,
      onEndReached = () => {},
      productAndBrandsOnlySwitchValue,
      handleLoadMore = () => {},
      itemData,
      filteredSlug,
    } = this.props;
    if (
      this.callPaginatedList &&
      (previousScreen === SCREEN_CONSTANTS.MORE_PAGE ||
        previousScreen === SCREEN_CONSTANTS.TAG_DETAIL ||
        previousScreen === SCREEN_CONSTANTS.CATEGORY)
    ) {
      this.paginatedListSlug = filteredSlug || itemData?.slug;
      this.autoHitPaginatedAPI();
    } else if (previousScreen === SCREEN_CONSTANTS.MORE_PAGE) {
      onEndReached();
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      if (
        typeof handleLoadMore !== 'function' ||
        !productAndBrandsOnlySwitchValue
      ) {
        return;
      }
      this.debounceHandleLodeMore();
    }
  };

  onPressReload = () => {
    this.setState(
      {
        retryLoading: true,
        callbackData: {},
      },
      this.hitApiForList,
    );
  };

  onRefreshDynamicList = () => {
    const { toggleDynamicListTitleVisibility } = this.props;
    const { callbackData: { objects = [], is_dynamic: isDynamic } = {} } =
      this.state;

    if (isDynamic && objects.length > 0) {
      toggleDynamicListTitleVisibility(true);
    }
  };

  shimmerForDynamicList = () => {
    const { layout } = this.props;
    const ShimmerLayout =
      layout === 'rail' ? SlimRailShimmer : GridScreenPlaceHolder;
    const dynamicListShimmerStyle =
      layout === 'rail' ? {} : styles.dynamicListShimmer;
    return (
      <View style={dynamicListShimmerStyle}>
        <ShimmerLayout columnCount={1} />
      </View>
    );
  };

  render() {
    const {
      navigation,
      theme,
      product = false,
      itemData = {},
      id,
      onPress,
      iconName = '',
      showCustomIcon = false,
      showColorComponent = false,
      updateCurrentlyPlayingIndex,
      currentlyPlayingIndex,
      previousScreen = '',
      hideActivityIndicator,
      paginate,
      slug = '',
      shimmerDisplay = '',
      extraEventParameters = {},
      showDynamicListShimmer,
    } = this.props;
    let { list = {} } = this.props;

    const {
      callbackData,
      loadActivityIndicator,
      listPageApiLoadFailure,
      timeStamp,
      serverError,
      isLoading,
      connectionError,
      retryLoading,
      stickyPosition: stateStickyPosition,
      selectedFilters: stateSelectedFilters,
      filteredSlug: stateFilteredSlug,
      filtersList: stateFiltersList,
    } = this.state;

    // todo: Chandranshu
    // It's assumed that if callbackData is present, it will be used even
    // if itemData is present
    if (Utility.isBlank(callbackData)) {
      if (Utility.isPresent(itemData)) {
        list = itemData;
      }
    } else {
      list = callbackData;
    }

    if (
      !connectionError &&
      !serverError &&
      (Utility.isBlank(list) || isLoading)
    ) {
      if (showDynamicListShimmer) {
        if (!isLoading) return null;
        return <this.shimmerForDynamicList />;
      }
      if (
        previousScreen === SCREEN_CONSTANTS.EMPTY_SEARCH ||
        previousScreen === SCREEN_CONSTANTS.SEARCH ||
        previousScreen === 'payoff' ||
        previousScreen === 'consumer_profile' ||
        previousScreen === SCREEN_CONSTANTS.INVITE_CENTRE ||
        previousScreen === SCREEN_CONSTANTS.NON_PERSONALISED_FEED
      ) {
        return null;
      }
      return (
        <ScreenPlaceholder
          previousScreen={previousScreen}
          shimmerDisplay={shimmerDisplay}
        />
      );
    }

    if (Utility.isBlank(list) && Utility.isPresent(itemData)) {
      list = itemData;
    }
    if (Utility.isBlank(list) && Utility.isPresent(callbackData)) {
      list = callbackData;
    }

    if (Utility.isBlank(list) && !connectionError && !serverError) {
      return null;
    }
    if (Utility.isPresent(callbackData) && paginate) {
      list = callbackData;
    }

    let listSlug = slug;
    if (Utility.isBlank(listSlug)) {
      listSlug = itemData?.slug;
    }

    let {
      display = list.display,
      showBanner = true,
      refreshing,
      showRating = false,
      displayCount,
      feed = false,
      search = false,
      onboarding = false,
      onCardClick = null,
      toggleCartVisibility,
      allVideosId = '', // to be used for all videos banner in artist page or product page
      index,
      searchQuery,
      elementItemCounts, // used for analytics events
      ignoreMinCount,
      listName,
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      listKey,
      toggleUnavailableProductsModal,
      checkVisible,
      updateSelectedItem,
      selectedItem,
      showFreeProductScreen,
      viewFreeProductScreen,
      paginationResult,
      isSearchResultScreen,
      productAndBrandsOnlySwitchValue,
      listContent,
      freeProductScreen,
      stickyPosition,
      selectedFilters,
      quickFiltersRef,
      onFiltersPress,
      getQuickFiltersListRef,
      setListRef,
      showFooterShimmer,
      loadMore,
      useInViewPort,
      onFoxyLiveRedirection,
      filtersEnabled,
      onPressResetButton,
      abc,
      paginateLastItem,
      totalItemsInList,
      setForceRefreshHomePage = () => {},
      filteredSlug,
      enableListingBackgroundColor,
      unLike,
      showEditButton,
      editableList,
      removeFromList,
      disableRefresh = false,
      onScrollToIndexFailed,
      foxyPoints,
      isRoutineSubscribed = false,
      setHeaderForCoupons = () => {},
      onItemPress = () => {},
      routineActivityId = '',
      showDynamicListTitle,
      force_apply_coupon,
      offer_id,
      brandId = '',
      categoryIds = [],
      listData = {},
      parentListsData = [],
      offersData = {},
      hideOosProduct,
      extraStyleParameter = {},
      sectionListHeader,
      sectionListFooter,
      isHeaderVisible,
      extraHeaderProps,
      avoidFlashList = false,
      hideHeader,
      showWebFooter = false,
      verticalComponentWidth,
      showPersonalisedDetails,
      listData: { display: listDisplay = EMPTY_STRING } = EMPTY_OBJECT,
      currentSku = EMPTY_STRING,
      fromProductPage = false,
      isDynamic = false,
      filtersList = {},
      tabListWidth = getScreenWidth(),
    } = this.props;
    this.displayCountPaginatedItems = displayCount;
    if (Utility.isBlank(displayCount)) {
      this.displayCountPaginatedItems = list.display_count; // more page sends display count in props
    }

    const { getListComponent } = getListRefHelper();
    const LayoutComponent =
      display === 'vertical' ? Vertical : getListComponent(display);
    this.setItems(list, display);
    if (LayoutComponent === undefined && !connectionError && !serverError) {
      return null;
    }

    // const addedProducts = showCustomIcon ? this.props.addedProducts : [];

    if (Utility.isPresent(callbackData) && paginateLastItem) {
      // TODO: This is a Hack for now to prevent it from crash.
      if (Utility.isPresent(itemData)) {
        itemData.display_count = itemData.objects.length;
      }
      const { objects: callbackDataObject = [] } = callbackData;
      this.displayCountPaginatedItems =
        callbackData?.objects?.length || DEFAULT_MAX_PAGINATED_ITEMS;
      callbackData.objects[callbackDataObject.length - 1].display_count =
        this.displayCountPaginatedItems;
    }

    if (Utility.isPresent(callbackData) && paginate) {
      this.displayCountPaginatedItems = callbackData.objects.length;
    }

    if (this.props.filtersEnabled && this.displayCountPaginatedItems === 0) {
      this.displayCountPaginatedItems =
        callbackData?.objects?.length || DEFAULT_MAX_PAGINATED_ITEMS;
    }

    if (camelCase(display) === LAYOUT.FEATURED_RAIL) {
      ignoreMinCount = true;
    }
    const listStickyPosition = stickyPosition || stateStickyPosition;
    const listSelectedFilters = selectedFilters || stateSelectedFilters;
    const listOnFilterPress = onFiltersPress ?? this.onFiltersPress;
    const listFiltersEnabled = filtersEnabled || this.filtersEnabled;
    const listOnPressResetButton = onPressResetButton || this.resetFilters;
    const listFilteredSlug = filteredSlug || stateFilteredSlug;
    const parentListData = callbackData || parentListsData;
    const listGetQuickFiltersListRef =
      getQuickFiltersListRef || this.getQuickFiltersListRef;

    //TODO - Temporary fix, Need better implementation to hide wallet for web
    // if (
    //   isWeb() &&
    //   this.list.display === 'fancy_rail' &&
    //   this.list.content === 'offer_prompt'
    // ) {
    //   return null;
    // }
    const { is_dynamic = false, objects = EMPTY_OBJECT } = this.list;
    const isDynamicList =
      is_dynamic &&
      objects?.length > 0 &&
      (listDisplay === 'vertical' || listDisplay === 'section');
    const filterListData = filtersList || stateFiltersList;
    return (
      <ErrorBoundary
        showServerError={serverError}
        onPressReload={this.onPressReload}
        connectionError={connectionError}
        loading={retryLoading}
        hideHeader
        slug={slug}
        isDynamic={isDynamic}
        screen_name={previousScreen}
      >
        {isDynamicList && (
          <Header
            item={this.list}
            navigation={navigation}
            layout={this.list.display}
            feed={feed}
            previousScreen={previousScreen}
            index={index}
            onTimerFinish={this.onTimerFinish}
            extraEventParameters={extraEventParameters}
            isVisible={isHeaderVisible}
            extraHeaderProps={extraHeaderProps}
            showMoreButton={false}
            hideHeader={hideHeader}
            is_dynamic={is_dynamic}
          />
        )}
        <LayoutComponent
          id={id}
          orientation={ORIENTATION.HORIZONTAL}
          display={camelCase(display)}
          displayCount={this.displayCountPaginatedItems}
          content={this.list.content}
          item={this.item}
          size={this.list.size}
          listData={this.list}
          parentListsData={parentListData}
          listSlug={listSlug}
          navigation={navigation}
          onRefresh={this.onRefresh}
          theme={theme}
          showBanner={showBanner}
          refreshing={refreshing}
          showRating={showRating}
          product={product}
          onPress={onPress}
          feed={feed}
          iconName={iconName}
          showCustomIcon={showCustomIcon}
          showColorComponent={showColorComponent}
          addedProducts={this.props.addedProducts}
          onboarding={onboarding}
          onCardClick={onCardClick}
          previousScreen={previousScreen}
          toggleCartVisibility={toggleCartVisibility}
          toggleUnavailableProductsModal={toggleUnavailableProductsModal}
          listIndex={index}
          index={index} // Deprected:  This index is buggy. It indicates the position of this LayoutComponent in the current list.
          // addedProducts={this.props.addedProducts}
          search={search}
          loadMore={loadMore || loadActivityIndicator}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          // isVisible={this.props.isVisible}
          endReached={this.endReached}
          allVideosId={allVideosId}
          updateCurrentlyPlayingIndex={updateCurrentlyPlayingIndex}
          currentlyPlayingIndex={currentlyPlayingIndex}
          // shouldLoadMore={this.shouldLoadMore}
          ignoreMinCount={ignoreMinCount}
          listName={listName}
          listContent={listContent}
          refreshOfferStrip={refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          listKey={listKey}
          showMoreButton={this.showMoreButton}
          checkVisible={checkVisible}
          updateSelectedItem={updateSelectedItem}
          selectedItem={selectedItem}
          showFreeProductScreen={showFreeProductScreen}
          viewFreeProductScreen={viewFreeProductScreen}
          paginationResult={paginationResult}
          isSearchResultScreen={isSearchResultScreen}
          productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
          toggleSwitch={this.props.toggleSwitch}
          freeProductScreen={freeProductScreen}
          useInViewPort={useInViewPort}
          onFoxyLiveRedirection={onFoxyLiveRedirection}
          feedLoadFailed={listPageApiLoadFailure}
          refetchFeed={this.refetchListData}
          randomTimeStamp={timeStamp}
          navigateToSearch={this.navigateToSearch}
          hideActivityIndicator={hideActivityIndicator}
          stickyPosition={listStickyPosition}
          selectedFilters={listSelectedFilters}
          quickFiltersRef={quickFiltersRef}
          onFiltersPress={listOnFilterPress}
          getQuickFiltersListRef={listGetQuickFiltersListRef}
          setListRef={setListRef}
          showFooterShimmer={showFooterShimmer}
          filtersEnabled={listFiltersEnabled}
          onPressResetButton={listOnPressResetButton}
          // abc={() => {}}
          verticalListHeader={this.props.verticalListHeader}
          VerticalListFooter={this.props.VerticalListFooter}
          setScrollYToNavigationHeader={this.props.setScrollYToNavigationHeader}
          paginateLastItem={this.paginatingLastItem || paginateLastItem}
          totalItemsInList={totalItemsInList}
          setForceRefreshHomePage={setForceRefreshHomePage}
          filteredSlug={listFilteredSlug}
          enableListingBackgroundColor
          onScrollToIndexFailed={onScrollToIndexFailed}
          unLike={unLike}
          showEditButton={showEditButton}
          editableList={editableList}
          removeFromList={removeFromList}
          disableRefresh={disableRefresh}
          componentOffsetList={this.componentOffsetList}
          foxyPoints={foxyPoints}
          isRoutineSubscribed={isRoutineSubscribed}
          setHeaderForCoupons={setHeaderForCoupons}
          onItemPress={onItemPress}
          routineActivityId={routineActivityId}
          showDynamicListTitle={showDynamicListTitle}
          force_apply_coupon={force_apply_coupon}
          offer_id={offer_id}
          brandId={brandId}
          categoryIds={categoryIds}
          extraEventParameters={extraEventParameters}
          offersData={offersData}
          hideOosProduct={hideOosProduct}
          avoidFlashList={avoidFlashList}
          extraStyleParameter={extraStyleParameter}
          sectionListHeader={sectionListHeader}
          sectionListFooter={sectionListFooter}
          verticalComponentWidth={verticalComponentWidth}
          showPersonalisedDetails={showPersonalisedDetails}
          currentSku={currentSku}
          fromProductPage={fromProductPage}
          showFooterInList={this.props.showFooterInList}
          filtersList={filterListData}
          parentListContent={listData?.content}
          parentListIndex={listData?.display_order}
          parentListDisplay={listDisplay}
          tabListWidth={tabListWidth}
        />
        {showWebFooter && <WebFooter />}
      </ErrorBoundary>
    );
  }
}

List.propTypes = {
  theme: PropTypes.string,
  updateCurrentlyPlayingIndex: PropTypes.func,
  currentlyPlayingIndex: PropTypes.number,
  ignoreMinCount: PropTypes.bool,
};

List.defaultProps = {
  updateCurrentlyPlayingIndex: () => {},
  currentlyPlayingIndex: null,
  ignoreMinCount: false,
};

const mapStateToProps = (state) => ({
  showDynamicListTitle: state.UserAccountInfo.showDynamicListTitle,
  cartPrompts: state.bag.cartPrompts,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { fetchList, callbackPaginatedList, toggleDynamicListTitleVisibility },
      dispatch,
    ),
  };
}

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(List),
);
