import { toLower, map, filter } from 'lodash';
import { URL, URLSearchParams } from '../libraries/ReactNativeUrlPolyfill';
import Config from '../libraries/ReactNativeConfig';
import { isIOS, isPresent, isBlank, isNative, isWeb } from './BooleanUtility';
import { restructuredPersonalizedElements } from './ArrayUtility';
import { PLATFORM_VERSION, URL as FOXY_URL } from '../config/Constants';
import { getStoreRef } from '../store/StoreUtility';
import { getGuestToken, setGuestToken } from './TokenUtility';
import { EnableShopifyDirect } from '../config/Constants';
import Utility from './Utility';

const getUserPersonalizationInfo = () => {
  const state = getStoreRef().getState();
  const {
    userPersonalizationInfo = {},
    UserAccountInfo: {
      device_information: {
        model: deviceModel = '',
        os_version: androidVersion = '',
      } = {},
      locationInfo: { city = '' } = {},
    } = {},
    todayDeals: { membership_id: edgePlanId = '' } = {},
    facialAnalysis: { my_attributes_values = [] } = {},
    UserAccountInfo,
  } = state;
  const myAttributesValue = UserAccountInfo?.facialAnalysis?.my_attributes_values;
  return {
    userPersonalizationInfo,
    deviceModel,
    osVersion: isIOS() ? PLATFORM_VERSION : androidVersion,
    edgePlanId,
    myAttributesValue,
    city,
  };
};

const filterUserAttributes = (
  userPersonalizationInfo,
  requiredAttributes,
  isPersonalizedUrl,
) => {
  if (isPersonalizedUrl) {
    return userPersonalizationInfo;
  }
  return Object.fromEntries(
    Object.entries(userPersonalizationInfo).filter(([key]) =>
      requiredAttributes.includes(key),
    ),
  );
};

const filterMyUserAttributes = (
  userAttribute,
  requiredAttributes,
  isPersonalizedUrl,
) => {
  if (isPersonalizedUrl) {
    return userAttribute;
  }
  const filteredAttributeData = userAttribute.filter((item) =>
    requiredAttributes.includes(item.user_attribute_id.toString()),
  );
  return filteredAttributeData;
};

const appendAttributeToUrl = (url, key, value) => {
  return `${url}&${key}=${value}`;
};

export const filteredRequiredAttribute = (
  url = '',
  requiredAttributes = [],
  isPersonalizedUrl = false,
) => {
  const {
    userPersonalizationInfo,
    deviceModel,
    osVersion,
    edgePlanId,
    myAttributesValue,
    city,
  } = getUserPersonalizationInfo();
  const filteredUserAttribute = filterUserAttributes(
    userPersonalizationInfo,
    requiredAttributes,
    isPersonalizedUrl,
  );

  const filterMyAttributesValues = filterMyUserAttributes(
    myAttributesValue,
    requiredAttributes,
    isPersonalizedUrl,
  );

  if (isPresent(filteredUserAttribute)) {
    const personalizedAttributesArray = restructuredPersonalizedElements(
      filteredUserAttribute,
    );
    url = appendAttributesWithHomePageUrl(
      url,
      personalizedAttributesArray,
      true,
    );
  }

  if (isPresent(filterMyAttributesValues)) {
    url = appendAttributesWithHomePageUrl(url, filterMyAttributesValues, true);
  }

  if (
    requiredAttributes?.includes(Config.OS_VERSION_PERSONALIZED_ATTRIBUTE_ID) ||
    isPersonalizedUrl
  ) {
    url = appendAttributeToUrl(url, 'os_version', osVersion);
  }

  if (
    requiredAttributes?.includes(Config.APP_VERSION_PERSONALIZED_ATTRIBUTE_ID) ||
    isPersonalizedUrl
  ) {
    url = appendAttributeToUrl(url, 'app_version', Config.VERSION_NAME);
  }

  if (
    (requiredAttributes?.includes(
      Config.EDGE_MEMBERSHIP_PERSONALIZED_ATTRIBUTE_ID,
    ) ||
      isPersonalizedUrl) &&
    isPresent(edgePlanId)
  ) {
    url = appendAttributeToUrl(url, 'edge_plan_id', edgePlanId);
  }

  if (
    (requiredAttributes?.includes(Config.LOCATION_PERSONALIZED_ATTRIBUTE_ID) ||
      isPersonalizedUrl) &&
    isPresent(city)
  ) {
    url = appendAttributeToUrl(url, 'city_name', toLower(city));
  }

  return url;
};

export const appendAttributesWithHomePageUrl = (
  url,
  attributesList = [],
  myAttributes,
) => {
  //TODO:COnvert this two loops into one which will check the object & key to extract on bases on condition
  if (myAttributes) {
    attributesList.forEach((userAttribute) => {
      if (isPresent(userAttribute.new_values)) {
        userAttribute.new_values.forEach((attributeValue) => {
          url = `${url}&user_attributes[${userAttribute.user_attribute_id}][]=${attributeValue}`;
        });
      }
    });
  } else {
    attributesList.forEach((userAttribute) => {
      if (isPresent(userAttribute.values)) {
        userAttribute.values.forEach((attributeValue) => {
          if (isBlank(attributeValue?.id)) {
            return;
          }
          url = `${url}&user_attributes[${userAttribute.id}][]=${attributeValue?.id}`;
        });
      }
    });
  }
  return url;
};

export const extractQueryParamsFromDeepLink = (url = '') => {
  if (url.indexOf('?') === -1) return { url };
  const [origin, paramString] = url.split('?');
  const urlObj = new URL(`${Config.HTTPS_WWW_WEB_URL}?${paramString}`);
  const searchParams = new URLSearchParams(urlObj.search);

  const queryParams = {};

  for (const [key, value] of searchParams.entries()) {
    if (isWhitelistedExtraEventParams(key)) {
      queryParams[key] = value;
    }
  }

  if (isBlank(queryParams['utm_source'])) {
    if (isPresent(queryParams['gs'])) {
      queryParams['utm_source'] = 'google_shopping';
    } else if (isPresent(queryParams['gclid'])) {
      queryParams['utm_source'] = 'gclid';
    }
  }

  return { ...queryParams, url: origin };
};


export const extractRequireAttributesFromSlug = (slug) => {
  // Construct the full URL
  let url = `${FOXY_URL.ROUTINE_URL}/${slug}`;
  const urlObj = new URL(url);

  // Extract the search parameters
  const params = new URLSearchParams(urlObj.search);

  // Convert the search parameters to an array of key-value pairs
  const entries = Array.from(params.entries());

  // Use lodash to filter and map the values of req_attrs[]
  const reqAttributes = map(
    filter(entries, ([key]) => key === 'req_attrs[]'),
    ([, value]) => value,
  );

  // Remove req_attrs from search parameters
  filter(entries, ([key]) => key !== 'req_attrs[]').forEach(([key, value]) => {
    params.set(key, value);
  });

  // Remove all req_attrs[] parameters from params
  params.delete('req_attrs[]');

  // Rebuild the slug without the req_attrs
  const paramsString = params.toString();
  let updatedSlug = paramsString
    ? `${urlObj.pathname}?${paramsString}`
    : urlObj.pathname;
  updatedSlug = truncateBeforePersonalized(updatedSlug);

  return { reqAttributes, updatedSlug };
};

const truncateBeforePersonalized = (url) => {
  const keyword = "personalized";
  const index = url.indexOf(keyword);

  if (index === -1) {
    // If 'personalized' is not found in the URL, return the original URL
    return url;
  }
  // Return the substring starting from 'personalized'
  return url.substring(index);
};



const isWhitelistedExtraEventParams = (paramKey = '') => keysToExclude.test(paramKey);

const keysToExclude = /^(?!.*(moeFeatures|moe_cid_attr|gcm_webUrl|gcm_image_url)).*$/;

export const removeEmptyObjects = (array) => {
  if (isBlank(array)) return;
  return array.filter((item) => {
    // Check if all values in the object are either null, undefined, or empty strings
    return !Object.values(item).every((value) => value === null || value === undefined || value === '');
  });
};

export function generateRequestHeader(state) {
  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = state.UserAccountInfo;

  if (!getGuestToken() || getGuestToken() !== guestAuthToken) {
    setGuestToken(guestAuthToken);
  }

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (isNative()) {
    headers = {
      ...headers,
      'x-build-version': Utility.getAppVersion(),
      'x-app-platform': Platform.OS,
    };
  }

  if (EnableShopifyDirect) {
    headers['x-build-type'] = 'shopify_direct';
  }

  if (isPresent(authToken)) {
    headers['x-auth-token'] = authToken;
  }

  if (isPresent(guestAuthToken)) {
    headers['x-guest-token'] = guestAuthToken;
  }

  if (isWeb()) {
    headers.Platform = 'web';
  }
  return headers;
}