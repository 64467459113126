// Dependencies
import React, { Component } from 'react';
import { View, PanResponder, Animated, Linking } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memoize } from 'lodash';
import { cardHeight, styles as FeatureStyles } from './styles';
import ComparePriceBanner from '../Product/ComparePriceBanner';
import {
  MAX_LINES,
  PERMISSION_STORE,
  PERMISSIONS,
  LOGIN_MODAL_MESSAGES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';

import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  dismissFeatureCard,
  getFoxyLiveArtists,
} from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import SelfieCard from '../shared/SelfieCard';
import FollowDynamicCard from './FollowDynamicCard';
import OfferFeatureCard from './OfferFeatureCard';
import { removeFollowCard } from '../../actions/FeatureCardActions';
import {
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsManager,
} from '../../analytics';
import CollapsedIngredientsCards from './CollapsedIngredientsCard';
import LargeBanner from './LargeBanner';
import BigBanner from './BigBanner';
import { Greetings } from '../payoff';
import CartAndOffers from '../../containers/cart/CartAndOffers';
import LoginFeatureCard from '../shared/LoginFeatureCard';
import ReviewCard from '../../containers/feed/ReviewCard';
import Masonry from './Masonry';
import ReferralCard from './ReferralCard';
import RemoteConfig from '../../utils/RemoteConfig';
import NavigationService from '../../navigator/NavigationService';
import RoutineFeatureCard from '../Routines/RoutineDetails/RoutineFeatureCard';
import SelfieAnalysisCard from '../shared/SelfieAnalysisCard';
import SelfieUtilities from '../../utils/SelfieUtilities';
import TallCard from '../feature/TallCard';
import EdgeToEdgeBanner from './EdgeToEdgeBanner';
import LoyaltyPlans from '../../containers/FoxyEdge/LoyaltyPlansWithBenefits';
import SubscribeToCalendarBanner from '../../containers/FoxyEdge/SubscribeToCalendarBanner';
import images from '../../theme/Images';
import FoxyEdgeDiscoveryCard from '../../containers/FoxyEdge/FoxyEdgeDiscoveryCard';
import EdgeFeatureCard from '../../containers/FoxyEdge/EdgeFeatureCard';
import PostCard from '../../containers/FoxyEdge/PostCard';
import { fetchRoutineListForUrl } from '../../actions/RoutineActions';
import RoutineDiscoveryCard from '../Routines/RoutineDiscoveryCard';
import ReferralMiniCard from './ReferralMiniCard';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import CustomerID from './CustomerID';
import { getNavigationSource } from '../../utils/PerfUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import TaskPromptCard from './TaskPromptCard';
import GetDirectionDetails from './GetDirectionDetails';
import MilestoneGoldenTicket from './MilestoneGoldenTicket';
import MilestoneProgress from './MilestoneProgress';
import SelectGenderCard from '../homepage/SelectGenderCard';
import MilestoneRewardsCard from './MilestoneRewardsCard';

const SELFIE_CARD_HEIGHT = 80;
const FeatureCardHeightConfig = {
  selfie: SELFIE_CARD_HEIGHT + FeatureStyles.largeBanner.marginVertical * 2,
  foxy_live_call_prompt: 360,
  referral: cardHeight + FeatureStyles.largeBanner.marginVertical * 2,
  large_banner: cardHeight,
  'offer-banner-with-rail':
    cardHeight + FeatureStyles.largeBanner.marginVertical * 2,
  overflow: 130,
  masonry: 408.8,
  tall_card: 358,
  referral_mini: 112,
};

// FIXME: RENDER functino is filled with junk conditions which should be fixed using a hash of components in a config.
class FeatureCard extends Component {
  static getComponentHeight(item) {
    const { display = '', type = '', offer_type = '' } = item;
    if (type === 'offer' && offer_type === 'offer_coupon') {
      return FeatureCardHeightConfig.large_banner;
    }
    if (display === 'rail') {
      return FeatureCardHeightConfig[item?.objects[0]?.display] || 0;
    }
    if (display === 'edge_to_edge_banner') {
      return EdgeToEdgeBanner.getComponentHeight(item);
    }
    return FeatureCardHeightConfig[display] || 0;
  }

  constructor(props) {
    super(props);
    this.state = {
      dismissed: false,
      routinesData: {},
    };
    this.currentPermissionState = '';
    this.translateX = new Animated.Value(0);
    this.navigationSource = getNavigationSource(this, this.props);
  }

  UNSAFE_componentWillMount() {
    const screenWidth = Utility.getScreenWidth();

    this.panResponder = PanResponder.create({
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderMove: Animated.event([null, { dx: this.translateX }]),
      onPanResponderRelease: (e, { vx, dx }) => {
        if (Math.abs(vx) >= 0.2 || Math.abs(dx) >= 0.2 * screenWidth) {
          Animated.timing(this.translateX, {
            toValue: dx > 0 ? screenWidth : -screenWidth,
            duration: 200,
          }).start(this.onDismissed);
        } else {
          Animated.spring(this.translateX, {
            toValue: 0,
            bounciness: 10,
          }).start();
        }
      },
    });
  }

  componentWillUnmount() {
    this.panResponder = null;
  }

  onDismissed = () => {
    const { itemData, dismissFeatureCard, featureCards } = this.props;
    if (itemData.display && itemData.display === 'selfie') {
      // FIXME:  Right now this logic is only for selfie and will be applicable on one card only. In future we shall convert it for multiple cards
      const currentFeatureCard = featureCards.filter(
        (element) => element.id === itemData.id,
      );
      const cards = [];
      if (currentFeatureCard.length < 1) {
        const card = { id: itemData.id, dismissCount: 1 };
        cards.push(card);
        dismissFeatureCard(cards);
      } else {
        currentFeatureCard[0].dismissCount += 1;
        dismissFeatureCard(currentFeatureCard);
      }
    }

    this.setState({ dismissed: true });
  };

  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: route,
      navigationSource: this.navigationSource,
      params: {
        destination: slug,
        slug,
        extra,
      },
    });
  };

  pushToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: {
        destination: slug,
        slug,
        extra,
      },
    });
  };

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate(this.navigationSource, 'TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.EDGE_SELFIE_CARD,
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(
        SCREEN_CONSTANTS.FEED,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  onFeatureCardPress = () => {
    const {
      navigation,
      itemData: { destination, display, external_link } = {},
      authToken,
      setPhoneNumberModalInfo,
    } = this.props;
    const url = Utility.isPresent(external_link) ? external_link : destination;

    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      this.pushToScreen,
    );
  };

  onActionPressed = (action) => {
    const {
      navigation,
      itemData,
      itemData: { destination, display } = {},
      itemIndex,
      listIndex,
      listName,
      id: listId,
      listType,
      listContent,
      listSlug,
      parentListsData,
      authToken,
      setPhoneNumberModalInfo,
      previousScreen = '',
      membership_cohort,
    } = this.props;

    if (Utility.isPresent(itemData)) {
      const { id, type, slug = '', title, name } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        title || name,
        itemIndex,
        listId,
        listType,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
        AnalyticsUtility.getParentsList(parentListsData),
      );
    }

    if (display === 'onboarding_card') {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.FEATURE_ACTION_CLICK,
        {
          [EventParameterKey.TYPE]: EventParameterValue.CARD_TITLE.LOGIN,
          [EventParameterKey.SCREEN_NAME]: EventParameterValue.SCREEN_NAME.HOME,
        },
      );
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('VIEW_PROFILE'));
      NavigationService.renderOnboarding();
      return;
    }
    if (display === 'selfie') {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.FEATURE_ACTION_CLICK,
        {
          [EventParameterKey.TYPE]: EventParameterValue.CARD_TITLE.SELFIE,
          [EventParameterKey.SCREEN_NAME]: previousScreen,
        },
      );

      if (action === 'review_selfie') {
        navigation.push('AttributeSelector');
      } else if (action === 'activate_membership') {
        this.navigateToLoyaltyPlans();
      } else if (action === 'selfie_reviewed') {
        this.navigateToPayOffPage();
      } else {
        this.navigateToSelfieScreen();
      }
    } else if (display === 'selfie_review') {
      SelfieUtilities.navigateToSelfieWithAnalytics('ROUTINES', navigation);
    } else {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        destination,
        this.navigateToScreen,
      );
    }
  };

  navigateToLoyaltyPlans = () => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'TheEdge',
      navigationSource: this.navigationSource,
      params: {
        source: EventParameterValue.SOURCE.EDGE_SELFIE_CARD,
      },
    });
  };

  navigateToPayOffPage = () => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, 'Payoff', {
      source: EventParameterValue.SOURCE.EDGE_SELFIE_CARD,
    });
  };

  render() {
    if (this.state.dismissed) {
      return null;
    }
    const {
      itemData,
      image_url,
      selfie_image_url,
      featureCards = [],
      onPress = () => {},
      has_selfie,
      followFeatureCard,
      listData,
      listData: {
        id: listId = '',
        name: listName = '',
        slug: listSlug = '',
        content: listContent = '',
        display: listDisplay = '',
      } = {},
      listIndex,
      parentListsData,
      checkVisible,
      toggleCartVisibility,
      showFreeProductScreen,
      viewFreeProductScreen,
      onFoxyLiveRedirection,
      authToken,
      previousScreen,
      index,
      isUserSubscribed = false,
      navigation,
      isPair = false,
    } = this.props;

    if (Utility.isBlank(itemData) || Utility.isBlank(itemData.display)) {
      return null;
    }
    if (itemData.display === 'task_prompt') {
      return <TaskPromptCard itemData={itemData} previousScreen={previousScreen} />;
    }
    if (itemData.display === 'location') {
      return <GetDirectionDetails itemData={itemData} previousScreen={previousScreen} />;
    }
    if (itemData.display === 'customer_id') {
      return <CustomerID itemData={itemData} />;
    }

    if (itemData.display === 'illustration') {
      return (
        <RoutineFeatureCard
          cardDetails={itemData}
          isUserSubscribed={isUserSubscribed}
          onPress={onPress}
        />
      );
    }

    if (itemData.display === 'routines_discovery') {
      const { routinesData = {} } = this.state;
      return (
        <RoutineDiscoveryCard
          itemData={routinesData}
          navigation={navigation}
          index={index}
          parentListsData={parentListsData}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'onboarding_card' && Utility.isBlank(authToken)) {
      return (
        <LoginFeatureCard
          itemData={itemData}
          onCardPress={this.onActionPressed}
          index={index}
          listId={listId}
          parentListsData={parentListsData}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }
    if (
      itemData.display === 'onboarding_card' &&
      Utility.isPresent(authToken)
    ) {
      return null;
    } else if (itemData.display === 'onboarding_card') {
      return null;
    }

    if (itemData.display === 'compare_prices') {
      return <ComparePriceBanner onPress={onPress} itemData={itemData} />;
    }

    if (itemData.display === 'collapsed_card') {
      return (
        <CollapsedIngredientsCards
          itemData={itemData}
          onActionPressed={this.onFeatureCardPress}
          index={index}
          listId={listId}
          parentListsData={parentListsData}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'large_banner') {
      return (
        <LargeBanner
          itemData={itemData}
          previousScreen={previousScreen}
          {...this.props}
          listId={listId}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'postcard') {
      return (
        <PostCard
          itemData={itemData}
          previousScreen={previousScreen}
          index={index}
          listId={listId}
          parentListsData={parentListsData}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'referral') {
      return (
        <ReferralCard
          itemData={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'referral_mini') {
      return (
        <ReferralMiniCard
          itemData={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'mini_action') {
      return (
        <SubscribeToCalendarBanner
          item={itemData}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (
      itemData.type === 'overflow' ||
      (itemData.type === 'offer' && itemData.offer_type === 'offer_coupon')
    ) {
      return (
        <BigBanner
          itemData={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
          isPair={isPair}
        />
      );
    }

    if (itemData.display === 'cart_items_with_offers') {
      return (
        <CartAndOffers
          itemData={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listDisplay={listDisplay}
          listSlug={listSlug}
          listContent={listContent}
        />
      );
    }

    if (itemData.display === 'review_reminder') {
      return <ReviewCard itemData={itemData} />;
    }

    if (
      itemData.display === 'timer_interstitial_small' ||
      itemData.display === 'timer_interstitial_big'
    ) {
      return (
        <OfferFeatureCard
          itemData={itemData}
          parentListsData={parentListsData}
          index={index}
          listData={listData}
          listIndex={listIndex}
          previousScreen={previousScreen}
          checkVisible={checkVisible}
          toggleCartVisibility={toggleCartVisibility}
          showFreeProductScreen={showFreeProductScreen}
          viewFreeProductScreen={viewFreeProductScreen}
        />
      );
    }

    if (itemData.display === 'masonry') {
      return (
        <Masonry
          {...this.props}
          index={index}
          parentListsData={parentListsData}
          listIndex={listIndex}
          listName={listName}
          listDisplay={listDisplay}
          listContent={listContent}
          listSlug={listSlug}
        />
      );
    }
    if (itemData.display === 'gender_selection') {
      return <SelectGenderCard {...this.props} />;
    }
    if (itemData.display === 'tall_card') {
      return (
        <TallCard
          {...this.props}
          listDisplay={listDisplay}
          listSlug={listSlug}
        />
      );
    }
    if (itemData.display === 'loyalty_plans') {
      return (
        <LoyaltyPlans
          {...this.props}
          listDisplay={listDisplay}
          listSlug={listSlug}
        />
      );
    }

    if (itemData.display === 'greeting') {
      const { selfie_image_url } = this.props;
      return (
        <Greetings itemData={itemData} selfie_image_url={selfie_image_url} />
      );
    }

    if (itemData.display === 'edge_to_edge_banner') {
      return (
        <EdgeToEdgeBanner
          itemData={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          listData={listData}
          index={index}
          listIndex={listIndex}
          navigation={this.props.navigation}
          previousScreen={previousScreen}
        />
      );
    }
    if (itemData.display === 'coupon') {
      return (
        <FoxyEdgeDiscoveryCard
          itemData={itemData}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listSlug={listSlug}
          listContent={listContent}
          listDisplay={listDisplay}
          onPress={onPress}
          navigation={this.props.navigation}
          previousScreen={previousScreen}
        />
      );
    }

    if (itemData?.display === 'overflow') {
      return (
        <EdgeFeatureCard
          cardDetails={itemData}
          previousScreen={previousScreen}
          parentListsData={parentListsData}
          index={index}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listSlug={listSlug}
          listContent={listContent}
          listDisplay={listDisplay}
          listData={listData}
          key={itemData.id}
        />
      );
    }

    if (itemData?.display === 'milestone_golden_ticket') {
      return (
        <MilestoneGoldenTicket
          previousScreen={previousScreen}
          itemData={itemData}
        />
      );
    }

    if (itemData?.display === 'milestone_progress') {
      return (
        <MilestoneProgress
          previousScreen={previousScreen}
          itemData={itemData}
        />
      );
    }

    if (itemData?.display === 'milestone_rewards') {
      return (
        <MilestoneRewardsCard
          previousScreen={previousScreen}
          itemData={itemData}
        />
      );
    }


    if (
      itemData.display === 'follow' &&
      !followFeatureCard.dismissedOrFollowedCardIds.includes(itemData.id)
    ) {
      if (Utility.currentActiveFollowCard.id === undefined) {
        Utility.currentActiveFollowCard = {
          id: itemData.id,
        };
      }
      return (
        <FollowDynamicCard
          itemData={itemData}
          parentListsData={parentListsData}
          listData={listData}
          removeFollowCard={this.props.removeFollowCard}
        />
      );
    }
    if (itemData.display === 'follow') {
      return null;
    }

    if (itemData?.display === 'selfie_review') {
      const selfieExists = Utility.isPresent(selfie_image_url) || has_selfie;
      const { facialProperties } = this.props;
      const backgroundStyle = { backgroundColor: colors.background };
      if (
        !selfieExists ||
        !SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)
      ) {
        return (
          <View style={backgroundStyle}>
            <SelfieAnalysisCard onCardPress={this.onActionPressed} />
          </View>
        );
      }
      return null;
    }

    const viewStyle = { transform: [{ translateX: this.translateX }] };
    const maxDismissCount = itemData.max_dismissal_count || 0;
    const getCurrentCardDismissCount =
      featureCards.length > 0 ? featureCards[0].dismissCount : -1;
    // if (
    //   (itemData.display === 'selfie' &&
    //     (Utility.isPresent(selfie_image_url) || has_selfie)) ||
    //   (itemData.display === 'selfie' &&
    //     getCurrentCardDismissCount >= maxDismissCount)
    // ) {
    //   return null;
    // }

    let title = '';
    let subtitle = '';
    let buttonText = 'Take selfie';

    const selfieCardConfig = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.selfie_card),
    );
    let buttonAction = 'take_selfie';
    const { facialProperties, membership_cohort } = this.props;

    if (Utility.isPresent(selfie_image_url)) {
      if (!SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)) {
        // Selfie is present but not reviewed
        const {
          title: reviewTitle,
          subtitle: reviewSubtitle,
          buttonText: reviewButtonText,
          buttonAction: reviewButtonAction,
        } = selfieCardConfig['review_selfie'];
        title = reviewTitle;
        subtitle = reviewSubtitle;
        buttonText = reviewButtonText;
        buttonAction = reviewButtonAction;
      }
    } else if (membership_cohort === 'member') {
      // Selfie not present, membership is 'member'
      const {
        title: memberTitle,
        subtitle: memberSubtitle,
        buttonText: memberButtonText,
      } = selfieCardConfig['selfieNotPresentForMember'];
      title = memberTitle;
      subtitle = memberSubtitle;
      buttonText = memberButtonText;
    } else {
      // Selfie not present, membership is inactive
      const {
        title: inactiveTitle,
        subtitle: inactiveSubtitle,
        buttonText: inactiveButtonText,
      } = selfieCardConfig['selfieNotPresentAndMembershipInactive'];
      title = inactiveTitle;
      subtitle = inactiveSubtitle;
      buttonText = inactiveButtonText;
    }

    if (
      Utility.isPresent(selfie_image_url) &&
      SelfieUtilities.detectIfSelfieIsReviewed(facialProperties) &&
      membership_cohort !== 'member'
    ) {
      // Selfie is present, membership is inactive
      const {
        title: presentInactiveTitle,
        subtitle: presentInactiveSubtitle,
        buttonText: presentInactiveButtonText,
        buttonAction: presentInactiveButtonAction,
      } = selfieCardConfig['selfiePresentAndMembershipInactive'];
      title = presentInactiveTitle;
      subtitle = presentInactiveSubtitle;
      buttonText = presentInactiveButtonText;
      buttonAction = presentInactiveButtonAction;
    }

    if (
      Utility.isPresent(selfie_image_url) &&
      membership_cohort === 'member' &&
      SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)
    ) {
      return null;
    }

    return (
      <Animated.View style={viewStyle}>
        {itemData.display === 'selfie' && (
          <SelfieCard
            onCardPress={memoize(
              () => this.onActionPressed(buttonAction),
              () => [buttonAction],
            )}
            onCancelPress={this.onDismissed}
            background_color={itemData.background_color}
            image_url={images.edge.offer}
            webpImageUrl={images.edge.offer}
            title={title}
            body={subtitle}
            isDismissable={itemData.dismissable}
            width={Utility.getDynamicWidth(1, 0)}
            height={90}
            showProgressIndicator
            ctaText={buttonText}
          />
        )}
      </Animated.View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
    featureCards: state.UserAccountInfo.featureCards,
    has_selfie: state.UserAccountInfo.has_selfie,
    authToken: state.UserAccountInfo.authToken,
    followFeatureCard: state.followFeatureCard,
    facialProperties: state.UserAccountInfo?.facialAnalysis?.facialProperties,
    membership_cohort: state.todayDeals.membership_cohort,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      dismissFeatureCard,
      setPhoneNumberModalInfo,
      removeFollowCard,
      getFoxyLiveArtists,
      fetchRoutineListForUrl,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(FeatureCard),
);
