import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { View, Text, Image, StyleSheet } from 'react-native';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { getCartItems } from '../../actions/ActionTypes';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';
//import CartItems from '../../components/cart/CartItems';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import Carousel from 'react-native-looped-carousel';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import { playlistStyle } from '../../components/media/Style';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { getPriceText } from '../../utils/NumberUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { isBlank, isPresent, isDesktop } from '../../utils/BooleanUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FastImageView from '../../components/FastImageView';
import images from '../../theme/Images';
import { TouchableOpacity } from 'react-native-gesture-handler';

class CartAndOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      refreshing: false,
    };

    this.modalMessage = null;
    const { route } = props;
    this.source = route.params?.source ?? '';
    this.allowWithoutAcceptingContactless = null;
    const { itemData, customStyles } = this.props;
    const marginHorizontal =
      itemData?.type === 'feature' &&
      itemData?.display === 'cart_items_with_offers'
        ? 12
        : 0;
    this.containerStyle = [
      styles.container,
      customStyles,
      { marginHorizontal },
    ];
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    this.modalMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.switch_contact_less_modal_message,
    );

    this.allowWithoutAcceptingContactless = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.allow_without_contactless,
    );
    const { setCurrentPaymentFlow } = this.props;
    setCurrentPaymentFlow(true);
    this.getCart();
    Utility.furtherAction = {
      action: null,
      params: [],
      phone_number: '',
      isActionAsync: false,
    };
  }

  getCart = () => {
    const { getCartItems } = this.props;

    getCartItems((success) => {
      this.setState({
        refreshing: false,
      });
      if (!success) {
        this.showToast('Something went wrong', 500);
      } else {
        const {
          cartItems = [],
          cartPricing = {},
          address = [],
          authToken,
          cartPrompts,
        } = this.props;
        const meta = {
          cartItems,
          cartPricing,
          address,
          authToken,
        };
        try {
          // AnalyticsUtilty.fireCartViewEvent(meta);
          // AnalyticsUtilty.firePageLoadEvent(SCREEN_CONSTANTS.CART);
          // AnalyticsUtilty.fireCartPromptVisible(cartPrompts);
        } catch (e) {}
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  renderLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  getComponent = ({ coupon_code, message } = {}) => {
    
    return (
      <View
        key={`${coupon_code}${message}`}
        style={{
          backgroundColor: '#DFF5EA',
          paddingHorizontal: 16,
          paddingVertical: 8,
          width: Utility.getScreenWidth(),
          alignItems: 'center',
          flex: 1,
          flexDirection: 'row',
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: 'Roboto-Medium',
            color: '#00B55D',
            alignSelf: 'flex-start',
          }}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {`${coupon_code} - `}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontFamily: 'Roboto-Medium',
            color: '#00B55D',
            alignSelf: 'flex-start',
          }}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {message || 'a'}
        </Text>
      </View>
    );
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.getCart();
  };

  product = (props) => {
    const {
      product,
      index,
      totalProducts,
      product: {
        loyalty_plan: { card_image: cardImage = '' } = {},
        routine: {
          data: { attributes: { imageUrl: routineImageUrl = '' } = {} } = {},
        } = {},
      } = {},
    } = props;
    if (Utility.isBlank(product)) {
      return null;
    }
    const imageUrls = [
      product?.offer?.card_image,
      product?.product?.image_url,
      cardImage,
      routineImageUrl,
    ];
    const imageUrl = imageUrls.find((url) => url && isPresent(url)) || '';
 
    return (
      <Image
        source={{
          uri: getMinifiedImage(
            imageUrl,
            styles.imageContainer.width,
            styles.imageContainer.height,
          ),
        }}
        style={styles.imageContainer}
      />
    );
  };

  cartProducts = ({ cartItems }) => {
    const { navigation } = this.props;

    if (cartItems.length > 1) {
      return (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 16,
            paddingHorizontal: 12,
            alignItems: 'center',
          }}
        >
          {cartItems.map((product, index) => {
            if (index === 6) {
              return (
                <View
                  key={product.id}
                  style={{
                    height: 36,
                    marginRight: 8,
                    width: 36,
                    borderWidth: 1,
                    borderRadius: 18,
                    borderColor: colors.borderDark,
                    resizeMode: 'cover',
                    overflow: 'hidden',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.white,
                  }}
                >
                  <Text
                    style={[
                      playlistStyle.productsUsedText,
                      { color: colors.foxyBlack },
                    ]}
                  >{`+${cartItems?.length - 6}`}</Text>
                </View>
              );
            }
            if (index < 6) {
              return (
                <this.product
                  key={product.id}
                  product={product}
                  index={index}
                  totalProducts={cartItems.length}
                  id={product.id}
                  products={cartItems}
                />
              );
            }
            return null;
          })}
        </View>
      );
    }
    return null;
    //return (
    //  <CartItems
    //    cartItems={cartItems.slice(0, 1)}
    //    navigation={navigation}
    //    showToast={this.showToast}
    //    hideAddToCart
    //  />
    //);
  };

  onCheckoutPress = () => {
    const {
      hideModal = () => {},
      navigation,
      previousScreen = '',
      itemData: { id = '', title = '', type = '' },
      index = 0,
      listIndex = 0,
      listName = '',
      listId = '',
      listDisplay = '',
      listContent = '',
      listSlug = '',
    } = this.props;
    hideModal();
    setTimeout(() => {
      navigation.push(
        this.navigationSource, 'Cart', {
          source: 'cart_feature_card',
        },
      );
    }, 0);

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      title,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      '',
      listSlug,
    );
  };

  render() {
    const {
      cartItems,
      cartPricing = {},
      cartPrompts,
      navigation,
      itemData,
      customStyles,
      fromModal,
      hideModal = () => {},
      showCrossBtn = false,
    } = this.props;

    const { title = '', body = '' } = itemData;

    if (cartItems.length === 0) {
      return null;
    }

    if (cartItems.length === 1 && cartItems[0]?.is_free) {
      return null;
    }

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={this.containerStyle}
        onPress={this.onCheckoutPress}
      >
        {/* <StaticNavigationHeader title='Bag' /> */}

        <View style={styles.headerContainer}>
          <Text
            style={styles.header}
            ellipsizeMode='tail'
            numberOfLines={1}
          >
            {title || ''}
          </Text>
          {showCrossBtn && (<TouchableOpacity onPress={hideModal}>
            <FastImageView
              source={images.black_cross}
              style={styles.crossBtn}
            />
          </TouchableOpacity>)}
        </View>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Regular',
            color: '#979BAA',
            marginBottom: 12,
            paddingHorizontal: 12,
          }}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {body || ''}
        </Text>

        <this.cartProducts cartItems={cartItems} />

        <Carousel
          horizontal
          bullets={false}
          style={{
            minHeight: 32,
            width: Utility.getScreenWidth(),
          }}
          autoplay
          isLooped
        >
          {_.filter(cartPrompts, (offer) => {
            return (
              offer?.status === 'redeemed' ||
              offer?.status === 'partially_redeemed'
            );
          }).map((cartPrompt) => this.getComponent(cartPrompt))}
        </Carousel>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 16,
            paddingHorizontal: 12,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Bold',
              color: colors.foxyBlack,
            }}
          >{`${cartItems.length} items · ${getPriceText(
            cartPricing.total,
          )}`}</Text>
          <RoundedButton
            buttonText={'Checkout'}
            buttonTextColor={'#fff'}
            buttonColor={'#000'}
            buttonAction={this.onCheckoutPress}
            style={{
              width: 124,
              height: 32,
              borderRadius: 20,
            }}
            buttonTextStyle={{ fontSize: 14, fontFamily: 'Roboto-Medium' }}
          />
        </View>
      </DebouncedTouchableOpacity>
    );
  }
}

CartAndOffers.propTypes = {
  getCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  cartPricing: PropTypes.object,
  address: PropTypes.array,
  authToken: PropTypes.string,
  cartPrompts: PropTypes.object,
  optForPlasticFreePackaging: PropTypes.bool,
  isContactLess: PropTypes.bool,
};

CartAndOffers.defaultProps = {
  getCartItems: () => {},
  cartItems: [],
  cartPricing: {},
  address: [],
  authToken: '',
  cartPrompts: {},
  optForPlasticFreePackaging: true,
  isContactLess: false,
};

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartPricing: state.bag.cartPricing,
  cartPrompts: state.bag.cartPrompts,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCartItems,
      setCurrentPaymentFlow,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartAndOffers),
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingVertical: 12,
    marginTop: 24,
    borderRadius: 8,
  },
  imageContainer: {
    height: 36,
    marginRight: 8,
    width: 36,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: colors.borderDark,
    resizeMode: 'cover',
    backgroundColor: colors.white,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  headerContainer:{
    flexDirection:'row',
    justifyContent:'space-between',
    paddingRight:12,
  },
  header:{
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginBottom: 4,
    paddingHorizontal: 12,
  },
  crossBtn:{
    width:20,
    height:20,
  },
});
