import Config from '../libraries/ReactNativeConfig';
import AppConfig from '../config/AppConfig';

const initialState = {
  todayDeals: {},
  bag: {
    cartItems: [],
    cartPricing: {},
    cartPrompts: [],
    cartData: {},
    UtoP: {
      // Upgrade To prepaid - Converting order from COD to prepaid
      isUpgradeToPrepaid: false,
      prepaidUpgradeOptionExpiresAt: '',
      orderId: '',
    },
    isContactLess: true,
    isOfferShimmerVisible: false,
    defaultAddress: {},
    isNewPaymentJourney: true,
    cod: false,
    saveToBagOffers: [],
    swipedLeftOrRightWishlist: [],
    addedToCartProduct: {},
    bagModalOpenTimeStamp: null,
    appLaunchState: 'organic',
    carryBagCharges: 0,
  },
  userInteractions: {
    myBrowsing: [],
    productViews: [],
    wishlistTooltip: [],
    lastLike: null,
    isWishlistCrossClicked: false,
    wishlistTooltipResetAt: new Date(),
  },
  userInfo: {
    id: null,
    countryCode: Config.COUNTRY_ISO_CODE || 'IN',
    callingCode: AppConfig.callingCode,
    mobileNumber: null,
    autofillMyProfileDetails: true,
    isVerifying: false,
    profilePic: null,
    authToken: null,
    errors: null,
    followedArtist: [],
    followedArtists: {},
    favoriteContent: [],
    followedArtistQueue: [],
    favoriteContentQueue: [],
    selectedInterests: [],
    optForPlasticFreePackaging: false,
    user_created_at: '',
    app_installed_at: new Date(),
    freeProductModalOpened: false,
    appInstalled: [],
    shouldLogoutUser: false,
    isSpecialOfferApplied: false,
    isSwipeNextCueDisplayed: false,
    networkInfo: {},
    show_edge_activity_pending_indicator: false,
    features: [
      {
        featureSet: 'lips',
        items: [{ usesLipstick: false }, { lipColor: null }],
      },
      {
        featuresSet: 'face',
        items: [{ tone: null }, { type: null }],
      },
      {
        featureSet: 'hair',
        items: [{ color: null }, { type: null }],
      },
      {
        featureSet: 'eyes',
        items: [{ kajal: false }, { eyeliner: false }],
      },
    ],
    handle: '',
    handleError: null,
    handleSuccess: false,
    name: '',
    email: '',
    locality: '',
    latitude: null,
    longitude: null,
    status: null,
    errorCode: null,
    errorMessage: null,
    registered: false,
    authenticated: false,
    verified: false,
    suspicious: false,
    onboardingStatus: null,
    deviceInfo: null,
    deviceId: null,
    favourites: {
      products: {},
      lists: {},
      videos: {},
      images: {},
      brands: {},
      salons: {},
    },
    productRatings: {},
    deliveryRatings: {},
    viewedProducts: {},
    viewedArtists: {},
    viewedArtistStory: {},
    viewedVideos: {},
    updateCurrentPost: false,
    currentPostId: '',
    productSearchingStatus: false,
    searchHistory: [],
    recentSearchId: '',
    addresses: {},
    shopifyAddresses: [],
    productTutorialVideo: true,
    scanProductTutorialVideo: true,
    profile: {
      name: '',
      email: '',
      locality: '',
      handle: '',
      email_verified: false,
    },
    youtube_profile: {},
    instagram_profile: {
      insta_user_name: '',
      insta_full_name: '',
      insta_profile_picture_url: '',
      insta_verification_last_initiated_at: '',
    },
    isUploading: false,
    showUploadTrayOnFeed: false,
    watermark: '',
    mediaCategories: [],
    singletonSteps: {
      createDirectory: true,
      productTutorialVideo: true,
      scanningTutorialVideo: true,
      downloadLogo: true,
      createWatermark: true,
      loadMediaCategories: true,
    },
    fetchingProductVariants: false,
    checkoutDetails: {
      lastPaymentMode: {
        mode: '',
        title: '',
        detail: '',
      },
      savedUpis: [],
      savedCards: [],
      savedIntents: [],
      savedNb: [],
    },
    currentOtp: '',
    requestedFromHintPrompt: false,
    profileAnalytics: {
      youtube: {
        subscriberCount: '0',
        accessToken: '',
        refreshToken: '',
      },
      instagram: {
        followersCount: '0',
      },
    },
    invitationCode: '',
    artistProfile: {
      pendingApprovalCampaigns: [],
      cancelledCampaigns: [],
      approvedCampaigns: [],
      declinedCampaigns: [],
      videoSharedCampaigns: [],
      isArtist: false,
      artistLevel: 0,
      freeProductsAllowance: 0,
      selectedArtistTypes: [],
      selectedBrands: [],
      selectedOfferings: {},
      selectedTrainings: [],
      videoInReviewCampaigns: [],
      videoApprovedCampaigns: [],
      cashRequestedCampaigns: [],
      completedCampaigns: [],
      allArtistTypes: [],
      freelancer_profile: {},
      selectedSalon: {},
      selectedStore: {},
      selectedStudentAcademy: {},
      selectedAcademy: {},
      claimedWelcomeGift: false,
      foxyInviteCode: '',
      hasBeenReferred: false,
      artistCtaOptions: [],
      city: '',
      emoji: '',
      bio: '',
      call_to_action_option_id: '',
    },
    termsAccepted: false,
    tempInstagramHandle: '',
    smsRetrieverStatus: '',
    currentOptinStage: '',
    lastOptinIndex: 0,
    uploadedVideosCount: 0,
    artistGift: {},
    campaigns: {
      statusMessage: {},
    },
    shouldRenderOnboarding: false,
    guestProfile: {},
    facialAnalysis: {},
    currentPincode: '',
    lastUsedAddress: '',
    currentCity: '',
    new_user: '',
    artistTypes: {},
    loginModalState: '',
    remote_configs: {},
    scannedProducts: [],
    featureCards: [],
    phoneNumberModalInfo: {},
    emojis: {},
    areAttributesReviewed: false,
    surprise_available: '',
    surprise_last_taken_at: '',
    myKitData: [],
    has_selfie: '',
    app_last_open_at: new Date(),
    last_app_killed_status: '',
    force_logged_out_flow: '',
    user_engagement: {},
    uac_score: '',
    lastScannedImageUrl: '',
    paytmDetails: {},
    uac_events_status: {
      user_s1_triggered: false,
      user_s2_triggered: false,
      user_s3_triggered: false,
      has_device_event_triggered: false,
    },
    uac_events: {
      uac_device_score: 0,
      uac_selfie_score: 0,
      uac_gender_score: 0,
      uac_paymentapps_score: 0,
      uac_commerceapps_score: 0,
    },
    appInstalledSource: undefined,
    followedArtistStoryRecommendations: {},
    followedStories: [],
    showError: false,
    isUserLogoutOnce: false,
    hasPreviousAssociatedAccount: false,
    appReviewSubmitted: false,
    initial_app_opened_at: new Date().getTime(),
    activeGroupDeals: [],
    orderActionsCards: {},
    likedSalons: [],
    salonSearchHistory: [],
    optForCarryBag: false,
    showCongratsModalForEdge: false,
    isClickedLikeAndDislike: [],
    notificationData: [],
    appFirstOpened: new Date(),
    showEdgePendingIndicator: false,
    continueAction: {
      continueSlug: '',
      continueImage: '',
      continueScreenType: '',
    },
    recentlyViewedProductIds: [],
    showDynamicListTitle: false,
    lastVisitedProductId: undefined,
    authorized: false,
    userRegisteredOnCt: false,
    locationInfo: {},
    locationUpdatedAt: '',
    activeRoutineId: false,
    activeRoutineSlug: '',
    activeRoutineSlugs: [],
    isInfluencerProfileCreated: '',
    partialCartDetails: {},
  },

  interests: [],
  posts: {},
  lastVideoUpload: {
    id: '',
    postData: '',
    status: '',
    progress: 0,
    numOfRetries: 0,
  },
  artistOnboarding: {
    onboardingCompleted: false,
    showCongratulationsModal: false,
  },
  data: {
    entities: {
      ads: {},
      banners: {},
      brands: {},
      features: {},
      products: {},
      salons: {},
      short_banners: {},
      videos: {},
      images: {},
      media_categories: {},
      tags: {},
      lists: {},
      artists: {},
      offers: {},
    },
    result: '1',
  },

  feedDetails: {
    id: '1',
    refreshing: false,
    error: null,
    referralGiftData: {},
  },
  mediaDetails: {},
  productDetails: {
    id: null,
    slug: null,
    type: null,
    isLoading: false,
  },
  artistDetails: {
    id: null,
    slug: null,
    type: null,
    followedArtists: {},
    viewedArtists: {},
    isLoading: false,
  },
  storeDetails: {
    id: '27',
  },
  brandDetails: {
    id: null,
    slug: null,
    type: null,
    isLoading: false,
  },
  cart: {
    id: '',
    cartItems: [],
    cartOffers: {},
    deliveryFeeDiscountCouponCode: '',
    itemCount: 0,
    totalAmount: 0,
    availableOffers: [],
    appliedOffers: [],
    appliedCouponCode: '',
    freeItems: [],
    pricingData: {},
  },
  order: {},
  queue: {
    followedArtist: [],
    unfollowedArtist: [],
    likedProduct: [],
    unlikedProduct: [],
    likedList: [],
    unlikedList: [],
    likedVideo: [],
    unlikedVideo: [],
    likedBrand: [],
    unlikedBrand: [],
    likedImage: [],
    unlikedImage: [],
    likedSalon: [],
    unlikedSalon: [],
  },
  offlineSearch: {
    artists: {},
    products: {},
    videos: {},
    brands: {},
    salons: {},
  },
  searchDetails: {
    topSearches: [],
    searchSuggestions: [],
  },
  facialAnalysis: {},
  followFeatureCard: {
    dismissedOrFollowedCardIds: [],
  },

  stories: {
    viewedStories: [],
    hideStoriesIntro: false,
    viewedTopics: {},
  },
  contactsData: {
    contacts: [],
    alreadyInvitedContacts: {},
    activeContacts: {},
  },
  boostedOffers: {},
  retailStore: {},
  zoomAnimationVisibility: {
    last_zoom_animation_visible_at: null,
    current_zoom_animation_visiblity_counter: 0,
  },
  routines: {
    viewedRoutines: [],
    checkList: {},
    liveStreams: [],
    scheduledNotificationIds: [],
  },

  permissionStatus: {},
  userPersonalizationInfo: {},
  product: {},
};

export default initialState;
