import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import { SET_NEW_PAYMENT_FLOW } from '../actions/PaymentActions';
import initialState from './initialState';
import Utility from '../utils/Utility';

export default function cart(state = initialState.bag, action) {
  if (Utility.isBlank(action.data)) {
    switch (action.type) {
      case types.LOGOUT_USER:
        return {
          ...state,
          cartItems: [],
          cartPricing: {},
          cartPrompts: [],
          deliveryFeeDiscountCouponCode: '',
          UtoP: {
            isUpgradeToPrepaid: false,
            prepaidUpgradeOptionExpiresAt: '',
            orderId: '',
          },
          isContactLess: true,
          isOfferShimmerVisible: false,
          defaultAddress: {},
          carryBagCharges: 0,
        };
      case types.SET_DEFAULT_ADDRESSES:
        return {
          ...state,
          showRetailStoreAddress: false,
        };
      default:
        return state;
    }
  }

  const {
    items = [],
    pricing = {},
    prompts = [],
    delivery_fee_discount_coupon_code = '',
    sku_id,
    quantity,
    isUpgradeToPrepaid = false,
    prepaidUpgradeOptionExpiresAt,
    orderId = '',
    isContactLess,
    isOfferShimmerVisible = false,
    amount = 0,
    isNewPaymentFlow = true,
    isCod,
    id,
    itemData = {},
    timeStamp,
    appState,
    carryBagCharges = 0,
  } = action.data;

  const {
    total = 0,
    plastic_free_packaging_charges = 0,
    carry_bag_amount = 0,
  } = state.cartPricing;

  switch (action.type) {
    case types.SAVE_BAG:
      return {
        ...state,
        cartItems: items,
        cartPricing: pricing,
        cartPrompts: prompts,
        deliveryFeeDiscountCouponCode: delivery_fee_discount_coupon_code,
      };

    case types.SAVE_BAG_ITEMS:
      return {
        ...state,
        cartItems: items,
      };

    case types.SAVE_BAG_PROMPTS:
      return {
        ...state,
        cartPrompts: prompts,
      };

    case types.SAVE_BAG_PRICE:
      return {
        ...state,
        cartPricing: pricing,
      };

    case types.SAVE_BAG_ITEM_AND_PROMPT:
      return {
        ...state,
        cartItems: items,
        cartPrompts: prompts,
      };

    case types.SAVE_BAG_ITEM_AND_PRICE:
      return {
        ...state,
        cartItems: items,
        cartPricing: pricing,
      };

    case types.SAVE_CART_DATA:
      return {
        ...state,
        cartData: action.data,
      };

    case types.SAVE_BAG_PRICE_AND_PROMPT:
      return {
        ...state,
        cartPricing: pricing,
        cartPrompts: prompts,
      };

    case types.INCREMENT_DECREMENT_FROM_CART_BEFORE_CALL:
      let newItems = _.map(state.cartItems, (item) => {
        if (item.sku_id === sku_id) {
          let updateItem = { ...item };
          updateItem.quantity = updateItem.quantity + quantity;
          return updateItem;
        } else {
          return item;
        }
      });

      return {
        ...state,
        cartItems: newItems,
      };

    case types.SAVE_ADDRESS:
      return {
        ...state,
        cartPricing: pricing,
      };

    case types.SET_UPGRADE_TO_PREPAID:
      return {
        ...state,
        UtoP: {
          isUpgradeToPrepaid,
          prepaidUpgradeOptionExpiresAt,
          orderId,
        },
      };

    case types.RENDER_OFFER_SHIMMER:
      return {
        ...state,
        isOfferShimmerVisible,
      };

    case types.SET_CONTACT_LESS:
      return {
        ...state,
        isContactLess,
      };

    case types.UPDATE_PLASTIC_FREE_DATA:
      return {
        ...state,
        cartPricing: {
          ...state.cartPricing,
          total: `${parseFloat(total) + parseFloat(amount)}`,
          plastic_free_packaging_charges: `${
            parseFloat(plastic_free_packaging_charges) + parseFloat(amount)
          }`,
        },
      };

    case types.SAVE_ALL_ADDRESSES:
      const { data = {} } = action || {};
      const { ekanek_addresses = [], shopify_addresses = [] } = data;
      const default_ekanek_address = ekanek_addresses?.filter(
        (item) => item?.default || item?.attributes?.default,
      )?.[0];
      const default_shopify_address = shopify_addresses?.filter(
        (item) => item.default || item?.attributes?.default,
      )?.[0];
      let default_address = '';
      if (Utility.isPresent(default_ekanek_address)) {
        default_address = default_ekanek_address;
      } else if (Utility.isPresent(default_shopify_address)) {
        default_address = default_shopify_address;
      }

      return {
        ...state,
        defaultAddress: default_address,
      };

    case types.SET_COD:
      return {
        ...state,
        cod: isCod,
      };

    case SET_NEW_PAYMENT_FLOW:
      return {
        ...state,
        isNewPaymentJourney: isNewPaymentFlow,
      };

    case types.SWIPED_LEFT_OR_RIGHT_WISHLIST_BUILDER:
      return {
        ...state,
        swipedLeftOrRightWishlist: [...state.swipedLeftOrRightWishlist, id],
      };

    case types.ADDED_TO_CART_PRODUCT:
      return {
        ...state,
        addedToCartProduct: itemData,
      };

    case types.SAVE_BAG_MODAL_OPEN_TIME_STAMP_AND_APP_LAUNCH_STATE:
      return {
        ...state,
        bagModalOpenTimeStamp: !!timeStamp
          ? timeStamp
          : state.bagModalOpenTimeStamp,
        appLaunchState: Utility.isPresent(appState)
          ? appState
          : state.appLaunchState,
      };

    case types.ADD_CARRY_BAG_CHARGES:
      return {
        ...state,
        carryBagCharges,
      };

    case types.UPDATE_CARRY_BAG_DATA:
      return {
        ...state,
        cartPricing: {
          ...state.cartPricing,
          total: `${parseFloat(total) + parseFloat(amount)}`,
          carry_bag_amount: `${
            parseFloat(carry_bag_amount) + parseFloat(amount)
          }`,
        },
      };

    default:
      return state;
  }
}
