import React from 'react';
import { dynamicLinks } from '../libraries/Firebase';
import { Linking } from 'react-native';
import {
  APP_CONSTANTS,
  WEB_URL,
  DYNAMIC_LINKS_CONFIG,
  BASE_API,
  LINK_DOMAIN,
  SHORT_WEB_URL,
  HTTP_WEB_URL,
  BASE_API_V2,
  APP_LOAD_SOURCE,
  LINK_DOMAIN_SHORT,
  WWW_WEB_URL,
  APP_INTENT_SCHEME,
  HTTP_WWW_WEB_URL,
  TYPE_FORM_URL,
  HOST_NAME,
  TRACKING_PARAMS,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  EnableShopifyDirect,
} from '../config/Constants';
import Utility from './Utility';
import withNavigation from './WithNavigation';
import DynamicLinkUtilities from './DynamicLinkUtilities';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsUtilty,
} from '../analytics';
import Session from './Sessions';
import Config from '../libraries/ReactNativeConfig';
import { isBlank, isWeb } from './BooleanUtility';
import { extractQueryParamsFromDeepLink } from './UriUtility';

class DynamicLinkManager {
  static createDynamicLink(shareUrl) {
    const link = `${shareUrl}`;
    const dynamicLinkDomain = LINK_DOMAIN;
    const fallbackUrl = 'https://play.google.com/store/apps/details?id=in.foxy';
    const dLinkBuilder = new dynamicLinks.DynamicLink(
      link,
      dynamicLinkDomain,
    ).ios
      .setAppStoreId(APP_CONSTANTS.IOS.APP_STORE_ID)
      .ios.setBundleId(APP_CONSTANTS.IOS.BUNDLE_ID)
      .android.setPackageName('in.foxy')
      .android.setMinimumVersion('8')
      .android.setFallbackUrl(fallbackUrl)
      .social.setTitle('foxy');
    const createdLink = dynamicLinks().createDynamicLink(dLinkBuilder);
    return createdLink;
  }

  // TODO: Wrong function name, It should be createShortLink
  static getShortenUrl = (
    url,
    linkOptions = {},
    enableFallbackLink = true,
    analytics = {},
    socialMeta = {},
  ) => {
    const domainLinkDomain = Utility.isIOS()
      ? DYNAMIC_LINKS_CONFIG.IOS_DOMAIN
      : DYNAMIC_LINKS_CONFIG.ANDROID_DOMAIN;

    const shareUrl = Utility.appendUtmParamsWithUrl(
      url,
      linkOptions.trackingParams,
    );

    const configs = !enableFallbackLink
      ? {
          link: shareUrl,
          domainUriPrefix: domainLinkDomain,
          analytics,
          socialMeta,
        }
      : {
          link: shareUrl,
          domainUriPrefix: domainLinkDomain,
          ios: {
            bundleId: Config.PRODUCT_BUNDLE_IDENTIFIER,
            appStoreId: Config.APP_STORE_ID,
            fallbackUrl: APP_STORE_LINK,
          },
          android: {
            packageName: Config.PACKAGE_NAME_ANDROID,
            fallbackUrl: PLAY_STORE_LINK,
          },
          analytics,
          social: socialMeta,
        };

    const link = dynamicLinks().buildShortLink(configs, 'SHORT');
    return link;
  };

  // TODO: Function name should be handleLink
  /**
   *
   * @param {*} internalNavigation: if true it will set utm_source=internal&utm_medium=internal with url
   */

  static handleDynamicLink(linkUrl, furtherAction, internalNavigation = true) {
    let url = linkUrl;
    if (isBlank(url)) return;
    if (internalNavigation) {
      url = Utility.appendUtmParamsWithUrl(url, TRACKING_PARAMS.INTERNAL);
    }

    url = decodeURI(url);

    const [slug, params] = DynamicLinkUtilities.removeDomainName(url);
    const route = DynamicLinkUtilities.extractRouteFromSlug(slug, url);

    /**
     * In case of internal navigation we dont need any setTimeout.
     * setTimeout is important in case of external deeplink navigation
     * when app start for the first time where
     * createNavigationContainerRef() at NavigationService.js need time to initialize
     */
    if (internalNavigation) {
      DynamicLinkManager.navigationHandler(
        route,
        slug,
        furtherAction,
        params,
        url,
      );
      return;
    }

    setTimeout(() => {
      DynamicLinkManager.navigationHandler(
        route,
        slug,
        furtherAction,
        params,
        url,
      );
    }, 800);
  }

  static handleLinkWithInternalTrackingParams(linkUrl, furtherAction) {
    if (Utility.isBlank(linkUrl)) return;
    DynamicLinkManager.handleDynamicLink(linkUrl, furtherAction, true);
  }

  static handleLinkWithoutInternalTrackingParams(linkUrl, furtherAction) {
    DynamicLinkManager.handleDynamicLink(linkUrl, furtherAction, false);
  }

  static handleDynamicLinkWithoutDelay(
    linkUrl,
    furtherAction,
    internalNavigation = true,
  ) {
    let url = linkUrl;

    if (internalNavigation) {
      url = Utility.appendUtmParamsWithUrl(url, TRACKING_PARAMS.INTERNAL);
    }

    url = decodeURI(url);

    const [slug, params] = DynamicLinkUtilities.removeDomainName(url);
    const route = DynamicLinkUtilities.extractRouteFromSlug(slug, url);

    DynamicLinkManager.navigationHandler(
      route,
      slug,
      furtherAction,
      params,
      url,
    );
  }

  static handleSlug = (slug, furtherAction) => {
    if (!Utility.isBlank(slug) && slug !== 'undefined' && slug !== 'null') {
      let path = '';

      const queryString = slug.split('/');
      if (queryString.length >= 2) {
        path = queryString[3];
      }

      DynamicLinkManager.navigationHandler(path, slug, furtherAction);
    }
  };

  static subscribeDynamicLinks(furtherAction) {
    if (isWeb()) return;
    this.unsubscribe = dynamicLinks().onLink((link) => {
      const { url } = link;
      DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
        url,
        furtherAction,
      );
    });
  }

  static fireAppLoadForDynamicOrDeepLinks = (appFirstOpened) => {
    try {
      Linking.getInitialURL().then((url) => {
        if (Utility.isPresent(url)) {
          if (url.includes('push_from=moengage')) {
            AnalyticsUtilty.fireAppLoadEvent(
              APP_LOAD_SOURCE.NOTIFICATION,
              appFirstOpened,
            );
          } else if (url.includes(LINK_DOMAIN)) {
            AnalyticsUtilty.fireAppLoadEvent(
              APP_LOAD_SOURCE.DYNAMIC_LINK,
              appFirstOpened,
              url,
            );
          } else {
            AnalyticsUtilty.fireAppLoadEvent(
              APP_LOAD_SOURCE.DEEP_LINK,
              appFirstOpened,
              url,
            );
          }
        }
      });
    } catch (e) {
      console.tron.log('error in handling notification');
    }
  };

  static checkForInitialDynamicLink = (furtherAction) => {
    if (isWeb()) return;
    dynamicLinks()
      .getInitialLink()
      .then((link) => {
        const { url } = link;

        Session.setInitialAppDeeplink(url);
        if (!Utility.isBlank(url) && !url.includes(LINK_DOMAIN_SHORT)) {
          AnalyticsManager.logEvent(EventType.deeplink.DEEP_LINK_OPEN, {
            [EventParameterKey.LINK_TYPE]:
              EventParameterValue.LINKS.LINK_TYPE.dynamicLink,
            [EventParameterKey.STATUS]:
              EventParameterValue.LINKS.STATUS.recieved,
            ...extractQueryParamsFromDeepLink(url),
          });
          DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
            decodeURIComponent(url),
            furtherAction,
          );
        } else {
          this.checkForInitialDeepLink(furtherAction);
        }
      })
      .catch((error) => {
        this.checkForInitialDeepLink(furtherAction);
      });
  };

  static checkForInitialDeepLink = (furtherAction) => {
    if (
      Utility.isPresent(Session.initialAppDeeplink) &&
      !Session.initialAppDeeplink?.includes(LINK_DOMAIN)
    ) {
      /**
       * FIXME: This is a hack to prevent homepage navigation to overriding deeplink navigation.
       *  this may not work as expected in some low end devices. In that case:
       *  will have to fix navigation
       */
      setTimeout(() => {
        DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
          Session.initialAppDeeplink,
          furtherAction,
        );
        // Session.setInitialAppDeeplink('');
      }, 0);

      return;
    }

    Linking.getInitialURL()
      .then((url) => {
        if (url.includes(LINK_DOMAIN_SHORT)) {
          DynamicLinkManager.resolveLink(url, furtherAction, false);
          return;
        }
        if (
          url.includes(WWW_WEB_URL) ||
          url.includes(WEB_URL) ||
          url.includes(APP_INTENT_SCHEME)
        ) {
          AnalyticsManager.logEvent(EventType.deeplink.DEEP_LINK_OPEN, {
            [EventParameterKey.LINK_TYPE]:
              EventParameterValue.LINKS.LINK_TYPE.deeplink,
            [EventParameterKey.STATUS]:
              EventParameterValue.LINKS.STATUS.recieved,
            ...extractQueryParamsFromDeepLink(url),
          });
          DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
            decodeURIComponent(url),
            furtherAction,
          );
        }
      })
      .catch((error) => {});
  };

  static unsubscribeDynamicLinks = () => {
    this.unsubscribe();
  };

  /**
   * @path = apply_coupon || order_details
   * @slugString = /apply_coupon/<coupon_code> ||  /order_detail/<order_id>
   */
  static navigationHandler = (
    path = '',
    slugString = '',
    furtherAction,
    params,
    url,
  ) => {
    if (Utility.isBlank(path) && Utility.isBlank(url)) {
      return;
    }
    const { HOST_NAME, WEB_URL, WWW_WEB_URL, HTTP_WEB_URL, HTTP_WWW_WEB_URL } =
      Config;
    if (
      url === HOST_NAME ||
      url === WEB_URL ||
      url === WWW_WEB_URL ||
      url === HTTP_WEB_URL ||
      url === HTTP_WWW_WEB_URL
    ) {
      url = WWW_WEB_URL;
    }

    console.tron.log(url, 'newUrl');
    AnalyticsManager.logEvent(EventType.deeplink.DEEP_LINK_OPEN, {
      [EventParameterKey.PATH]: path,
      [EventParameterKey.SLUG]: slugString,
      [EventParameterKey.STATUS]: EventParameterValue.LINKS.STATUS.mapped,
      ...extractQueryParamsFromDeepLink(url),
    });
    let newPath = '';
    if (path === 'brands' && slugString === '/brands') {
      newPath = 'altBrand';
    } else if (path === 'stories' && slugString.includes('/stories')) {
      newPath = 'reviews';
    } else if (slugString.startsWith('/@')) {
      newPath = 'influencer';
    } else if (path === 'salons') {
      newPath = 'salons';
    } else if (path === 'pro') {
      newPath = slugString.includes('/pro/') ? url.split('/pro/')[1] : '';
    }
    const pathToNavigate = Utility.isPresent(newPath) ? newPath : path;

    let route = Utility.mapPathToComponentRoute(pathToNavigate);
    if (EnableShopifyDirect && url.includes('shopify_collections')) route = 'ShopifyCollections';
    const slugStr = slugString.replace(/\/$/, '').trim();

    let { slug, extra } = DynamicLinkUtilities.generateSlugWithData(
      path,
      slugStr,
      params,
    );
    //TODO: Not able to understand this code. What exactly it do
    //www.foxy.in/orders/123231333 => Route = OrderDetails
    if (Utility.shouldUseId(route)) {
      slug = slugStr;
      if (Utility.isPresent(slug) && slug.includes('/')) {
        const splittedWords = slug.split('/');
        if (slug.length >= 1) {
          slug = splittedWords.reverse()[0];
        }
      }
    }

    if (route !== 'WebUrlView') {
      AnalyticsManager.logEvent(EventType.deeplink.DEEP_LINK_OPEN, {
        [EventParameterKey.PATH]: path,
        [EventParameterKey.SLUG]: slug,
        [EventParameterKey.ROUTE]: route,
        [EventParameterKey.STATUS]: EventParameterValue.LINKS.STATUS.navigated,
        ...extractQueryParamsFromDeepLink(url),
      });
      furtherAction({ route, slug, path, extra, params, url });
    } else if (
      route === 'WebUrlView' &&
      !(
        url.includes(WWW_WEB_URL) ||
        url.includes(HTTP_WWW_WEB_URL) ||
        url.includes(APP_INTENT_SCHEME) ||
        url.includes(WEB_URL) ||
        url.includes(HTTP_WEB_URL) ||
        url.includes(SHORT_WEB_URL) ||
        url.includes(TYPE_FORM_URL) ||
        url.includes(HOST_NAME)
      )
    ) {
      Linking.openURL(url);
    } else if (route === 'WebUrlView') {
      furtherAction({ route, slug: url, url, path, extra, params });
    } else {
      furtherAction({ route, slug, path, extra, params, url });
    }
  };

  static detectDynamicLinkWhenAppIsRunning = (furtherAction) => {
    this.subscribeDynamicLinks(furtherAction);
  };

  static appOpenedFromDynamicLink = (furtherAction, appFirstOpened) => {
    this.fireAppLoadForDynamicOrDeepLinks(appFirstOpened);
    this.checkForInitialDynamicLink(furtherAction);
  };

  /**
   * This function will try to resolve the short url.
   * there are 2 cases if link is short dynamic link or proper foxy link.
   * if short dynamic link - it will try to resolve and extract proper url from that short link and navigate
   * if its foxy link or links.foxy.in/<slug> it will go to catch block and treat as normal deeplink
   */
  static resolveLink = (urlLink, furtherAction, internalNavigation = true) => {
    dynamicLinks()
      .resolveLink(urlLink)
      .then((link) => {
        const { url } = link;
        if (Utility.isPresent(url) && !url.includes(LINK_DOMAIN_SHORT)) {
          AnalyticsUtilty.logDeepLinkStatusEvent(
            EventParameterValue.LINKS.STATUS.recieved,
            extractQueryParamsFromDeepLink(url),
          );

          DynamicLinkManager.handleDynamicLink(
            decodeURIComponent(url),
            furtherAction,
            internalNavigation,
          );
        }
      })
      .catch((error) => {
        DynamicLinkManager.handleDynamicLink(
          urlLink,
          furtherAction,
          internalNavigation,
        );
      });
  };
}

export default DynamicLinkManager;

// export default function (props) {
//   const navigation = useNavigation();

//   return <DynamicLinkManager navigation={navigation} {...props} />;
// }

// export default withNavigation(DynamicLinkManager);
