import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import colors from '../theme/Colors';
import { withMaybe } from '../lib/Monads';
import Utility from './Utility';
import { CURRENCY_SYMBOL } from '../config/Constants';
import { getMinifiedImage } from './ImageUtility';
import { isPresent } from './BooleanUtility';

class DockedHeader extends PureComponent {
  variantCondition = (props) => Utility.isBlank(props.variant);

  shadeInHeader = withMaybe(this.variantCondition)(View);

  showVariantCondition = (props) =>
    props.selectedVariantImage === '' && props.selectedVariantColor === '';

  variantImage = withMaybe(this.showVariantCondition)(ImageBackground);

  render() {
    let {
      name,
      selectedVariant = {},
      boldTitle,
      itemData: { final_sp } = {},
      itemData = {},
      couponCodes = [],
      displayFinalSp = '',
      mrp,
    } = this.props;

    let {
      display_name: selectedVariantName = '',
      color_code: selectedVariantColor = '',
      image_url: selectedVariantImage = '',
      title = '',
    } = selectedVariant;

    let display_sp_price = final_sp;

    let { offersEsp: prompt_esp, isEspOffer } = Utility.getEsp(
      itemData?.prompts,
      couponCodes,
    );

    if (isPresent(selectedVariant)) {
      const { prompts, final_sp: selectedVariantFinalSp = '' } =
        selectedVariant;
      display_sp_price = selectedVariantFinalSp;
      const { offersEsp, isEspOffer } = Utility.getEsp(prompts, couponCodes);

      // prompt_esp = offersEsp;

      if (!isEspOffer) {
        // display_sp_price = mrp;
      }
    }

    if (Utility.isPresent(prompt_esp)) {
      // display_sp_price = prompt_esp;
      if (!isEspOffer) {
        // display_sp_price = mrp;
      }
    }
    if (!isEspOffer) {
      // display_sp_price = mrp;
    }
    if (Utility.isBlank(selectedVariantImage)) {
      selectedVariantImage = '';
    }
    if (Utility.isBlank(selectedVariantColor)) {
      selectedVariantColor = '';
    }

    const selectedImage = Utility.isPresent(selectedVariantImage)
      ? getMinifiedImage(
          selectedVariantImage,
          styles.shadeInHeaderStyle.width,
          styles.shadeInHeaderStyle.height,
        )
      : '';

    if (isPresent(title) && title !== 'Default Title') {
      selectedVariantName = title;
    }

    if (displayFinalSp) {
      display_sp_price = displayFinalSp;
    }

    return (
      <View style={styles.dockedHeaderContainer}>
        <Text
          numberOfLines={1}
          style={styles.nameInHeaderStyle}
          allowFontScaling={false}
        >
          {name}
        </Text>
        {isPresent(mrp) && (
          <Text
            numberOfLines={1}
            style={styles.nameInHeaderStyle}
            allowFontScaling={false}
          >
           {`${CURRENCY_SYMBOL} ${mrp}`}
          </Text>
        )}
        <View style={{ flexDirection: 'row' }}>
          <this.shadeInHeader
            variant={selectedVariant}
            style={styles.headerShadeContainer}
          >
            <this.variantImage
              source={{ uri: selectedImage }}
              style={[
                styles.shadeInHeaderStyle,
                { backgroundColor: selectedVariant },
              ]}
              selectedVariantImage={selectedImage}
              selectedVariantColor={selectedVariantColor}
            />
            {isPresent(selectedVariantName) && (
              <Text
                numberOfLines={1}
                style={[styles.nameInHeaderStyleRegular, { fontSize: 12 }]}
                allowFontScaling={false}
              >
                {`${selectedVariantName} ·\u00A0`}
              </Text>
            )}
          </this.shadeInHeader>

          {Utility.isPresent(display_sp_price) && (
            <Text
              numberOfLines={1}
              style={[styles.nameInHeaderStyleRegular, { fontSize: 12 }]}
              allowFontScaling={false}
            >
              {`${CURRENCY_SYMBOL}${display_sp_price}`}
            </Text>
          )}
        </View>
      </View>
    );
  }
}

DockedHeader.propTypes = {
  name: PropTypes.string,
  selectedVariant: PropTypes.string,
  selectedVariantName: PropTypes.string,
};

const styles = StyleSheet.create({
  dockedHeaderContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
    marginRight: 4,
  },
  headerShadeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  nameInHeaderStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  nameInHeaderStyleRegular: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  shadeInHeaderStyle: {
    height: 12,
    width: 12,
    borderRadius: 3,
    marginRight: 8,
    overflow: 'hidden',
  },

  boldHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
});
export default DockedHeader;
