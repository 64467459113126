import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT, MORE_ITEMS } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FastImageView from '../FastImageView';
import Images from '../../theme/Images';
import size from '../../theme/Fonts';
import LinearGradient from 'react-native-linear-gradient';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class DefaultSizeProductRail extends PureComponent {
  static getComponentHeight() {
    return styles.railCardContainer.height;
  }

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
    this.navigationSource = getNavigationSource(this, this.props);
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, stocked_status, replacement_sku },
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    // if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
    applyFlashDeal({
      type: 'product',
      id,
    });
    // }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource: this.getNavigationSource,
      params: {
        id,
        slug:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku)
            ? `/api/v1/products/${replacement_sku}`
            : slug,
        isReplacement:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku),
        original_slug: slug,
        name: itemData.name,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData = {},
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      listData: {
        options: listOptions = {},
        slug: listSlug = {},
      } = {},
      listData = {},
      navigation,
      parentListsData = [],
      extraEventParameters = {},
    } = this.props;

    let {
      listData: {
        display_count: displayCount = '',
      } = {},
    } = this.props;


    if (isDesktop()) {
      displayCount *= 2;
    }

    const options = Utility.isPresent(listOptions) ? listOptions : {};
    // FIXME: This is an ugly hack. Use better code design to achieve this.
    const { show_timer_in_subtitle = false } = options;

    if (index === displayCount - 1 && show_timer_in_subtitle === true) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: MORE_ITEMS,
        params: {
          displayCount: itemData?.objects?.length,
          heading: itemData.name,
          navigation,
          title: itemData.name,
          slug: listData.slug,
        },
      });
      return;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }
    const extraParams = {
      ...extraEventParameters,
      ...AnalyticsUtility.getParentsList(parentListsData),
    };
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData.slug,
      listSlug,
      extraParams,
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData,
      listData: { additional_data = {} } = {},
      parentListsData = [],
      extraEventParameters = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.RAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        parentListsData={parentListsData}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        additional_data={additional_data}
        listData={listData}
        extraEventParameters={extraEventParameters}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        transparent_image_url = '',
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = '#B57CD6',
        } = {},
      } = {},
      listData: {
        options: listOptions = {},
        options: { disable_card_background_color = '0' } = {},
        display = '',
        subTitle = '',
      } = {},
      index,
      listData,
    } = this.props;

    let {
      listData: {
        display_count: displayCount = '',
      } = {},
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    const finalBackgroundColor =
      (disable_card_background_color === '1' || Utility.isBlank(transparent_image_url))
      ? colors.white
      : backgroundColor || colors.white;

    const options = Utility.isPresent(listOptions) ? listOptions : {};

    const { show_borders_for_list_items = 0, show_timer_in_subtitle = false } =
      options;

    const containerStyle =
      Utility.isPresent(transparent_image_url) || show_borders_for_list_items
        ? (index === 0) ? styles.firstBorderedRailCardContainer : styles.borderedRailCardContainer
        : (index === 0) ? styles.firstRailCardContainer : styles.railCardContainer;

    const gradientArray = [
      Utility.addAlpha(finalBackgroundColor, 0.15),
      Utility.addAlpha(finalBackgroundColor, 0.25),
    ];
    const transparentImageUrl =
      disable_card_background_color !== '1' ? transparent_image_url : '';

    if (isDesktop()) {
      displayCount *= 2;
    }

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={containerStyle}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.RAIL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                itemData={itemData}
                transparentImageUrl={transparentImageUrl}
                isVariant={hasVariants}
              />
              <LinearGradient
                colors={gradientArray}
                style={styles.gradient}
                start={this.x}
                end={this.y}
              />
            </View>
            <View style={styles.fullCardDivider} />
            <View style={{ flex: 1 }}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.RAIL}
                id={id}
                previousScreen={previousScreen}
                numberOfLines={1}
              />
            </View>
            <View style={styles.cartImageContainer}>
              <this.addToCart
                skuId={skuId}
                orientation={orientation}
                toggleCartState={this.toggleCartState}
                layout={LAYOUT.RAIL}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                hideAddToCart={hideAddToCart}
                id={id}
                itemData={itemData}
                hasVariants={hasVariants}
                showCart={this.showCart}
                priority={priority}
                outOfStock={outOfStock}
                previousScreen={previousScreen}
                singleStockedVariantSku={singleStockedVariantSku}
              />
            </View>
            {index === displayCount - 1 && show_timer_in_subtitle === true && (
              <View style={styles.viewMoreIconContainer}>
                <FastImageView
                  source={Images.view_more_icon}
                  style={styles.viewMoreIcon}
                  resizeMode="contain"
                />
                <Text style={styles.viewMoreText}>View More</Text>
              </View>
            )}
          </Card>
          <this.productVariants containerStyle={styles.variantContainerStyle} />
        </ScaleAnimate>
      </View>
    );
  }
}

const noOfItems = isDesktop() ? 6 : 2;

const styles = StyleSheet.create({
  railCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 10,
    width: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3 + 116,
    alignSelf: 'center',
    borderRadius: 10,
  },
  firstRailCardContainer:{
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginVertical: 4,
    marginRight: 4,
    flexDirection: 'column',
    borderRadius: 10,
    width: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3 + 116,
    alignSelf: 'center',
    borderRadius: 10,
  },
  borderedRailCardContainer:{
    backgroundColor: colors.white,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 10,
    width: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3 + 116,
    alignSelf: 'center',
    borderRadius: 10,
    borderColor: colors.border,
    borderWidth: 1,
  },
  firstBorderedRailCardContainer:{
    backgroundColor: colors.white,
    marginVertical: 4,
    marginRight: 4,
    flexDirection: 'column',
    borderRadius: 10,
    width: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3 + 116,
    alignSelf: 'center',
    borderRadius: 10,
    borderColor: colors.border,
    borderWidth: 1,
  },
  imageContainer: {
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  gradient: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    top: 0,
    position: 'absolute',
    zIndex: -1,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 92,
    right: 7,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
  viewMoreIconContainer: {
    position: 'absolute',
    borderRadius: 8,
    width: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(noOfItems, 16) * 1.3 + 116,
    backgroundColor: colors.white,
    opacity: 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMoreIcon: {
    height: 80,
    width: 80,
  },
  viewMoreText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    color: colors.cta.lightBlue,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(null, mapDispatchToProps)(DefaultSizeProductRail),
);
