// Dependencies
import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import lodash, { capitalize } from 'lodash';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import GridStyles from './styles';
import LAYOUT_CONFIG from '../../../config/LayoutConstants/LayoutConfig';
import styles from './styles';
import ProductPersonalizedHorizontal from '../../Product/ProductPersonalizedHorizontal';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../../utils/PerfUtility';

class PersonalizedHorizontal extends Component {
  static Components = {
    ProductPersonalizedHorizontal,
    VariantPersonalizedHorizontal: ProductPersonalizedHorizontal,
  };

  static getComponentHeight() {
    return 669.2;
  }

  constructor(props) {
    super(props);

    this.displayCount = lodash.max([
      LAYOUT_CONFIG.minListCount,
      this.props.displayCount || 0,
    ]);
    this.itemData = this.props.item.filter((item) => !!item);
    this.column = LAYOUT_CONFIG.numColumn[this.props.content] || 2;
    this.viewedItemsIds = [];
  }

  outOfStockWishlistProductCondition = (previousScreen, stockedStatus) =>
    previousScreen === SCREEN_CONSTANTS.WISH_DEALS &&
    Utility.isProductOutOfStock(stockedStatus);

  getComponent = ({ item, index }) => {
    const {
      size,
      id,
      content,
      navigation,
      toggleCartVisibility,
      listName,
      listIndex,
      previousScreen,
      listData,
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      updateSelectedItem,
      selectedItem,
      viewFreeProductScreen,
      listContent,
      onPress,
      search,
      searchQuery,
      elementItemCounts,
      freeProductScreen,
      extraEventParameters = {},
      verticalComponentWidth = {},
    } = this.props;
    if (
      Utility.isBlank(item) ||
      this.outOfStockWishlistProductCondition(
        previousScreen,
        item?.stocked_status,
      )
    ) {
      return null;
    }

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ProductPersonalizedHorizontal
          id={item.id}
          layout={LAYOUT.PERSONALIZED_HORIZONTAL}
          itemData={item}
          listName={listName}
          listContent={listContent}
          type={item.type}
          size={size}
          listIndex={listIndex}
          navigation={navigation}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={index}
          toggleCartVisibility={toggleCartVisibility}
          previousScreen={previousScreen}
          listData={listData}
          refreshOfferStrip={refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          personalisedGrid={false}
          onPress={onPress}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          content={content}
          updateSelectedItem={updateSelectedItem}
          selectedItem={selectedItem}
          viewFreeProductScreen={viewFreeProductScreen}
          freeProductScreen={freeProductScreen}
          noOfItems={(this.itemData || []).length}
          extraEventParameters={extraEventParameters}
          verticalComponentWidth={verticalComponentWidth}
        />
      </ErrorBoundaryComponent>
    );
  };

  keyExtractor = (item) => `${item.schema}_${item.id}_grid`;

  listKey = (item, index) =>
    `PersonalizedHorizontal${index.toString()}_${item.id}`;

  render() {
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    return (
      <View style={GridStyles.container}>
        <FlatList
          data={this.itemData}
          horizontal
          scrollEnabled
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          extraData={this.state}
          keyExtractor={this.keyExtractor}
          listKey={this.listKey} // added to prevent virtuaized list crash
          renderItem={this.getComponent}
          style={styles.flatlistStyle}
          initialNumToRender={4}
          snapToInterval={Utility.getScreenWidth() - 54}
          snapToAlignment={'start'}
          decelerationRate={'fast'}
        />
      </View>
    );
  }
}

PersonalizedHorizontal.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
  content: PropTypes.string,
};

export default PersonalizedHorizontal;
