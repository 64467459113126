import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  FlatList,
  Text,
  ScrollView,
  TouchableWithoutFeedback,
  ImageBackground,
} from 'react-native';
import PropTypes from 'prop-types';
import _, { memoize } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toast from 'react-native-easy-toast';
import {
  CUSTOMIZED_SKU_FORM_PAGE,
  DisableVarianListScrollability,
  LAYOUT,
  MAX_LINES,
} from '../../config/Constants';
import { ProductInfoStyles, ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { withEither, withMaybe } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import images from '../../theme/Images';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import {
  getVariantsForProduct,
  getRecommendedVariants,
} from '../../actions/ActionTypes';
import VariantSectionList from './VariantSectionList';
import RequestProductButton from './RequestProductButton';
import VariantImagesWithHeader from './VariantImagesWithHeader';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RemoteConfig from '../../utils/RemoteConfig';
import IncrementDecrement from './IncrementDecrement';
import Config from '../../libraries/ReactNativeConfig';
import VariantModalQuantity from './VariantModalQuantity';
import AppConfig from '../../config/AppConfig';
import VariantUtility, { getMultiVariantOosConfig } from '../../utils/VariantUtility';
import WithNavigation from '../../utils/WithNavigation';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import { getPriceText } from '../../utils/NumberUtility';
import { isBlank, isPresent, isDesktop } from '../../utils/BooleanUtility';
import VariantWithImage from './VariantWithImage';
import { getHitSlop } from '../../utils/LayoutUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import VariantQuantityText from './VariantQuantityText';

const VariantPropertyListIndex = {
  primary: 0,
  secondary: 1,
  tertiary: 2,
};

const numColumns = 7;

class VariantModal extends Component {
  constructor(props) {
    super(props);
    this.specialPriceVariant = [];
    this.premiumVariant = [];
    this.moreVariant = [];
    this.soldOutVariant = [];
    this.itemData = this.props.route?.params?.itemData;
    this.state = {
      variantAttributes: [],
      recommendedVariants: [],
      variantMrp: '',
      variantSp: '',
      variantDiscount: '',
      selectedSku: null,
      selectedVariant: {},
      selectedVariantId: '',
      selectedVariantName: '',
      selectedVariantImage: '',
      selectedVariantColor: '',
      productImages: [],
      quantity: 1,
      selectedMultiVariantPrimary: {},
      selectedMultiVariantSecondary: {},
      selectedMultiVariantTertiary: {},
      multiVariantAttributes: {},
    };
    const {
      property_count: variantPropertyCount = 0,
      variant_type: variantType = '',
    } = this.itemData;
    this.variantModalContainerStyle =
      variantType === PRODUCT.ATTRIBUTE_TYPE.IMAGE
        ? ProductInfoStyles.variantModalContainer
        : ProductInfoStyles.variantModalContainerForSize;
    this.variantModalContainerStyle = [
      this.variantModalContainerStyle,
      variantPropertyCount > 1 && { height: '92%' },
      isDesktop() && { width: '80%', height: '80%', alignSelf: 'center' },
    ];
    this.variantsOosConfig = {};
  }

  componentDidMount() {
    this.hitApis();
  }

  getOutOfStockStatus = (item, level) => {
    const { value: itemValue = '' } = item;
    const {
      selectedMultiVariantPrimary: { value: selectedPrimaryValue } = {},
      selectedMultiVariantSecondary: { value: selectedSecondaryValue } = {},
      selectedMultiVariantTertiary,
    } = this.state;
    const { primary, secondary, tertiary } = VariantPropertyListIndex;
    let isOutOfStock = false;
    try {
      switch (level) {
        case primary:
          isOutOfStock = this.variantsOosConfig[itemValue].oos;
          break;
        case secondary:
          isOutOfStock = this.variantsOosConfig[selectedPrimaryValue][itemValue].oos;
          break;
        case tertiary:
          isOutOfStock = this.variantsOosConfig[selectedPrimaryValue][selectedSecondaryValue][itemValue].oos;
          break;
        default:
      }
    } catch (e){
    }
    return isOutOfStock;
  };

  memoizedGetOosStatus = (item, level) => (memoize(() => this.getOutOfStockStatus(item, level), () => [`${item.value}_${level}`]))();

  getExistingSelectedChildVariantIndex = (newParentValue, parentLevel) => {
    const {
      selectedMultiVariantSecondary,
      selectedMultiVariantTertiary,
    } = this.state;
    const { primary, secondary, tertiary } = VariantPropertyListIndex;
    let index = 0;
    let existingSelectedChildValue = '';
    try {
      switch (parentLevel) {
        case primary:
          existingSelectedChildValue = selectedMultiVariantSecondary.value;
          break;
        case secondary:
          existingSelectedChildValue = selectedMultiVariantTertiary.value;
          break;
        default:
      }
      index = Object.keys(this.variantsOosConfig[newParentValue]).indexOf(existingSelectedChildValue);
    } catch (error) {
    }
    return index > 0 ? index - 1 : 0;
  }

  setSelectedMultiVariantTertiary = (item) => {
    this.setState({
      selectedMultiVariantTertiary: item,
    });
  };

  setSelectedMultiVariantSecondary = (item) => {
    this.setState({
      selectedMultiVariantSecondary: item,
    });
  };

  setSelectedMultiVariantPrimary = (item) => {
    this.setState({
      selectedMultiVariantPrimary: item,
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  discountConditionFn = (props) =>
    parseFloat(props.sp) == parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  VARIANT_TEXT = {
    RECOMMENDED: 'Recommended for you',
    PREMIUM: 'Premium/New Arrivals',
    SPECIAL_PRICE: 'Special Price',
    MORE: 'More Variants',
    OUT_OF_STOCK: 'Out of stock',
  };

  setDefaultVariant = (multi_level_variant_attributes) => {
    const { variants_details: { principal_sku_id: preSelectedSkuId = '' } = {} } = this.itemData;
    if (VariantUtility.checkIfMultiVariant(
      multi_level_variant_attributes,
    )) {
      let primaryVariantToBeSet =
        VariantUtility.getParentVariantForChildVariantId(
          preSelectedSkuId,
          multi_level_variant_attributes,
        );
      // in case empty first variant should be selected.
      if (isBlank(primaryVariantToBeSet)) {
        primaryVariantToBeSet = multi_level_variant_attributes[0];
      }

      const {
        multi_level_variant_attributes: primaryMultiVariantAttributes = [],
      } = primaryVariantToBeSet;
      let secondaryVariantToBeSet;
      if (isPresent(primaryMultiVariantAttributes[0].variant)) {
        secondaryVariantToBeSet = primaryMultiVariantAttributes?.filter(
          (secondaryVariant) =>
            secondaryVariant?.variant?.sku_id === preSelectedSkuId,
        )?.[0];
      } else {
        secondaryVariantToBeSet =
          VariantUtility.getParentVariantForChildVariantId(
            preSelectedSkuId,
            primaryMultiVariantAttributes,
          );
      }
      // in case empty first variant should be selected.
      if (isBlank(secondaryVariantToBeSet)) {
        secondaryVariantToBeSet = primaryMultiVariantAttributes[0];
      }
      this.setSelectedMultiVariantPrimary(primaryVariantToBeSet);
      this.setSelectedMultiVariantSecondary(secondaryVariantToBeSet);
      let selectedVariantData = secondaryVariantToBeSet?.variant || {};
      let selectedVariantSkuId = secondaryVariantToBeSet?.variant?.sku_id;

      const {
        multi_level_variant_attributes: secondaryMultiVariantAttributes = [],
      } = secondaryVariantToBeSet;

      if (
        isPresent(secondaryMultiVariantAttributes) &&
        secondaryMultiVariantAttributes.length > 0
      ) {
        let tertiaryVariantToBeSet = secondaryMultiVariantAttributes?.filter(
          (tertiaryVariant) =>
            tertiaryVariant?.variant?.sku_id === preSelectedSkuId,
        )?.[0];
        // in case empty first variant should be selected.
        if (isBlank(tertiaryVariantToBeSet)) {
          tertiaryVariantToBeSet = secondaryMultiVariantAttributes[0];
        }

        this.setSelectedMultiVariantTertiary(tertiaryVariantToBeSet);
        selectedVariantData = tertiaryVariantToBeSet?.variant || {};
      }
      this.updateSelectedVariant(selectedVariantData);
    } else {
      const selectedVariantItem =
        multi_level_variant_attributes.filter(
          (e) => e?.variant?.sku_id === preSelectedSkuId,
        )?.[0]?.variant || multi_level_variant_attributes?.[0]?.variant;

      this.updateSelectedVariant(selectedVariantItem);
    }
  }

  hitApiCallback = (success, responseData) => {
    if (success) {
      const { multi_level_variant_attributes: multiVariantAttributes = [], facialAnalysis: { my_attributes_values = [] } = {} } = responseData;
      this.setState({
        variantAttributes: responseData.variant_attributes,
        multiVariantAttributes,
      });
      this.variantsOosConfig = getMultiVariantOosConfig(multiVariantAttributes) || {};
      this.setDefaultVariant(responseData?.multi_level_variant_attributes);
      if (
        Utility.isBlank(responseData.multi_level_variant_attributes) ||
        responseData.multi_level_variant_attributes.length === 0
      ) {
        //fire variant error
        AnalyticsUtility.fireVariantErrorAnalytics(
          this.itemData.id,
          this.itemData.name,
        );
      }
      this.checkIfOnlyOneVariantIsAvailable(responseData.variant_attributes);
      const meta = {
        [EventParameterKey.PRODUCT_ID]: this.itemData?.id,
        [EventParameterKey.PRODUCT_NAME]: this.itemData?.name,
        [EventParameterKey.VARIANT_COUNT]:
          Utility.isPresent(multiVariantAttributes) &&
          multiVariantAttributes.length > 0
            ? multiVariantAttributes?.length
            : 0,
        [EventParameterKey.BRAND_ID]: this.itemData?.brand?.id,
        [EventParameterKey.BRAND_NAME]: this.itemData?.brand?.name,
      };
      const analyticsMeta = {
        [EventParameterKey.PRODUCT_ID]: this.itemData.id,
        [EventParameterKey.PRODUCT_NAME]: this.itemData.name,
        [EventParameterKey.BRAND_ID]: this.itemData?.brand?.id,
        [EventParameterKey.BRAND_NAME]: this.itemData?.brand?.name,
        [EventParameterKey.PRODUCT_LISTING_PRICE]: this.itemData.mrp,
        [EventParameterKey.PRODUCT_SELLING_PRICE]: this.itemData.sp,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: !!this.itemData?.gwp
          ? 'gwp'
          : this.itemData.stocked_status,
        [EventParameterKey.RATING]: this.itemData?.rating,
        [EventParameterKey.POSITIVE_INGREDIENTS]: Utility.getIngredientsCount(
          this.itemData?.star_ingredients,
          my_attributes_values,
        )?.goodCount,
        [EventParameterKey.NEGATIVE_INGREDIENTS]: Utility.getIngredientsCount(
          this.itemData?.star_ingredients,
          my_attributes_values,
        )?.badCount,
      };

      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_VARIANT_VIEW,
        meta,
      );
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_VIEW,
        analyticsMeta,
      );
    }
  };

  hitApis = () => {
    const {
      getVariantsForProduct,
      getRecommendedVariants,
      route: { params: { itemData, offer_id = '' } = {} } = {},
    } = this.props;
    const { slug = '' } = this.itemData;
    AnalyticsUtilty.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.VARIANTS,
        ...Utility.setRecordTimeEventMeta(itemData, 'product_variant'),
      },
      this.hitApiCallback,
      getVariantsForProduct,
      slug.replace('.json', ''),
      offer_id,
    );
    getRecommendedVariants(this.itemData.id, (success, responseData) => {
      if (success) {
        this.setState({
          recommendedVariants: responseData,
        });
      }
    });
  };

  updateSelectedVariant = (item) => {
    const {
      route: {
        params: { updateSelectedVariantForReview = () => {} } = {},
      } = {},
    } = this.props;
    if (!item) return;
    let imageArray = item?.images || [];
    if (Utility.isAndroid()) {
      imageArray = Utility.getWebpImages(imageArray);
    }
    this.setState({
      selectedVariant: item,
      productImages: imageArray,
    });
    updateSelectedVariantForReview(item);
  };

  checkIfOnlyOneVariantIsAvailable = (variantAttribute = []) => {
    if (Utility.isBlank(variantAttribute) && variantAttribute.length === 0) {
      return;
    }
    let availableVariant = {};
    let inStockCount = 0;
    const allowedVariantValues = variantAttribute[0].allowed_values;s
    if (allowedVariantValues.length === 0) {
      return;
    }
    allowedVariantValues.forEach((variant) => {
      if (!variant.outOfStock) {
        inStockCount += 1;
        availableVariant = variant;
      }
    });
    if (inStockCount === 1) {
      setTimeout(() => {
        this.updateSelectedVariant(availableVariant);
      }, 200);
    }
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  PricingComponent = (props) => {
    const { mrp, sp, discount, showPrice } = props;
    if (!showPrice) {
      return null;
    }
    const styles = ProductInfoStyles;
    return (
      <View style={styles.priceContainer}>
        <Text style={styles.spStyle}>{`${getPriceText(sp)} `}</Text>
        <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
          {getPriceText(mrp)}
        </this.conditionalPriceText>
        <this.conditionalDiscountText
          style={styles.discountRateStyle}
          discount={discount}
        >
          {`(${discount})`}
        </this.conditionalDiscountText>
      </View>
    );
  };

  checkIfShadeVariantIsSelected = (item) => {
    const { selectedVariant, selectedMultiVariantPrimary } = this.state;
    if (Utility.isPresent(selectedMultiVariantPrimary)) {
      return selectedMultiVariantPrimary?.value === item?.value;
    }
    if (Utility.isPresent(selectedVariant)) {
      return selectedVariant?.id === item?.variant?.id;
    }
    return false;
  };

  handleVariantItemPress = (item = {}, parentValue) => {
    const { product_category: productCategory = {} } = this.itemData;
    const { navigation } = this.props;
    const changeProductOnPrimaryVariantSelect = AppConfig.getBooleanValue(
      Config.CHANGE_PRODUCT_ON_PRIMARY_VARIANT_SELECT,
    );
    if (parentValue) {
      this.secondaryVariantSelected(item);
    } else {
      const { multi_level_variant_attributes = [] } = item;
      if (
        changeProductOnPrimaryVariantSelect &&
        Utility.isPresent(multi_level_variant_attributes)
      ) {
        const firstVariant = multi_level_variant_attributes[0].variant;
        this.updateSelectedVariant(firstVariant); // updating top level primary variant
        this.secondaryVariantSelected(multi_level_variant_attributes[0]); // updating secondary variant
      }
      this.primaryVariantSelected(item);
    }
    const { variant = {} } = item;
    const { sku = {} } = variant;
    const { id: skuId, needs_customization: needsCustomization = false } = sku;
    if (CUSTOMIZED_SKU_FORM_PAGE && needsCustomization && skuId) {
      navigation.navigate(CUSTOMIZED_SKU_FORM_PAGE, { item, productCategory });
      setTimeout(this.goBack, 0);
    }
  };

  sizeVariantItem = ({ item }) => {
    const { selectedVariant } = this.state;
    if (selectedVariant === '' && item.default !== undefined) {
      this.updateSelectedVariant(item);
    }
    const selectedContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedSizeContainerSelected
      : ProductDetailStyles.sizeContainerSelected;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedSizeContainer
      : ProductDetailStyles.sizeContainer;
    const containerStyle = this.checkIfShadeVariantIsSelected(item)
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const isDisabled = this.memoizedGetOosStatus(item, VariantPropertyListIndex.primary);
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <View style={ProductDetailStyles.centerAlign}>
        <TouchableOpacity
          onPress={_.memoize(
            () => this.handleVariantItemPress(item),
            () => [item.value ?? item.id],
          )}
          style={containerStyle}
        >
          <Text style={textStyle}>{item?.value || item.display_name}</Text>
          {isDisabled && (
            <View style={ProductDetailStyles.disabledSizePillLine} />
          )}
        </TouchableOpacity>
        <VariantQuantityText item={item} />
      </View>
    );
  };

  primaryKeyExtractor = (item) => `_${item.value}`;

  secondaryKeyExtractor = (item) => `#${item.value}`;

  tertiaryKeyExtractor = (item) => `&${item.value}`;

  productSizeAttribute = (props) => {
    const {
      selectedMultiVariantPrimary,
      selectedVariant,
      selectedMultiVariantSecondary,
      multiVariantAttributes,
      selectedMultiVariantTertiary,
    } = this.state;
    if (Utility.isBlank(multiVariantAttributes)) {
      return null;
    }

    const {
      variant_attributes: selectedVariantAttributes,
      multi_level_variant_attributes: selectedMultiVariantAttributes,
    } = selectedMultiVariantPrimary || {};

    const {
      variant_attributes: selectedVariantAttributesSecondary,
      multi_level_variant_attributes: selectedMultiVariantAttributesSecondary,
    } = selectedMultiVariantSecondary || {};

    const flatListContentContainerStyle = DisableVarianListScrollability
      ? ProductDetailStyles.flexWrappedContentContainerStyle
      : {};

    let imageProps1 = {};
    let imageProps2 = {};
    let imageProps3 = {};


    if (multiVariantAttributes[0].type === 'image') {
      imageProps1 = this.getImageListProps(VariantPropertyListIndex.primary);
    }

    if (selectedMultiVariantAttributes && selectedMultiVariantAttributes[0].type === 'image') {
      imageProps2 = this.getImageListProps(VariantPropertyListIndex.secondary);
    }

    if (selectedMultiVariantAttributesSecondary && selectedMultiVariantAttributesSecondary[0].type === 'image') {
      imageProps3 = this.getImageListProps(VariantPropertyListIndex.tertiary);
    }

    return (
      <ScrollView
        style={[isDesktop() && ProductInfoStyles.variantListContainerStyle]}
        scrollEnabled={isDesktop()}
      >
        <View style={ProductDetailStyles.productSizeVariantContainer}>
          <Text style={ProductInfoStyles.shadeTextWithLeftMargin}>
            {multiVariantAttributes?.[0]?.name}:{' '}
            <Text style={ProductInfoStyles.selectedShadeText}>
              {selectedMultiVariantPrimary?.value ??
                selectedVariant?.display_name}
            </Text>
          </Text>
          <FlatList
            key={'_'}
            data={multiVariantAttributes}
            horizontal={!DisableVarianListScrollability}
            renderItem={this.sizeVariantItem}
            keyExtractor={this.primaryKeyExtractor}
            showsHorizontalScrollIndicator={false}
            style={ProductDetailStyles.flatListItemStyle}
            contentContainerStyle={flatListContentContainerStyle}
            extraData={{
              ...this.state.selectedVariant,
              ...selectedMultiVariantPrimary,
            }}
            {...imageProps1}
          />
          {(Utility.isPresent(selectedVariantAttributes) ||
            Utility.isPresent(selectedMultiVariantAttributes)) && (
            <Text style={ProductInfoStyles.shadeTextWithMargin}>
              {selectedMultiVariantAttributes?.[0]?.name ??
                selectedVariantAttributes?.[0]?.name}{' '}
              :{' '}
              <Text style={ProductInfoStyles.selectedShadeText}>
                {selectedMultiVariantSecondary?.value}
              </Text>
            </Text>
          )}
          <FlatList
            data={selectedMultiVariantAttributes ?? selectedVariantAttributes}
            horizontal={!DisableVarianListScrollability}
            contentContainerStyle={flatListContentContainerStyle}
            renderItem={this.sizeVariantsPill}
            keyExtractor={this.secondaryKeyExtractor}
            style={ProductDetailStyles.secondaryVariantFlatlistStyle}
            extraData={{
              ...selectedMultiVariantPrimary,
              ...selectedMultiVariantSecondary,
            }}
            {...imageProps2}
          />

          {Utility.isPresent(selectedMultiVariantAttributesSecondary) && (
            <Text style={ProductInfoStyles.shadeTextWithMargin}>
              {selectedMultiVariantAttributesSecondary?.[0]?.name ??
                selectedVariantAttributes?.[0]?.name}{' '}
              :{' '}
              <Text style={ProductInfoStyles.selectedShadeText}>
                {selectedMultiVariantTertiary?.value}
              </Text>
            </Text>
          )}
          <FlatList
            key={'&'}
            data={selectedMultiVariantAttributesSecondary}
            horizontal={!DisableVarianListScrollability}
            contentContainerStyle={flatListContentContainerStyle}
            renderItem={this.tertiaryVariantsPill}
            keyExtractor={this.tertiaryKeyExtractor}
            style={ProductDetailStyles.secondaryVariantFlatlistStyle}
            extraData={{
              ...selectedMultiVariantPrimary,
              ...selectedMultiVariantSecondary,
            }}
            {...imageProps3}
          />
        </View>
      </ScrollView>
    );
  };

  sizeListKeyExtractor = (item) => `${item.value}`;

  secondaryVariantSelected = (item) => {
    const { variant = {}, multi_level_variant_attributes: attributes = [], value = '' } = item || {};
    this.setSelectedMultiVariantSecondary(item);
    if (isPresent(attributes) && attributes.length > 0) {
      const existingSelectedTertiaryVariantIndex = 
      this.getExistingSelectedChildVariantIndex(value, VariantPropertyListIndex.secondary);
      this.tertiaryVariantSelected(attributes[existingSelectedTertiaryVariantIndex]);
      return;
    }
    this.updateSelectedVariant(variant);
  };

  tertiaryVariantSelected = (item) => {
    this.setSelectedMultiVariantTertiary(item);
    this.updateSelectedVariant(item?.variant);
  };

  tertiaryVariantsPill = ({ item, index }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantTertiary } = this.state;
    const isDisabled = this.memoizedGetOosStatus(item, VariantPropertyListIndex.tertiary);
    const isSelected =
      selectedMultiVariantTertiary?.value === item?.value && !isDisabled;
    const selectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <View style={ProductDetailStyles.centerAlign}>
        <TouchableOpacity
          style={borderStyle}
          onPress={_.memoize(
            () => this.tertiaryVariantSelected(item),
            () => [item.value ?? item.id],
          )}
        >
          <Text style={textStyle}>{item?.value}</Text>
          {isDisabled && (
            <View style={ProductDetailStyles.disabledSizePillLine} />
          )}
        </TouchableOpacity>
        <VariantQuantityText item={item} />
      </View>
    );
  };

  sizeVariantsPill = ({ item, index }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantSecondary, selectedMultiVariantPrimary } =
      this.state;
    const { value: parentVariantValue } = selectedMultiVariantPrimary;
    const isDisabled = this.memoizedGetOosStatus(item, VariantPropertyListIndex.secondary);
    if (
      isPresent(selectedMultiVariantSecondary.multi_level_variant_attributes) &&
      selectedMultiVariantSecondary.multi_level_variant_attributes.length > 0
    ) {
      isDisabled = false;
    }

    const isSelected =
      selectedMultiVariantSecondary?.value === item?.value && !isDisabled;
    const selectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = DisableVarianListScrollability
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <View style={ProductDetailStyles.centerAlign}>
        <TouchableOpacity
          style={borderStyle}
          onPress={_.memoize(
            () => this.handleVariantItemPress(item, parentVariantValue),
            () => [item.value ?? item.id, parentVariantValue],
          )}
        >
          <Text style={textStyle}>{item?.value}</Text>
          {isDisabled && (
            <View style={ProductDetailStyles.disabledSizePillLine} />
          )}
        </TouchableOpacity>
        <VariantQuantityText item={item} />
      </View>
    );
  };

  conditionalScrollView = withEither(isDesktop, View)(ScrollView);

  getImageBackgroundStyle = (colorCode) => memoize(
    () => ({
      ...ProductDetailStyles.productVariantWhiteImageBackground,
      backgroundColor: colorCode,
    }),
    () => [colorCode],
  )()

  getImageBackgroundContainerStyle = (borderColor) => memoize(
    () => [ProductDetailStyles.ImageBackgroundContainer, { borderColor }],
    () => [borderColor],
  )();

  getImageSource = (uri) => memoize(
    () => ({
      uri,
    }),
    () => [uri],
  )();

  shadeSwatch = ({ item = {}, propertyListIndex }) => {
    const {
      selectedVariant,
      showSelectedVariantIdFromSlug,
      disableSelectedVariantFromSlug,
      selectedMultiVariantPrimary = {},
      selectedMultiVariantSecondary,
      selectedMultiVariantTertiary,
    } = this.state;
    const { id, value, color_code, image_url = '' } = item;
    const { value: tertiaryParentVariantValue } = selectedMultiVariantSecondary || {};
    const { value: secondaryVariantParentValue } = selectedMultiVariantPrimary || {};
    const isDisabled = this.memoizedGetOosStatus(item, propertyListIndex);
    if (
      selectedVariant === '' &&
      item?.default !== undefined &&
      AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_SHOW_DEFAULT_SELECTED_VARIANT,
      )
    ) {
      this.updateSelectedVariant(item);
    }

    if (
      isPresent(showSelectedVariantIdFromSlug) &&
      showSelectedVariantIdFromSlug === id
    ) {
      this.updateSelectedVariant(item);
      disableSelectedVariantFromSlug();
    }
    let onPress;
    let isSelected;
    const { secondary, tertiary } = VariantPropertyListIndex;
    switch (propertyListIndex) {
      case secondary:
        onPress = memoize(
          () => this.handleVariantItemPress(item, secondaryVariantParentValue),
          () => [value ?? id, secondaryVariantParentValue],
        );
        isSelected = selectedMultiVariantSecondary?.value === value;
        break;
      case tertiary:
        onPress = memoize(
          () => this.handleVariantItemPress(item, tertiaryParentVariantValue),
          () => [value ?? id, tertiaryParentVariantValue],
        );
        isSelected = selectedMultiVariantTertiary?.value === value;
        break;
      default:
        onPress = memoize(
          () => this.handleVariantItemPress(item),
          () => [value ?? id],
        );
        isSelected = selectedMultiVariantPrimary?.value === item?.value;
    }
    const borderColor = isSelected ? colors.black : colors.lightGrey;
    const imageBackgroundContainerStyle = this.getImageBackgroundContainerStyle(borderColor);
    const imageBackgroundStyle = this.getImageBackgroundStyle(isPresent(color_code) ? color_code : colors.grey);
    const imageSource = this.getImageSource(image_url);

    return (
      <View style={ProductDetailStyles.centerAlign}>
        <DebouncedTouchableOpacity
          onPress={onPress}
          style={imageBackgroundContainerStyle}
        >
          <ImageBackground
            source={imageSource}
            style={imageBackgroundStyle}
          />
          {isSelected ? (
            <View style={ProductDetailStyles.whiteTickContainer}>
              <Image
                style={ProductDetailStyles.productVariantTickImage}
                source={Images.whiteTick}
              />
            </View>
          ) : null}
          {isDisabled && (
            <View style={ProductDetailStyles.oosContainer} />
          )}
        </DebouncedTouchableOpacity>
        <View style={ProductDetailStyles.inventoryTextContainer}>
          <VariantQuantityText item={item} />
        </View>
      </View>

    );
  };

  getImageListProps = (propertyListIndex) => {
    return {
      horizontal: false,
      numColumns,
      renderItem: ({ item, index }) => this.shadeSwatch({item, index, propertyListIndex}),
      style: ProductDetailStyles.imageVariantFlatlistStyle,
      contentContainerStyle: ProductDetailStyles.imageVariantFlatlistContentStyle,
      key: propertyListIndex,
    };
  }

  variantScreen = (props) => {
    const { recommendedVariants } = props;
    const {
      productImages,
      quantity,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
    } = this.state;
    const { previousScreen, onItemAddToCartFromCollab, campaignId } =
      this.props;
    const { sp, webp_images } = this.itemData;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    const { variantAttributes, selectedVariant, multiVariantAttributes } =
      this.state;
    if (Utility.isBlank(multiVariantAttributes)) {
      return (
        <View style={ProductInfoStyles.variantHeaderContainer}>
          <VariantImagesWithHeader
            itemData={this.itemData}
            imageArray={this.itemData.images}
            selectedVariantName={selectedVariant.name}
            selectedVariantImage={selectedVariant.image_url}
            selectedVariant={selectedVariant}
          />
        </View>
      );
    }
    const styles = ProductInfoStyles;
    const imageCarouselContainer = [isDesktop() && { flex: 1 }];
    const sectionListContainer = [
      ProductInfoStyles.variantHeaderContainer,
      isDesktop() && {
        flexDirection: 'row',
        height: '100%',
      },
    ];
    if (this.itemData.variant_type !== PRODUCT.ATTRIBUTE_TYPE.IMAGE) {
      // size variant
      // TODO: use smaller modal height got size variant, get variant type initially from backend

      const { images: selectedVariantImages, show_product_images = false } = selectedVariant || {};

      let productImages = selectedVariantImages ?? this.itemData.images;
      if (show_product_images) {
        productImages = this.itemData.images;
      }
      return (
        <ScrollView
          style={[
            styles.scrollViewStyle,
            isDesktop() && styles.scrollViewBottomPadding,
          ]}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollViewBottomPadding}
          scrollEnabled={!isDesktop()}
        >
          <View style={sectionListContainer}>
            <View style={imageCarouselContainer}>
              <VariantImagesWithHeader
                itemData={this.itemData}
                imageArray={productImages}
                selectedVariantName={selectedVariant.name}
                selectedVariantImage={selectedVariant.image_url}
                selectedVariant={selectedVariant}
              />
              {(this.itemData.stocked_status === 'in_stock' ||
                this.itemData.stocked_status === 'stocked_out') && (
                <View style={ProductDetailStyles.ratingsMarginTop}>
                  <ProductRatingAndMoreInfo
                    rating={this.itemData?.rating}
                    id={this.itemData?.id}
                    sku_id={this.itemData?.sku_id}
                  />
                </View>
              )}
            </View>
            <this.productSizeAttribute
              variantAttributes={variantAttributes}
              multiVariantAttributes={multiVariantAttributes}
            />
          </View>
        </ScrollView>
      );
    }
    const allowedVariantValues = _.filter(
      variantAttributes?.[0].allowed_values,
      (item) => item.outOfStock !== true,
    );
    if (!Utility.isBlank(recommendedVariants)) {
      this.recommendedVariants = _.filter(allowedVariantValues, (item) =>
        recommendedVariants.includes(item.id),
      );
    }
    const imageArray =
      this.itemData.images.length === 0 ? this.itemData.images : productImages;

    return (
      <this.conditionalScrollView
        style={[
          styles.scrollViewStyle,
          isDesktop() && styles.scrollViewBottomPadding,
        ]}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewBottomPadding}
      >
        <VariantSectionList
          itemData={this.itemData}
          recommendedVariants={this.recommendedVariants}
          selectedVariantName={selectedVariant.display_name}
          selectedVariantImage={selectedVariant.image_url}
          selectedVariant={selectedVariant}
          updateSelectedVariant={this.updateSelectedVariant}
          imageArray={imageArray}
          variantAttributes={variantAttributes}
          previousScreen={previousScreen}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          campaignId={campaignId}
          quantity={quantity}
          setVariantQuantity={this.setVariantQuantity}
          selectedMultiVariantPrimary={selectedMultiVariantPrimary}
          selectedMultiVariantSecondary={selectedMultiVariantSecondary}
          setSelectedMultiVariantSecondary={
            this.setSelectedMultiVariantSecondary
          }
          setSelectedMultiVariantPrimary={this.setSelectedMultiVariantPrimary}
          multiVariantAttributes={multiVariantAttributes}
          handleVariantItemPress={this.handleVariantItemPress}
        />
      </this.conditionalScrollView>
    );
  };

  primaryVariantSelected = (item) => {
    const { variant, multi_level_variant_attributes: attributes, value = '' } = item;
    this.setSelectedMultiVariantPrimary(item);
    if (isPresent(attributes)) {
      const existingSelectedSecondaryVariantIndex =
      this.getExistingSelectedChildVariantIndex(value, VariantPropertyListIndex.primary);
      this.secondaryVariantSelected(attributes[existingSelectedSecondaryVariantIndex]);
    } else {
      this.updateSelectedVariant(variant ?? item);
    }
  };

  pricingInfo = (props) => {
    const {
      mrp,
      sp,
      discount,
      itemData,
      skuToAdd,
      selectedVariant,
      outOfStock,
      priority,
      additional_discount,
    } = props;

    const {
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
      multiVariantAttributes,
    } = this.state;

    if (Utility.isBlank(selectedVariant)) {
      return null;
    }

    const {
      listName,
      listData = {},
      listIndex,
      layout,
      campaignId,
      onItemAddToCartFromCollab,
      route: {
        params: {
          listId = '',
        } = {}
      } = {},
      force_apply_coupon,
    } = this.props;
    const unStockedVariant =
      selectedVariant && selectedVariant.stocked_status === 'unstocked'
        ? true
        : false;
    return (
      <View style={ProductDetailStyles.pricingAndCartContainerVariantModal}>
        <View style={{ flexDirection: 'row' }}>
          <View style={ProductDetailStyles.pricingContainer}>
            <View style={{ flexDirection: 'row' }}>
              <this.conditionalPriceText
                style={ProductDetailStyles.mrpStyle}
                sp={sp}
                mrp={mrp}
              >
                {getPriceText(mrp)}
              </this.conditionalPriceText>

              {Utility.isPresent(discount) && (
                <Text
                  style={ProductDetailStyles.discountRateStyle}
                  discount={discount}
                >
                  {`${discount}`}
                </Text>
              )}
            </View>
            <Text style={ProductDetailStyles.spStyle}>{getPriceText(sp)}</Text>
          </View>
          {outOfStock || priority === 'Discontinued' ? (
            <RequestProductButton
              skuId={skuToAdd}
              showToast={this.showToast}
              priority={priority}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
              unstocked={unStockedVariant}
              variantModal
            />
          ) : (
            <IncrementDecrement
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
              skuId={skuToAdd}
              previousScreen={SCREEN_CONSTANTS.VARIANTS}
              hasVariants={itemData.has_variants}
              selectedVariant={selectedVariant}
              selectedVariantName={selectedVariant.display_name}
              showCart={this.goBack}
              showToast={this.showToast}
              itemData={itemData}
              listId={listId}
              listName={listName}
              listIndex={listIndex}
              layout={layout}
              campaignId={campaignId}
              listData={listData}
              onItemAddToCartFromCollab={onItemAddToCartFromCollab}
              variantModal
              cancelpressed={this.goBack}
              refreshOfferStrip={this.props.refreshOfferStrip}
              refreshOffersDetailsPageDiscountStrip={
                this.props.refreshOffersDetailsPageDiscountStrip
              }
              force_apply_coupon={force_apply_coupon}
              selectedMultiVariantPrimary={selectedMultiVariantPrimary}
              selectedMultiVariantSecondary={selectedMultiVariantSecondary}
              multiVariantAttributes={multiVariantAttributes}
            />
          )}
        </View>
      </View>
    );
  };

  chooseVariant = () => {
    return (
      <FoxyShadowButton
        width={Utility.getScreenWidth() - 34}
        title='Select'
        onPress={this.goBack}
        backgroundColor={colors.primaryActionBackgroundColor}
        style={ProductInfoStyles.chooseVariantButton}
        Loading={false}
        firstColor={colors.linerGradientGreenFirst}
        secondColor={colors.linerGradientGreenSecond}
      />
    );
  };

  setVariantQuantity = (quantity) => {
    this.setState({
      quantity,
    });
  };

  crossButton = () => {
    return (
      <TouchableOpacity
        hitSlop={Utility.getHitSlop()}
        onPress={this.goBack}
        style={ProductDetailStyles.crossButtonContainer}
      >
        <Image style={ProductDetailStyles.crossButton} source={images.cross} />
      </TouchableOpacity>
    );
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const {
      route: {
        params: {
          isVisible,
          previousScreen,
          selectOnly = false,
          comboOffer = false,
        } = {},
      } = {},
    } = this.props;
    const { recommendedVariants } = this.state;
    const styles = ProductInfoStyles;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    const { selectedVariant } = this.state;
    let {
      mrp,
      discount,
      outOfStock,
      priority,
      final_sp: sp,
      offer_code,
      offer_value,
      additional_discount,
    } = this.itemData;

    let skuToAdd = this.itemData.sku_id;

    if (Utility.isPresent(discount)) {
      discount = `(${discount})`;
    }

    if (!Utility.isBlank(selectedVariant)) {
      mrp = selectedVariant.mrp;
      sp = selectedVariant.final_sp;

      skuToAdd = selectedVariant.sku_id;
      outOfStock = selectedVariant.outOfStock;
      offer_code = selectedVariant.offer_code;
      offer_value = selectedVariant.offer_value;
      discount = selectedVariant.discount;
      if (!Utility.isBlank(selectedVariant.additional_discount)) {
        discount = selectedVariant.additional_discount;
      }
    }

    // const imageArray = productImages.length === 0 ? images : productImages;
    const modalHeaderTitle =
      this.itemData.variant_type === PRODUCT.ATTRIBUTE_TYPE.IMAGE
        ? 'Select a shade'
        : 'Select a size';
    return (
      <>
        <TouchableWithoutFeedback onPress={this.goBack}>
          <View style={styles.touchableContainer} />
        </TouchableWithoutFeedback>
        <View style={styles.variantModal} pointerEvents='box-none'>
          <View style={this.variantModalContainerStyle}>
            <View style={styles.variantModalHeader}>
              <Text style={styles.variantModalHeaderTextWithMargin}>
                {modalHeaderTitle}
              </Text>
            </View>
            <this.variantScreen recommendedVariants={recommendedVariants} />
            {!selectOnly ? (
              <this.pricingInfo
                mrp={mrp}
                sp={sp}
                discount={discount}
                additional_discount={additional_discount}
                itemData={this.itemData}
                skuToAdd={skuToAdd}
                selectedVariant={selectedVariant}
                outOfStock={outOfStock}
                priority={priority}
                comboOffer={comboOffer}
              />
            ) : (
              <this.chooseVariant />
            )}
            <this.crossButton />
          </View>
          <Toast ref={this.toastRef} />
        </View>
      </>
    );
  }
}

VariantModal.propTypes = {
  brand: PropTypes.shape({
    name: PropTypes.string,
    images: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  }),
  image_url: PropTypes.string,
};
VariantModal.defaultProps = {
  brand: {
    name: '',
    images: {
      thumbnail: '',
    },
  },
  image_url: '',
};

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { getVariantsForProduct, getRecommendedVariants },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(VariantModal),
);
