import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import { getAndyWarholImages } from '../../../actions/ActionTypes';
import Collection from '../../../containers/collection/Collection';
import styles from './styles';
import LAYOUT_CONFIG from '../../../config/LayoutConstants/LayoutConfig';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop } from '../../../utils/BooleanUtility';
import { getListSource } from '../../../utils/PerfUtility';

class PersonalisedGrid extends Component {
  static getComponentHeight(item) {
    return 481.2;
  }

  constructor(props) {
    super(props);
    this.displayCount = lodash.max([
      LAYOUT_CONFIG.minListCount,
      this.props.displayCount || 0,
    ]);
    this.itemData = this.props.item.filter((item) => !!item);
    this.column = 2;
  }

  componentDidMount() {
    const { getAndyWarholImages } = this.props;
    getAndyWarholImages();
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      size,
      id,
      navigation,
      toggleCartVisibility,
      listName,
      listIndex,
      listData,
      listContent,
      previousScreen = '',
    } = this.props;
    if (item === undefined) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <Collection
          id={item.id}
          layout={LAYOUT.GRID}
          itemData={item}
          listName={listName}
          listContent={listContent}
          type={item.type}
          size={size}
          listIndex={listIndex}
          navigation={navigation}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={index}
          toggleCartVisibility={toggleCartVisibility}
          previousScreen='personalised_grid'
          listData={listData}
          personalisedGrid
        />
      </ErrorBoundaryComponent>
    );
  };

  keyExtractor = (item) => `${item.schema}_${item.id}_grid`;

  listKey = () => (item, index) => `Grid${index.toString()}_${item.id}`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  render() {
    const { displayCount } = this.props;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    this.displayCount = lodash.max([
      LAYOUT_CONFIG.minGridCount,
      displayCount || 0,
    ]);
    this.itemData = this.props.item.filter((item) => !!item);
    if (this.displayCount > this.itemData.length) {
      this.displayCount = this.itemData.length;
    }
    return (
      <View style={styles.container}>
        <FlatList
          data={this.itemData.slice(0, this.displayCount)}
          numColumns={isDesktop() ? this.column * 2 : this.column}
          scrollEnabled
          keyExtractor={this.keyExtractor}
          listKey={this.listKey}
          renderItem={this.renderItem}
          style={styles.flatlistStyle}
        />
      </View>
    );
  }
}

PersonalisedGrid.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
  content: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAndyWarholImages,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(PersonalisedGrid);
