import React, { PureComponent } from 'react';
import { View } from 'react-native';
import CountDown from '../../helpers/Counter';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { COUNTDOWN, LAYOUT } from '../../config/Constants';
import { ids, styles } from './styles';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';

class TallBanner extends PureComponent {
  static getComponentHeight() {
    return styles.bannerContainer.height + 8;
  }

  constructor(props) {
    super(props);
    const {
      listData: { display },
    } = this.props;
    if (display === LAYOUT.RAIL) {
      this.imageStyles = styles.bannerImage;
      this.containerStyle = styles.bannerContainer;
      this.countDownTimerStyle = styles.countdownTimerContainer;
      this.imageDataSet = { media: ids.bannerImage };
      this.containerDataSet = { media: ids.bannerContainer };
    } else {
      this.imageStyles = styles.pagerImage;
      this.containerStyle = styles.pagerContainer;
      this.countDownTimerStyle = styles.pagerCountdownTimerContainer;
    }
    this.navigationSource = getNavigationSource(this, this.props);
  }

  onPressTallBanner = () => {
    const {
      itemData: { destination, id, type, name, slug = '', external_link },
      previousScreen,
      index,
      listId,
      layout,
      navigation,
      listName,
      listIndex,
      listContent,
      listSlug = '',
    } = this.props;

    const url = Utility.isPresent(external_link) ? external_link : destination;

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      id,
      'rail',
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );

    if (Utility.isBlank(url)) return null;
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      ({ route = '', slug = '', extra = '' }) => {
        navigateToScreen({
          navigation,
          type: 'push',
          screen: route,
          navigationSource: this.navigationSource,
          params: { slug, extra },
        });
      },
    );
  };

  countdownTimerContainer = ({ style, ends_at }) => {
    if (!ends_at) return null;
    return (
      <View style={style}>
        <CountDown
          size={14}
          until={Utility.calculateTimeDifferenceForOffer(ends_at)}
          endsAt={ends_at}
          onFinish={this.onFinish}
          digitStyle={styles.counterDigitStyle}
          digitTxtStyle={styles.counterTextStyle}
          timeLabelStyle={styles.timeLabelStyle}
          separatorStyle={styles.separatorStyle}
          timeToShow={COUNTDOWN.timeToShowHMS}
          showSeparator
        />
      </View>
    );
  };

  render() {
    const {
      itemData: { image_url, ends_at, desktop_image_webp_url: desktopImageUrl = '' },
    } = this.props;

    if (
      Utility.isPresent(ends_at) &&
      Utility.calculateTimeDifferenceForOffer(ends_at) < 0
    ) {
      return null;
    }

    let image = image_url;

    image = isDesktop() && isPresent(desktopImageUrl) ? desktopImageUrl : image;

    return (
      <ScaleAnimate {...this.props} onPress={this.onPressTallBanner}>
        <View style={this.containerStyle} dataSet={this.containerDataSet}>
          <FastImageView source={image} style={this.imageStyles} dataSet={this.imageDataSet} />
          <this.countdownTimerContainer
            ends_at={ends_at}
            style={this.countDownTimerStyle}
          />
        </View>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(TallBanner);
