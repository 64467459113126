import _ from 'lodash';
import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import RoutinesUtility, { isRoutineLive, showActiveIcon } from './RoutinesUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isBlank } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class RoutineDescriptionThinCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
    };
    const {
      itemData: { routine = {} },
    } = props;
    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(routine)
        .then((data) => {
          console.tron.log('routines data thin', data);
          this.setState({ itemData: data });
        });
    } catch (e) {}
    this.debouncedOnPress = _.debounce(this.onPress, 800, {
      leading: true,
      trailing: false,
    });
  }

  onPress = () => {
    const {
      navigation,
      previousScreen = '',
      index = '',
      listIndex,
      listData: {
        name: listName = '',
        id: listId = '',
        slug: listSlug = '',
        display: listDisplay = '',
        content: listContent = '',
      } = {},
      authToken = '',
    } = this.props;
    const {
      itemData: {
        name = '',
        id = '',
        type = '',
        slug = '',
        skuId = '',
        endsAt = '',
        isUserSubscribed = false,
      } = {},
    } = this.state;
    const isLive = isRoutineLive(slug) || false;
    const isExpired = new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );
    console.tron.log(isUserSubscribed,'isUserSubscribed')
    if (isLive || (isExpired && authToken && isUserSubscribed)) {
      this.navigateToRoutineSchedule(isLive);
      return;
    }

    navigation.navigate('PreRoutineDetails', {
      slug,
      sku_id: skuId,
    });
  };

  navigateToRoutineSchedule = (isRoutineActive) => {
    const { navigation } = this.props;
    const { itemData: { slug = '', personalizedSlug = '' } = {} } = this.state;
    navigation.navigate('DuringRoutineDetails', {
      slug,
      isRoutineActive,
      personalizedSlug,
    });
  };

  render() {
    const { itemData = {} } = this.state;

    const {
      name = '',
      description = '',
      artist = {},
      pictureUrls = {},
      slug = '',
      startsAt = '',
      endsAt = '',
    } = itemData;
    if (isBlank(itemData)) return null;
    const { cardImage = '' } = pictureUrls;
    const { name: artistName, imageUrl } = artist;
    const artistImage = Utility.isBlank(imageUrl)
      ? images.routines.artist_placeholder
      : imageUrl;
    const isLive = isRoutineLive(slug);
    const shouldShowActiveIcon = showActiveIcon(startsAt, endsAt);
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedOnPress}>
        <View style={styles.cardContainer}>
          <View style={styles.routineContainer}>
            <FastImageView
              source={getMinifiedImage(
                cardImage,
                styles.routineImage.width,
                styles.routineImage.height,
              )}
              style={styles.routineImage}
            />
            <View
              style={
                shouldShowActiveIcon
                  ? styles.routineCardTextContainerWithLiveText
                  : styles.routineCardTextContainer
              }
            >
              <View style={styles.flexDirectionRow}>
                <Text style={styles.routineCardTitle}>{name}</Text>
                {shouldShowActiveIcon && (
                  <View style={styles.liveContainer}>
                    <Text style={styles.liveText}>LIVE</Text>
                  </View>
                )}
              </View>
              <Text
                numberOfLines={2}
                ellipsizeMode='tail'
                style={styles.routineCardBody}
              >
                {description}
              </Text>
              <View style={styles.flexDirectionRow}>
                <FastImageView
                  source={artistImage}
                  style={styles.artistImage}
                />
                <Text style={styles.routineCardBody}>by {artistName}</Text>
              </View>
            </View>
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default RoutineDescriptionThinCard;

const styles = StyleSheet.create({
  gradientContainer: {
    width: '100%',
    borderRadius: 8,
    alignSelf: 'center',
  },
  container: {
    marginTop: 10,
    backgroundColor: colors.white,
    alignItems: 'flex-start',
  },
  heading: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    paddingHorizontal: 12,
    marginTop: 4,
  },
  subHeading: {
    color: colors.subtitle,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4_5,
    paddingHorizontal: 12,
    paddingTop: 12,
  },
  routineContainer: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: colors.ultraLightGray,
    borderBottomWidth: 1,
    marginVertical: 4,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  routineImage: {
    width: 72,
    height: 72,
    borderRadius: 16,
  },
  routineCardTextContainer: {
    paddingLeft: 12,
    flex: 1,
  },
  routineCardTextContainerWithLiveText: {
    paddingLeft: 12,
    flex: 0.9,
  },
  routineCardBody: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    lineHeight: 18,
  },
  routineCardTitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  artistImage: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: 8,
    marginTop: 4,
    resizeMode: 'cover',
  },
  flexDirectionRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  liveContainer: {
    backgroundColor: colors.routine_card_pink,
    borderRadius: 2,
    paddingHorizontal: 8,
    paddingVertical: 2,
    marginLeft: 6,
  },
  liveText: {
    color: colors.white,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  rightArrow: {
    tintColor: colors.cta.lightBlue,
    height: 12,
    resizeMode: 'contain',
  },
  moreRoutinesContainer: {
    flexDirection: 'row',
    padding: 12,
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  moreRoutinesText: {
    color: colors.cta.lightBlue,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  cardContainer: { width: getScreenWidth(), paddingHorizontal: 12 },
});
