import { APP_ASSET_URL } from '../config/Constants';
import Config from '../libraries/ReactNativeConfig';

/* eslint-disable global-require */
const images = {
  info: require('../assets/images/infoCircleIcon.png'),
  infoBnW: require('../assets/images/info_bnw.png'),
  Recorder: require('../assets/images/Recorder.png'),
  searchFeed: require('../assets/images/searchFeed.png'),
  cross: require('../assets/images/cross.png'),
  black_cross: `${APP_ASSET_URL}/533/frame_115.png`,
  greenCheck: require('../assets/images/greenCheck.png'),
  roundedCross: require('../assets/images/roundedCross.png'),
  grayscaleCross: require('../assets/images/grayscaleCross.png'),
  checkSquare: require('../assets/images/checkSquare.png'),
  squareOutline: require('../assets/images/squareOutline.png'),
  retail_qr: require('../assets/images/retail_qr.png'),
  phone: require('../assets/images/phone.png'),
  foxy_barcode: require('../assets/images/foxy_barcode.png'),
  store_hut: require('../assets/images/store_hut.png'),
  login_modal_image: `${APP_ASSET_URL}/532/login_1.png`,
  check_circle: require('../assets/images/check_circle.png'),
  chevron_green: require('../assets/images/chevron_green.png'),
  phonePe: {
    uri: `${APP_ASSET_URL}/73/phone_pe_2x.png`,
  },
  gpay: { uri: `${APP_ASSET_URL}/66/gpay_2x.png` },
  gpay_bnw: `${APP_ASSET_URL}/79/googlepaybnw_3x.png`,
  bhim: { uri: `${APP_ASSET_URL}/24/bhim.png` },
  paytm: { uri: `${APP_ASSET_URL}/36/paytm.png` },
  sorting: require('../assets/images/sorting.png'),
  product_blur: {
    uri: `${APP_ASSET_URL}/16/product_blur.png`,
  },
  takeSelfie: {
    uri: `${APP_ASSET_URL}/22/takeselfie.png`,
  },
  foxyFooter: {
    uri: `${APP_ASSET_URL}/14/foxyfooter.png`,
  },
  paytmbnw: `${APP_ASSET_URL}/908/paytmbnw.png`,
  paytmUpi: {
    uri: `${APP_ASSET_URL}/64/paytm_upi_2x.png`,
  },
  ratingStar: require('../assets/images/rating-star.png'),
  ratingStarWhite: require('../assets/images/rating-star-white.png'),
  likeComment: require('../assets/images/likeComment.png'),
  photoCapture: {
    uri: `${APP_ASSET_URL}/28/photocapture.png`,
  },
  offer_gift: require('../assets/images/offer_gift.png'),
  offer_percentage: require('../assets/images/offer_percentage.png'),
  offer_absolute: require('../assets/images/offer_percentage.png'),
  scanPhotoCapture: {
    uri: `${APP_ASSET_URL}/56/scanphotocapture.png`,
  },
  selfiePromptImage: {
    uri: `${APP_ASSET_URL}/1/selfieprompt.png`,
  },
  safePayments: {
    uri: `${APP_ASSET_URL}${Config.SAFE_PAYMENT_IMAGE_PATH}`,
  },
  calendar_coloured: {
    uri: `${APP_ASSET_URL}/389/calendar_coloured.png`,
  },
  chevronRight: require('../assets/images/chevronRight.png'),
  chevronLeft: require('../assets/images/chevronLeft.png'),
  chevronUp: require('../assets/images/chevronUp.png'),
  chevronDown: require('../assets/images/chevronDown.png'),
  verifiedPurchase: require('../assets/images/verified-purchase.png'),
  whitePlus: require('../assets/images/plus_white.png'),
  whiteRightArrow: require('../assets/images/whiteRightArrow.png'),
  videoIcon: require('../assets/images/videoIcon.png'),

  listvideoIcon: require('../assets/images/video_play_white.png'),
  likeImage: require('../assets/images/likeButton.png'),
  location: require('../assets/images/location.png'),
  unlikeImage: require('../assets/images/unlikeButton.png'),
  heartIcon: require('../assets/images/unlikeImage.png'),

  unlikeImageWhite: require('../assets/images/unlikeButton_white.png'),
  searchIcon: require('../assets/images/search_icon.png'),
  notificationIcon: require('../assets/images/notification_icon.png'),
  navigationWhiteBackIcon: require('../assets/images/back_icon_white.png'),
  ic_back_product: require('../assets/images/productPageBack.png'),
  ic_bag_product: require('../assets/images/bagProductPage.png'),
  ic_search_product: require('../assets/images/searchProductPageNew.png'),
  search_autosuggest_icon: `${APP_ASSET_URL}/852/searchwithpadding.png`,
  ic_share_product: require('../assets/images/shareProductPage.png'),
  fourofour: {
    uri: `${APP_ASSET_URL}/7/four_o_four.png`,
  },
  alert: require('../assets/images/alert.png'),
  share: require('../assets/images/share_white.png'),
  whiteTick: require('../assets/images/check_white.png'),
  whiteCross: require('../assets/images/white_cross.png'),
  blueStar: require('../assets/images/star_blue.png'),
  defaultStar: {
    uri: `${APP_ASSET_URL}/88/star_default.png`,
  },
  defaultStarGrey: require('../assets/images/defaultGreyStar.png'),
  trendingDown: require('../assets/images/feather_trending-down.png'),
  featherCalendar: require('../assets/images/feather_calendar.png'),

  interestSelected: require('../assets/images/interestSelected.png'),
  productShortBannerImage: require('../assets/images/product_short_banner_logo.png'),
  serviceShortBannerImage: require('../assets/images/service_short_banner_logo.png'),
  verifiedTick: require('../assets/images/verifiedTick.png'),
  failure: require('../assets/images/failure.png'),
  success: require('../assets/images/success.png'),
  goBack: require('../assets/images/goBack.png'),
  recommendation_products: require('../assets/images/recommendation_product.png'),

  takePicture: {
    uri: `${APP_ASSET_URL}/72/takepicture_2x.png`,
  },
  settings: require('../assets/images/settings.png'),

  foxyLiveLogo: {
    uri: `${APP_ASSET_URL}/54/foxy_live_logo.png`,
  },
  instagramBlack: require('../assets/images/instagramBlack.png'),
  twitter: require('../assets/images/twitter.png'),
  publishingSettings: require('../assets/images/publishingSettings.png'),
  edit: require('../assets/images/ic_edit.png'),
  edit_new: require('../assets/images/ic_edit_v2.png'),
  search: require('../assets/images/search.png'),
  smallPause: require('../assets/images/smallPause.png'),
  smallPlay: require('../assets/images/smallPlay.png'),
  delete: require('../assets/images/delete.png'),
  thumbsUpGreen: require('../assets/images/thumbs_up_green.png'),
  thumbsDownRed: require('../assets/images/thumbs_down_red.png'),

  more: { uri: `${APP_ASSET_URL}/86/more_3x.png` },

  popupClose: require('../assets/images/popupClose.png'),
  deliveryPromise: require('../assets/images/delivery-promise.png'),
  narrow_arrow: require('../assets/images/narrow_arrow.png'),
  delete: require('../assets/images/ic_delete.png'),

  trending_up: require('../assets/images/trending-up.png'),
  audience: require('../assets/images/users_content.png'),
  instagram_handle_error: require('../assets/images/insta_handle_error.png'),

  largeBlackCheckBox: require('../assets/images/blackCheckBox.png'),

  uploadProfilePic: require('../assets/images/Artboard.png'),
  retakeProfilePic: require('../assets/images/Retake.png'),
  my_address: require('../assets/images/my_address.png'),
  payment_methods: require('../assets/images/payment_method.png'),
  support: require('../assets/images/support.png'),
  shipments: {
    uri: `${APP_ASSET_URL}/63/tracking_3x.png`,
  },
  office_building: require('../assets/images/office_building.png'),
  home_building: require('../assets/images/home_add.png'),
  other_location: require('../assets/images/other_location.png'),
  retake: require('../assets/images/retake_image.png'),
  empty_state_address: {
    uri: `${APP_ASSET_URL}/77/empty_address_state_3x.png`,
  },
  no_search_result: require('../assets/images/search-problem.png'),
  empty_state_order: {
    uri: `${APP_ASSET_URL}/82/empty_order.png`,
  },
  user_profile_empty_state: require('../assets/images/user_profile_empty_state.png'),
  cameraIcon: {
    uri: `${APP_ASSET_URL}/51/camera_icon_3x.png`,
  },
  retake_selfie: require('../assets/images/retake_selfie.png'),
  video_first_frame: {
    uri: `${APP_ASSET_URL}/4/video_first_frame.jpg`,
  },
  review_camera: require('../assets/images/review_camera.png'),
  foxy_main_logo: {
    uri: `${APP_ASSET_URL}/70/app_video_foxy_logo.png`,
  },
  popover_cross: require('../assets/images/popover_cross.png'),
  bigFaceAnalysis: {
    uri: `${APP_ASSET_URL}/3/big_face_analysis_v2_3x.png`,
  },
  serverError: {
    uri: `${APP_ASSET_URL}/200/servererror_3x.png`,
  },
  no_internet: require('../assets/images/no_internet.png'),
  selfie_analysis_failed: {
    uri: `${APP_ASSET_URL}/20/selfie_analysis_failed_3x.png`,
  },
  screen_flash: require('../assets/images/screen_flash.png'),
  order_confirmation: {
    uri: `${APP_ASSET_URL}/27/order_confirmation.png`,
  },
  lock: require('../assets/images/lock.png'),
  label: { uri: Config.FLASHDEAL_FLAG_IMAGE },
  ic_swipe: require('../assets/images/ic_swipe.png'),
  empty_wishlist: {
    uri: `${APP_ASSET_URL}/5/empty_wishlist.png`,
  },
  ic_coupon: require('../assets/images/ic_coupon.png'),
  ic_store: require('../assets/images/store-icon.png'),
  addedToCart: {
    light: {
      false: require('../assets/images/cartIcon.png'),
      true: require('../assets/images/addedToCartIcon.png'),
    },
    dark: {
      false: require('../assets/images/cartIcon_white.png'),
      true: require('../assets/images/view_cart_white.png'),
    },
    grey: {
      false: require('../assets/images/cartIcon_white.png'),
      true: require('../assets/images/view_cart_white.png'),
    },
    flash: require('../assets/images/flash.png'),
  },
  mediaDetailPage: {
    followingButton: require('../assets/images/followingArtist.png'),
    nextVideoButton: require('../assets/images/next_video.png'),
    thumbIcon: require('../assets/sliderIcon.png'),
    playIcon: require('../assets/images/play.png'),
    pauseIcon: require('../assets/images/pause.png'),
    pauseVideoIcon: require('../assets/images/videoPause.png'),
    playVideoIcon: require('../assets/images/videoPlay.png'),
    nextButton: require('../assets/images/next.png'),
    previousButton: require('../assets/images/previous.png'),
    closeButton: require('../assets/images/closeContent.png'),
    moreContentPage: require('../assets/images/moreContentPage.png'),
    followButtonWhite: require('../assets/images/followIconWhite.png'),
    followingButtonWhite: require('../assets/images/followingIconWhite.png'),
  },
  videoControls: {
    fullscreen: require('../assets/images/fullscreen.png'),

    share: require('../assets/images/shareWhite.png'),
    unfavorite: {
      uri: `${APP_ASSET_URL}/80/like_3x.png`,
    },

    favoriteAnimation: {
      uri: `${APP_ASSET_URL}/100/likeanimation.gif`,
    },
  },

  addReviewVideoIcon: require('../assets/images/video_icon_blue.png'),
  rating: {
    product_1_star: {
      uri: `${APP_ASSET_URL}/55/one_star.png`,
    },
    product_2_star: {
      uri: `${APP_ASSET_URL}/68/two_star.png`,
    },
    product_3_star: {
      uri: `${APP_ASSET_URL}/61/three_star.png`,
    },
    product_4_star: {
      uri: `${APP_ASSET_URL}/65/four_star.png`,
    },
    product_5_star: {
      uri: `${APP_ASSET_URL}/57/five_star.png`,
    },
    filled_star: require('../assets/images/filled_star.png'),
  },
  tabbar: {
    camera: {
      normal: require('../assets/images/tabbar_camera.png'),
      selected: require('../assets/images/tabbar_camera_selected.png'),
    },
    feed: {
      normal: require('../assets/images/feed_unselected.png'),
      selected: require('../assets/images/feed_selected.png'),
    },
    store: {
      normal: require('../assets/images/tabber_offer_unselected.png'),
      selected: require('../assets/images/tabber_offer_selected.png'),
    },
    collection: {
      normal: require('../assets/images/tabber_mykit_unselected.png'),
      selected: require('../assets/images/tabber_mykit_selected.png'),
    },
    profile: {
      normal: require('../assets/images/profile_unselected.png'),
      selected: require('../assets/images/profile_selected.png'),
    },
  },
  whatsappIcon: {
    enabled: require('../assets/images/whatsappIconEnabled.png'),
    disabled: require('../assets/images/whatsappIconDisabled.png'),
  },
  interest: {
    selected: require('../assets/images/foxySelected.png'),
    unselected: require('../assets/images/interestUnselected.png'),
  },

  redTick: require('../assets/images/red_tick.png'),
  innerShadow: require('../assets/images/inner_shadow.png'),
  currentLocation: require('../assets/images/current_location.png'),
  plus: require('../assets/images/plus.png'),
  plus_thin: require('../assets/images/plus_thin.png'),
  socialMediaIcons: {
    youtube: require('../assets/images/youtube_circular.png'),
    instagram_filled_big: {
      uri: `${APP_ASSET_URL}/37/instagram_fill_3x.png`,
    },
    instagram_text_logo: {
      uri: `${APP_ASSET_URL}/47/instagram_text_logo_3x.png`,
    },
  },

  profilePicPlaceholder: {
    uri: `${APP_ASSET_URL}/9/profile_pic_placeholder.png`,
  },
  cartTrayIcon: require('../assets/images/cart_tray_icon.png'),
  coloredCartIcon: {
    uri: `${APP_ASSET_URL}/805/colored_cart_icon.png`,
  },
  item: {
    unselected: require('../assets/images/itemUnselected.png'),
    selected: require('../assets/images/foxySelected.png'),
  },
  foxyPromise: {
    orignalProducts: {
      uri: `${APP_ASSET_URL}/85/orignal_products.png`,
    },
    fastDelivery: require('../assets/images/fastDelivery.png'),
    easyReturns: require('../assets/images/easy-return.png'),
    securePayments: require('../assets/images/safe-payment.png'),
    myLocation: require('../assets/images/my-address.png'),
    deliveryReturn: require('../assets/images/delivery-return.png'),
    deliveryTruck: require('../assets/images/delivery-truck.png'),
  },
  foxyMatch: {
    thumbsUp: require('../assets/images/foxy-match-positive.png'),
    thumbsDown: require('../assets/images/foxy-match-negative.png'),
    perfect: require('../assets/images/foxy-match-perfect.png'),
    play: require('../assets/images/foxy-match-play.png'),
    star: require('../assets/images/foxy-match-star.png'),
    social: require('../assets/images/foxy-match-social.png'),
    rank: require('../assets/images/foxy-match-rank.png'),
  },
  tab: {
    home_selected: require('../assets/images/tab_home_selected.png'),
    home_unselected: require('../assets/images/tab_home_unselected.png'),
    offer_selected: require('../assets/images/tab_offer_selected.png'),
    offer_unselected: require('../assets/images/tab_offer_unselected.png'),
    brand_selected: require('../assets/images/tab_brand_selected.png'),
    brand_unselected: require('../assets/images/tab_brand_unselected.png'),
    review_selected: require('../assets/images/tab_review_selected.png'),
    review_unselected: require('../assets/images/tab_review_unselected.png'),
    me_unselected: {
      uri: `${APP_ASSET_URL}/15/tab_me_unselected.png`,
    },
    club_unselected: require('../assets/images/club_unselected.png'),
  },

  cameraScreenIcons: {
    goBack: require('../assets/images/back_icon_white.png'),

    toggleCameraFace: require('../assets/images/toggleCameraFace.png'),

    productShutter: {
      uri: `${APP_ASSET_URL}/67/productshutter_3x.png`,
    },

    selfie: {
      uri: `${APP_ASSET_URL}/69/takepicture_3x.png`,
    },
    burst: require('../assets/images/takePicture.png'),
    navigate: require('../assets/images/navigate.png'),
    itemSelected: require('../assets/images/foxySelected.png'),

    flash: {
      on: require('../assets/images/flashOn.png'),
      off: require('../assets/images/flashOff.png'),
    },

    cross: require('../assets/images/cross.png'),
    popupClose: require('../assets/images/popupClose.png'),
    decrementButton: require('../assets/images/minus.png'),

    retake: require('../assets/images/retake_button.png'),
    toggle_screen_light: {
      uri: `${APP_ASSET_URL}/84/toggle_screen_light_3x.png`,
    },
    toggle_screen_light_gray: require('../assets/images/toggle_screen_light_gray.png'),
  },

  paymentStatus: {
    stopWatch: require('../assets/images/stopwatch.png'),
  },
  ingredients: {
    uaav_1: { uri: `${APP_ASSET_URL}/25/uaav_1.png` },
    uaav_2: { uri: `${APP_ASSET_URL}/28/uaav_2.png` },
    uaav_3: { uri: `${APP_ASSET_URL}/28/uaav_3.png` },
    uaav_4: { uri: `${APP_ASSET_URL}/28/uaav_4.png` },
    uaav_5: { uri: `${APP_ASSET_URL}/28/uaav_5.png` },
    uaav_6: { uri: `${APP_ASSET_URL}/23/uaav_6.png` },
    uaav_7: { uri: `${APP_ASSET_URL}/28/uaav_7.png` },
    uaav_8: { uri: `${APP_ASSET_URL}/28/uaav_8.png` },
    uaav_9: { uri: `${APP_ASSET_URL}/28/uaav_9.png` },
    uaav_10: {
      uri: `${APP_ASSET_URL}/13/uaav_10.png`,
    },
    uaav_11: {
      uri: `${APP_ASSET_URL}/28/uaav_11.png`,
    },
    uaav_12: {
      uri: `${APP_ASSET_URL}/26/uaav_12.png`,
    },
    uaav_13: {
      uri: `${APP_ASSET_URL}/18/uaav_13.png`,
    },
    uaav_14: {
      uri: `${APP_ASSET_URL}/28/uaav_14.png`,
    },
    uaav_15: {
      uri: `${APP_ASSET_URL}/21/uaav_15.png`,
    },
    uaav_16: {
      uri: `${APP_ASSET_URL}/28/uaav_16.png`,
    },
  },

  ingredients_empty: {
    empty_uaav_1: require('../assets/images/empty_uaav_1.png'),
    empty_uaav_2: require('../assets/images/empty_uaav_2.png'),
    empty_uaav_3: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_3.png`,
    },
    empty_uaav_4: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_4.png`,
    },
    empty_uaav_5: require('../assets/images/empty_uaav_5.png'),
    empty_uaav_6: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_6.png`,
    },
    empty_uaav_7: {
      uri: `${APP_ASSET_URL}/33/empty_uaav_7.png`,
    },
    empty_uaav_8: {
      uri: `${APP_ASSET_URL}/38/empty_uaav_8.png`,
    },
    empty_uaav_9: require('../assets/images/empty_uaav_9.png'),
    empty_uaav_10: {
      uri: `${APP_ASSET_URL}/32/empty_uaav_10.png`,
    },
    empty_uaav_11: {
      uri: `${APP_ASSET_URL}/30/empty_uaav_11.png`,
    },
    empty_uaav_12: {
      uri: `${APP_ASSET_URL}/34/empty_uaav_12.png`,
    },
    empty_uaav_13: require('../assets/images/empty_uaav_13.png'),
    empty_uaav_14: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_14.png`,
    },
    empty_uaav_15: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_15.png`,
    },
    empty_uaav_16: {
      uri: `${APP_ASSET_URL}/28/empty_uaav_16.png`,
    },
  },

  previewScreenIcons: {
    goBack: require('../assets/images/back_icon_white.png'),
    delete: {
      uri: `${APP_ASSET_URL}/75/delete_trash_3x.png`,
    },
    reset: require('../assets/images/Reset.png'),
    croppingRatioOriginal: require('../assets/images/Original.png'),
    grayscaleCross: require('../assets/images/grayscaleCross.png'),
    split: require('../assets/images/split.png'),

    playSpeed: require('../assets/images/speed.png'),
    edit: require('../assets/images/edit.png'),
    rotate: require('../assets/images/rotate.png'),

    tagProducts: require('../assets/images/tagProducts.png'),
    smallCross: require('../assets/images/smallCross.png'),

    originalDimension: require('../assets/images/Original.png'),

    playVideo: require('../assets/images/playVideo.png'),
    pauseVideo: require('../assets/images/pauseVideo.png'),
  },

  orderAddress: {
    Home: {
      uri: `${APP_ASSET_URL}/49/home_address.png`,
    },
    Work: {
      uri: `${APP_ASSET_URL}/46/office_address.png`,
    },
    Others: {
      uri: `${APP_ASSET_URL}/44/other_address.png`,
    },
    'Foxy Retail': {
      uri: `${APP_ASSET_URL}/44/other_address.png`,
    },
    'Foxy App': {
      uri: `${APP_ASSET_URL}/49/home_address.png`,
    },
  },

  addProductsScreenIcon: {
    goBack: require('../assets/images/back_icon_white.png'),
    smallCross: require('../assets/images/smallCross.png'),
    addIcon: require('../assets/images/plusIcon.png'),
    search: require('../assets/images/search.png'),
    tagProducts: require('../assets/images/tagProducts.png'),
    closeCircle: require('../assets/images/closeCircle.png'),
    closeIcon: {
      uri: `${APP_ASSET_URL}/78/close_3x.png`,
    },
    permissionBackground: {
      uri: `${APP_ASSET_URL}/29/permissionbackground.png`,
    },

    scanFlash: {
      on: require('../assets/images/flash-on.png'),
      off: require('../assets/images/flash-off.png'),
    },
  },

  uploadPostScreenIcons: {
    goBack: require('../assets/images/back_icon_white.png'),
    whatsApp: require('../assets/images/whatsappIconEnabled.png'),
    tagProducts: require('../assets/images/tagProducts.png'),
    edit: require('../assets/images/edit.png'),
    publishingSettings: require('../assets/images/publishingSettings.png'),
    checkSquare: require('../assets/images/checkSquare.png'),
    roundedCross: require('../assets/images/roundedCross.png'),
    instagram: require('../assets/images/instagramBlack.png'),
    twitter: require('../assets/images/twitter.png'),
    checkBox: require('../assets/images/checkBox.png'),
    uncheck: require('../assets/images/uncheck.png'),

    greenCheckmark: require('../assets/images/greenCircularTick.png'),
    greyChevronRight: require('../assets/images/grey_chevron_right.png'),
  },

  playMoreButton: require('../assets/images/play_more.png'),
  history: { uri: `${APP_ASSET_URL}/834/history.png` },
  radioButton: {
    true: require('../assets/images/radio_button_selected.png'),
    false: require('../assets/images/radio_button_default.png'),
  },
  promoCode: require('../assets/images/promocode_logo.png'),
  greenCircularTick: require('../assets/images/greenCircularTick.png'),
  greyCross: require('../assets/images/greyCross.png'),
  card: `${APP_ASSET_URL}/906/card_black.png`,
  cardSelected: `${APP_ASSET_URL}/907/card_selected_3x.png`,
  bankImage: {
    hdfc: require('../assets/images/hdfc.png'),
    sbi: { uri: `${APP_ASSET_URL}/45/sbi.png` },
    icici: { uri: `${APP_ASSET_URL}/42/icici.png` },
    axis: { uri: `${APP_ASSET_URL}/43/axis.png` },
    kotak: { uri: `${APP_ASSET_URL}/28/kotak.png` },
    BANK_HDFB: require('../assets/images/hdfc.png'),
    BANK_SBIB: { uri: `${APP_ASSET_URL}/45/sbi.png` },
    BANK_ICIB: {
      uri: `${APP_ASSET_URL}/42/icici.png`,
    },
    BANK_AXIB: {
      uri: `${APP_ASSET_URL}/43/axis.png`,
    },
    BANK_162B: {
      uri: `${APP_ASSET_URL}/28/kotak.png`,
    },
  },
  payment: {
    bank: `${APP_ASSET_URL}/911/netbanking_black.png`,
    cod: `${APP_ASSET_URL}/58/cod_black_3x.png`,
    upi: `${APP_ASSET_URL}/905/upi_black.png`,
    card: `${APP_ASSET_URL}/906/card_black.png`,
    colored_upi: {
      uri: `${APP_ASSET_URL}/89/upi_logo.png`,
    },
    onlinePayment: {
      uri: `${APP_ASSET_URL}/35/online_payment.png`,
    },
    upiSelected: `${APP_ASSET_URL}/74/upi_selected_3x.png`,
    netBankingSelected: `${APP_ASSET_URL}/912/netbanking_selected_3x.png`,
    codSelected: `${APP_ASSET_URL}/60/cod_selected_3x.png`,
    gpaySelected: `${APP_ASSET_URL}/910/gpay_selected_3x.png`,
    paytmSelected: `${APP_ASSET_URL}/909/paytm_selected_3x.png`,
  },
  cardBrand: {
    mastercard: require('../assets/images/master-black.png'),
    visa: {
      uri: `${APP_ASSET_URL}/53/visa_black.png`,
    },
    rupay: {
      uri: `${APP_ASSET_URL}/87/rupay_black.png`,
    },
    discover: require('../assets/images/discover.png'),
    maestro: {
      uri: `${APP_ASSET_URL}/76/maestro.png`,
    },
    amex: {
      uri: `${APP_ASSET_URL}/81/amex_black.png`,
    },
    jcb: require('../assets/images/visa.png'),
    diners_club: {
      uri: `${APP_ASSET_URL}/71/diners_club.png`,
    },
    unknown: require('../assets/images/card.png'),
  },
  recommendation_tick: require('../assets/images/recommendation_tick.png'),
  profileEmptyState: {
    uri: `${APP_ASSET_URL}/40/profile_empty_state_3x.png`,
  },
  socialShareIcons: {
    whatsapp: require('../assets/images/whatsapp_share.png'),
    more: require('../assets/images/more_share.png'),

    sms: require('../assets/images/sms.png'),
  },
  emptyState: {
    profile: require('../assets/images/profile_empty_state.png'),
    collection: {
      uri: `${APP_ASSET_URL}/17/placeholder_collection_3x.png`,
    },
  },
  delayOffers: {
    giftBox: {
      uri: `${APP_ASSET_URL}/281/gift_nft_artwork_2.png`,
    },
    giftBoxLineIcon: {
      uri: `${APP_ASSET_URL}/304/gift_nft.png`,
    },
  },
  comparePrice: {
    nyka: {
      uri: `${APP_ASSET_URL}/39/nykaa_logo_3x.png`,
    },
    myntra: {
      uri: `${APP_ASSET_URL}/52/myntra_logo_3x.png`,
    },
  },
  comparePriceImage: {
    nyka: {
      uri: `${APP_ASSET_URL}/39/nykaa_logo_3x.png`,
    },
    myntra: {
      uri: `${APP_ASSET_URL}/52/myntra_logo_3x.png`,
    },
    foxy: {
      uri: `${APP_ASSET_URL}/48/foxy_linear.png`,
    },
  },
  orderStatus: {
    success: {
      uri: `${APP_ASSET_URL}/62/order_success_3x.png`,
    },
    failure: {
      uri: `${APP_ASSET_URL}/59/order_failure_3x.png`,
    },
  },
  packageBox: require('../assets/images/package_box.png'),
  services: {},
  checkBox: {
    true: require('../assets/images/checkBox.png'),
  },
  gender: {
    male: {
      true: `${APP_ASSET_URL}/576/men_selected_new.png`,
      false: require('../assets/images/men_unselected.png'),
    },
    female: {
      true: `${APP_ASSET_URL}/577/women_selected_new.png`,
      false: require('../assets/images/women_unselected.png'),
    },
  },

  dashboard: {
    refer: {
      uri: `${APP_ASSET_URL}/28/refer_earn_large_3x.png`,
    },
    instagramLogo: require('../assets/images/instagram_small.png'),
  },
  myProfile: {
    starCircle: require('../assets/images/star_circle.png'),
    addImage: require('../assets/images/add_image.png'),
    rightArow: require('../assets/images/white_circular_arrow.png'),

    freeProductTag: require('../assets/images/tag.png'),
    freeProductIcon: require('../assets/images/free_product_icon.png'),
    trendingUp: require('../assets/images/trending-up-hd.png'),
    videoUploadRetry: require('../assets/images/retry.png'),
  },

  overflow_menu: require('../assets/images/overflow_menu.png'),
  infoIcon: require('../assets/images/information_icon.png'),

  artistCardPlaceholder: {
    uri: `${APP_ASSET_URL}/12/beauty_influencer_placeholder_3x.png`,
  },
  MoreOption: require('../assets/images/more_option.png'),
  returnLogo: require('../assets/images/return_logo.png'),
  cartEmptyBag: require('../assets/images/empty_bag.png'),
  artistFollowDock: require('../assets/images/follow_dock.png'),
  artistFollowedDock: require('../assets/images/followed_dock.png'),
  scanGraphics: {
    uri: `${APP_ASSET_URL}/83/scan_graphics_3x.png`,
  },
  originalProductLogo: require('../assets/images/original_product_logo.png'),

  returnPickupLogo: require('../assets/images/return_pickup_logo.png'),
  rupee: require('../assets/images/rupee.png'),
  gift: { uri: `${APP_ASSET_URL}/31/gift.png` },
  giftPopover: require('../assets/images/Rectangle.png'),
  locationImage: require('../assets/images/maps-and-flags.png'),
  upNext: require('../assets/images/Group.png'),
  mask: require('../assets/images/Mask.png'),
  moreDots: require('../assets/images/more_dots.png'),
  offerDiscountTag: require('../assets/images/offer_discount_icon.png'),
  thumb: require('../assets/images/thumb.png'),
  animations: {
    foxy_icon: {
      uri: `${APP_ASSET_URL}/99/foxy_icon.gif`,
    },
  },
  videoCallIcon: require('../assets/images/video-call-icon.png'),
  googlePlayIcon: require('../assets/images/google-play-icon.png'),
  recycle_safe_area: {
    uri: `${APP_ASSET_URL}/19/recycle_safe_area.png`,
  },
  notification_modal: {
    uri: `${APP_ASSET_URL}/10/notification_modal_3x.png`,
  },
  selfiePromptSmall: {
    uri: `${APP_ASSET_URL}/11/selfie_prompt_small.png`,
  },
  iconSortFilter: require('../assets/images/icon_sort_filter.png'),
  emptyFilterResults: {
    uri: `${APP_ASSET_URL}/41/empty_filter_results.png`,
  },
  rightGrayChevron: require('../assets/images/rightChevron.png'),
  leftGrayChevron: require('../assets/images/leftChevron.png'),
  rightBlueChevron: require('../assets/images/chevron_blue.png'),
  playCircle: {
    uri: `${APP_ASSET_URL}/28/playcircle_3x.png`,
  },
  profilePlaceholder: {
    uri: `${APP_ASSET_URL}/9/profile_pic_placeholder.png`,
  },
  fullScreenMaximize: `${APP_ASSET_URL}/114/fullscreenmaximize_3x.png`,
  volumeMuteButton: `${APP_ASSET_URL}/112/volumemutebutton_3x.png`,
  volumeUnmuteButton: `${APP_ASSET_URL}/113/volumeunmutebutton_3x.png`,
  edgeFeatureCard: `${APP_ASSET_URL}/558/invite_modal_image.png`,
  becomeFoxyEdgePro: `${APP_ASSET_URL}/388/become_foxy_edge_pro.png`,
  chevronRightBlack: `${APP_ASSET_URL}/578/chevron_right_black.png`,
  comboOffer: `${APP_ASSET_URL}/804/combo_offer.png`,
  loginCardIcon: {
    uri: `${APP_ASSET_URL}/6/login_card_icon_3x.png`,
  },
  consumerProfileEmptyStateImage: {
    uri: `${APP_ASSET_URL}/50/consumerprofileemptystateimage_3x.png`,
  },
  selfie_prompt_v2: {
    uri: `${APP_ASSET_URL}/573/selfie_prompt_new_ui.png`,
  },
  for_you_placeholder: {
    uri: `${APP_ASSET_URL}/28/for_you_placeholder.png`,
  },
  uploadTray: {
    success: require('../assets/images/feather_check.png'),
    paused: require('../assets/images/feather_pause.png'),
    failure: require('../assets/images/feather_x.png'),
    in_progress: require('../assets/images/feather_upload.png'),
  },
  filled_star: require('../assets/images/filled_star.png'),
  empty_star: require('../assets/images/defaultGreyStar.png'),
  alert_message: {
    uri: `${APP_ASSET_URL}/107/alert_message.png`,
  },
  take_selfie_camera_icon: `${APP_ASSET_URL}/575/camera_icon.png`,
  salon: {
    cross: `${APP_ASSET_URL}/406/cross_white.png`,
    salon_no_result: `${APP_ASSET_URL}/294/no_result_found_salon.png`,
    salon_empty_search: `${APP_ASSET_URL}/295/salon_empty_search.png`,
    Rectangle2: `${APP_ASSET_URL}/151/rectangle2.png`,
    pay_on_foxy_point: `${APP_ASSET_URL}/256/ellipse_576.png`,
    qr_scan: { uri: `${APP_ASSET_URL}/220/scan_icon.png` },
    search_qr_scan: `${APP_ASSET_URL}/331/search_qr_scan.png`,
    cross_black_2x: { uri: `${APP_ASSET_URL}/153/cross_black_2x.png` },
    black_cross: `${APP_ASSET_URL}/153/cross_black_2x.png`,
    qr_scan_rectangle: `${APP_ASSET_URL}/154/qr_window.png`,
    qr_scan_rectangle_default: `${APP_ASSET_URL}/245/qr_window__1_.png`,
    back_icon_blue_3x: `${APP_ASSET_URL}/155/back_icon_blue_3x.png`,
    share_icon_blue_3x: `${APP_ASSET_URL}/156/share_icon_blue_3x.png`,
    like_icon_blue_3x: `${APP_ASSET_URL}/157/like_icon_blue_3x.png`,
    overview_icon: { uri: `${APP_ASSET_URL}/158/overview_icon.png` },
    product_icon: { uri: `${APP_ASSET_URL}/159/product_icon.png` },
    review_icon: { uri: `${APP_ASSET_URL}/160/review_icon.png` },
    product_salon_sample: `${APP_ASSET_URL}/161/image_242.png`,
    salon_missing_info: { uri: `${APP_ASSET_URL}/162/salon_missing_info.png` },
    add_salon_info: { uri: `${APP_ASSET_URL}/163/add_salon_info.png` },
    pay_with_foxy_image: `${APP_ASSET_URL}/164/pay_with_foxy_image.png`,
    next_icon_3x: `${APP_ASSET_URL}/165/next_icon_3x.png`,
    salon_pay_failure: `${APP_ASSET_URL}/168/salon_pay_failure.png`,
    claim_reward: `${APP_ASSET_URL}/169/claim_reward.png`,
    unlock_reward_icon: { uri: `${APP_ASSET_URL}/332/unlock_reward_icon.png` },
    knob: { uri: `${APP_ASSET_URL}/249/knob.png` },
    selected_step: `${APP_ASSET_URL}/334/selected_step.png`,
    unselected_step: `${APP_ASSET_URL}/335/unselected_step.png`,
    upi_searching_image: `${APP_ASSET_URL}/175/upi_searching_image.png`,
    map_pin: `${APP_ASSET_URL}/176/map_pin.png`,
    phone_icon: `${APP_ASSET_URL}/177/phone_icon.png`,
    location_salons_empty: `${APP_ASSET_URL}/292/location_salons_empty.png`,
    clock_icon: `${APP_ASSET_URL}/184/clock_icon.png`,
    star_icon: `${APP_ASSET_URL}/185/star_icon.png`,
    price_icon: `${APP_ASSET_URL}/186/price_icon.png`,
    map_sample: `${APP_ASSET_URL}/293/map_sample.png`,
    salon_more_details_popular: `${APP_ASSET_URL}/187/salon_more_details_popular.png`,
    salon_more_details_features: `${APP_ASSET_URL}/188/salon_more_details_features.png`,
    start_scanning_background: `${APP_ASSET_URL}/29/permissionbackground.png`,
    star_scanning_image: `${APP_ASSET_URL}/192/start_scanning.png`,
    flash_icon: `${APP_ASSET_URL}/296/flash_icon.png`,
    flash_icon_on: `${APP_ASSET_URL}/297/flash_icon_on.png`,
    location_icon: `${APP_ASSET_URL}/243/location_icon.png`,
    allow_location_icon: {
      uri: `${APP_ASSET_URL}/333/allow_location_icon.png`,
    },
    pay_on_foxy: `${APP_ASSET_URL}/244/pay_on_foxy.png`,
    wheel_center: `${APP_ASSET_URL}/250/wheel_center.png`,
    wheel_center_2: `${APP_ASSET_URL}/254/ellipse_650.png`,
    pay_on_foxy_search: `${APP_ASSET_URL}/257/ellipse_577.png`,
    pay_on_foxy_reward: `${APP_ASSET_URL}/258/ellipse_579.png`,
    pay_on_foxy_not_listed: `${APP_ASSET_URL}/259/ellipse_580.png`,
    pay_on_foxy_info: `${APP_ASSET_URL}/260/ellipse_581.png`,
    pay_on_foxy_wheel: `${APP_ASSET_URL}/261/group_5684.png`,
    spin_the_wheel_background: `${APP_ASSET_URL}/262/salon_background_image.png`,
  },
  contact_book: {
    uri: `${APP_ASSET_URL}/110/contact.png`,
  },
  reminderBell: { uri: `${APP_ASSET_URL}/127/ic_bell.png` },
  inviteIcon: { uri: `${APP_ASSET_URL}/130/ic_invite.png` },
  linkChain: { uri: `${APP_ASSET_URL}/133/ic_link_chain.png` },
  pinkLabel: { uri: Config.GROUPDEAL_FLAG_IMAGE },
  copyButton: { uri: `${APP_ASSET_URL}/134/ic_content_copy.png` },
  addUser: { uri: `${APP_ASSET_URL}/129/add_user.png` },
  blackCheck: { uri: `${APP_ASSET_URL}/132/black_check_ic.png` },
  blackCross: { uri: `${APP_ASSET_URL}/128/black_cross_ic.png` },
  redCross: { uri: `${APP_ASSET_URL}/131/ic_red_cross.png` },
  foxyKit: { uri: `${APP_ASSET_URL}/118/foxy_kit.png` },
  dashedLine: require('../assets/images/dashedLine.png'),
  upload_ic: require('../assets/images/upload_ic.png'),
  itemLikeAnimation: {
    uri: `${APP_ASSET_URL}/125/item_like_for_autosearch_and_modal.gif`,
  },
  introVideoPlay: `${APP_ASSET_URL}/148/intro_play_ic.png`,
  shareButton: `${APP_ASSET_URL}/221/fluent_share_ios_20_filled.png`,
  altLink: `${APP_ASSET_URL}/222/bx_link_alt.png`,
  followingIcon: `${APP_ASSET_URL}/279/carbon_user_follow__1_.png`,
  followIcon: `${APP_ASSET_URL}/278/carbon_user_follow.png`,
  itemLikeAnimation: `${APP_ASSET_URL}/125/item_like_for_autosearch_and_modal.gif`,

  bluePlusIcon: `${APP_ASSET_URL}/248/blue_plus_icon.png`,
  foxyReward: `${APP_ASSET_URL}/251/foxy_reward.png`,
  salonBeautyKit: `${APP_ASSET_URL}/255/beauty_starter_kit_by_foxy_2.png`,
  wheelBackground: `${APP_ASSET_URL}/262/salon_background_image.png`,
  soon: `${APP_ASSET_URL}/263/soon.png`,
  discountCoupon: `${APP_ASSET_URL}/280/discount_icon.png`,
  greenCheckIcon: `${APP_ASSET_URL}/379/green_check.png`,
  coins: `${APP_ASSET_URL}/372/coins.png`,
  handsUp: `${APP_ASSET_URL}/373/hands_up.png`,
  foxin: `${APP_ASSET_URL}/374/foxin.png`,
  friendGroup: `${APP_ASSET_URL}/377/group_122.png`,
  whatsappRemind: `${APP_ASSET_URL}/380/frame_156.png`,
  calendar: `${APP_ASSET_URL}/390/calendar_1.png`,
  award: `${APP_ASSET_URL}/391/award_1.png`,
  addUser1: `${APP_ASSET_URL}/392/user_plus_1.png`,
  cart_reminder: {
    added_to_bag: `${APP_ASSET_URL}/104/cart_1.png`,
    added_shipping: `${APP_ASSET_URL}/105/cart_2.png`,
    confirm_order: `${APP_ASSET_URL}/106/cart_3.png`,
  },
  successInfo: `${APP_ASSET_URL}/252/seccess_info.png`,

  routines: {
    sample_image: `${APP_ASSET_URL}/428/green_minimalist_and_simple_skincare_giveaway_instagram_post__1__1.png`,
    invite_icon: `${APP_ASSET_URL}/429/ic_invite.png`,
    routine_benefits_icons: {
      list: `${APP_ASSET_URL}/430/list.png`,
      live: `${APP_ASSET_URL}/431/live.png`,
      live_white: `${APP_ASSET_URL}/468/live_icon_white.png`,
      coupon: `${APP_ASSET_URL}/432/coupon_icon.png`,
      reward: `${APP_ASSET_URL}/433/reward_icon.png`,
    },
    routine_qualities_icons: {
      video: `${APP_ASSET_URL}/434/video_icon.png`,
      star: `${APP_ASSET_URL}/435/star.png`,
      users: `${APP_ASSET_URL}/436/users.png`,
    },
    routine_invite: `${APP_ASSET_URL}/437/image_171.png`,
    checkedIcon: `${APP_ASSET_URL}/438/checked.png`,
    uncheckedIcon: `${APP_ASSET_URL}/439/unchecked.png`,
    calender_icon: `${APP_ASSET_URL}/440/calender_icon.png`,
    calender_icon_1: `${APP_ASSET_URL}/469/calendar_1.png`,
    play_icon: `${APP_ASSET_URL}/442/play__1_.png`,
    share_icon: `${APP_ASSET_URL}/459/ic_share.png`,
    feature_card_img: `${APP_ASSET_URL}/462/image_169.png`,
    feature_card_bg: `${APP_ASSET_URL}/463/bff_invite__1_.png`,
    info_icon: `${APP_ASSET_URL}/466/akar_icons_info.png`,
    live_bg_sample: `${APP_ASSET_URL}/467/image_129__1_.png`,
    gold_badge: `${APP_ASSET_URL}/380/gold_badge.png`,
    silver_badge: `${APP_ASSET_URL}/381/silver_badge.png`,
    bronze_badge: `${APP_ASSET_URL}/382/bronze_badge.png`,
    trophy: `${APP_ASSET_URL}/383/trophy_3x.png`,
    tiltedTrophy: `${APP_ASSET_URL}/384/tilted_trophy_3x.png`,
    fireWorks: `${APP_ASSET_URL}/385/fireworks.png`,
    camera: `${APP_ASSET_URL}/478/coins_1.png`,
    participantsArray: [
      `${APP_ASSET_URL}/497/artist_1.png`,
      `${APP_ASSET_URL}/498/artist_2.png`,
      `${APP_ASSET_URL}/499/artist_3.png`,
      `${APP_ASSET_URL}/670/artist_4.png`,
      `${APP_ASSET_URL}/671/artist_5.png`,
      `${APP_ASSET_URL}/672/artist_6.png`,
      `${APP_ASSET_URL}/673/artist_7.png`,
      `${APP_ASSET_URL}/674/artist_8.png`,
      `${APP_ASSET_URL}/675/artist_9.png`,
      `${APP_ASSET_URL}/676/artist_10.png`,
    ],
    participation_feature: `${APP_ASSET_URL}/500/frame_5630.png`,
    consistency_feature: `${APP_ASSET_URL}/511/trophy_copy.png`,
    ranks: {
      rank_1: `${APP_ASSET_URL}/501/rank_1.png`,
      rank_2: `${APP_ASSET_URL}/502/rank_2.png`,
      rank_3: `${APP_ASSET_URL}/503/rank_3.png`,
    },
    upload_icon: { uri: `${APP_ASSET_URL}/506/upload_1.png` },
    artist_placeholder: `${APP_ASSET_URL}/507/artistplaceholder.png`,
    whatsApp_white: `${APP_ASSET_URL}/509/cib_whatsapp.png`,
    chats_icon: `${APP_ASSET_URL}/510/bi_chat_dots.png`,
    sample_action_card_image: `${APP_ASSET_URL}/512/youtube_1__7_.png`,
    products_recommended: `${APP_ASSET_URL}/513/recommende__1_.png`,
  },
  locationIcon: `${APP_ASSET_URL}/508/location_ic.png`,
  clubStar: {
    uri: `${APP_ASSET_URL}/486/flash__1_.png`,
  },
  club_selected: require('../assets/images/club_selected.png'),
  retailStore: {
    display: `${APP_ASSET_URL}/384/screenshot_2022_02_24_at_10.06_1.png`,
    overviewIcon: { uri: `${APP_ASSET_URL}/158/overview_icon.png` },
    productIcon: { uri: `${APP_ASSET_URL}/159/product_icon.png` },
    reviewIcon: { uri: `${APP_ASSET_URL}/160/review_icon.png` },
    mapPinIcon: `${APP_ASSET_URL}/386/map_pin_2.png`,
    sampleImage: `${APP_ASSET_URL}/387/image_190.png`,
    signInIcon: { uri: `${APP_ASSET_URL}/388/log_in_1.png` },
    mapPin: `${APP_ASSET_URL}/176/map_pin.png`,
    phoneIcon: `${APP_ASSET_URL}/177/phone_icon.png`,
    foxyPassBackground: { uri: `${APP_ASSET_URL}/393/foxypassbackground.png` },
    sampleQr: `${APP_ASSET_URL}/395/frame_546.png`,
    sampleProductImage:
      'https://cdn3.foxy.in/media/product/images/2/51kwyicrhjl._sl1098_.jpg',
    scanIcon: `${APP_ASSET_URL}/401/frame_578.png`,
    qrIcon: `${APP_ASSET_URL}/401/frame_578.png`,
    confetti:
      'https://cdn3.foxy.in/generic_files/app_asset/generic_file/404/confettii.gif',
    handsup: `${APP_ASSET_URL}/405/handsup.png`,
    locationImage: `${APP_ASSET_URL}/418/screenshot_2022_08_04_at_11.55.32_am.png`,
  },
  profile: {
    box: `${APP_ASSET_URL}/396/box_1.png`,
    heart: `${APP_ASSET_URL}/397/heart_1.png`,
    gift: `${APP_ASSET_URL}/398/gift_1.png`,
    userPlus: `${APP_ASSET_URL}/399/user_plus_1.png`,
  },

  usnScan: {
    default:
      'https://cdn3.foxy.in/generic_files/app_asset/generic_file/408/usn_scanning_frame.png',

    success: 'https://cdn3.foxy.in/media/app_asset/image/409/success_frame.png',
    zapIcon: `${APP_ASSET_URL}/407/zap_icon.png`,
    usnSampleImage: `${APP_ASSET_URL}/410/usn_code_image.png`,
    greyAddToBag: `${APP_ASSET_URL}/411/add_to_bag_grey_check.png`,
    addTobagWhite: { uri: `${APP_ASSET_URL}/420/add_to_bag___white.png` },
    plane:
      'https://cdn3.foxy.in/generic_files/app_asset/generic_file/421/plane.gif',
  },

  retail: {
    photosEmptyState: {
      uri: `${APP_ASSET_URL}/425/placeholder_v1_removebg_preview.png`,
    },
    loadingCart: `${APP_ASSET_URL}/423/loading_cart__1_.gif`,
    profileScanningIcon: `${APP_ASSET_URL}/426/user_profile_pic_scanning_frame.png`,
  },
  zoom_icon: `${APP_ASSET_URL}/618/zoom_in_1.png`,
  boho_logo: `${APP_ASSET_URL}/543/boho_logo.png`,
  foxy_logo: `${APP_ASSET_URL}/48/foxy_linear.png`,
  minus: `${APP_ASSET_URL}/629/vector__2_.png`,
  plusBlack: `${APP_ASSET_URL}/630/group_5848.png`,
  zoom_in: `${APP_ASSET_URL}/625/zoom.png`,
  plus_expand: `${APP_ASSET_URL}/627/plus_expand.png`,
  minus_collapse: `${APP_ASSET_URL}/628/minus_collapse.png`,
  product_rating_image: `${APP_ASSET_URL}/657/vector.png`,
  rating_star_yellow: `${APP_ASSET_URL}/699/vector.png`,

  edge: {
    offer: `${APP_ASSET_URL}/514/edge_icon.png`,
    selfie_arrow: `${APP_ASSET_URL}/515/arrow_up_circle_1.png`,
    tooltip: `${APP_ASSET_URL}/520/tooltip.png`,
    testimonyQuotes: `${APP_ASSET_URL}/522/__.png`,
    checked_icon: `${APP_ASSET_URL}/523/checked_icon.png`,
    logo: `${APP_ASSET_URL}/544/edge.png`,
    placeholder: `${APP_ASSET_URL}/507/artistplaceholder.png`,
    plus: `${APP_ASSET_URL}/525/_.png`,
    upgrade_logo: `${APP_ASSET_URL}/537/image_243.png`,
    modal_close: `${APP_ASSET_URL}/538/frame_117.png`,
    check_white: `${APP_ASSET_URL}/539/check_2.png`,
    mesh: `${APP_ASSET_URL}/531/mesh_1__3_.png`,
    edge_icon: { uri: `${APP_ASSET_URL}/553/foxy_edge.png` },
  },
  view_more_icon: `${APP_ASSET_URL}/552/view_more_button.png`,
  rotate_cw: `${APP_ASSET_URL}/693/rotate_cw.png`,
  faq_top_section_image: `${APP_ASSET_URL}/619/faq_section_image.png`,
  faq_dislike_icon: `${APP_ASSET_URL}/621/dislike_icon.png`,
  faq_like_icon: `${APP_ASSET_URL}/620/like_icon.png`,
  upload_image_camera_icon: `${APP_ASSET_URL}/622/upload_image_camera.png`,
  white_cross: `${APP_ASSET_URL}/623/white_cross_4x.png`,
  confirm_request_image: `${APP_ASSET_URL}/624/confirm_request_image.png`,
  cancel_order_image: `${APP_ASSET_URL}/655/cancel_order_image.png`,
  link_icon: `${APP_ASSET_URL}/656/link_icon.png`,
  video_pause_button: `${APP_ASSET_URL}/659/video_pause_button.png`,
  defective_product_1: `${APP_ASSET_URL}/662/defective_product_1.jpeg`,
  defective_product_2: `${APP_ASSET_URL}/663/defective_product_2.jpeg`,
  blue_chevron: `${APP_ASSET_URL}/664/blue_chevron.png`,
  quantity_plus: `${APP_ASSET_URL}/722/frame_6370.png`,
  quantity_minus: `${APP_ASSET_URL}/723/frame_6371.png`,
  inverted_percent_ribbon: `${APP_ASSET_URL}/724/inverted_percent_ribbon.png`,
  edit_white: `${APP_ASSET_URL}/732/edit_white.png`,
  plus_white: `${APP_ASSET_URL}/730/plus_white.png`,
  minus_white: `${APP_ASSET_URL}/731/vector__1_.png`,
  trash_icon: `${APP_ASSET_URL}/741/frame_6708.png`,
  ic_info: require('../assets/images/ic_info.png'),
  tailorFit: {
    home: `${Config.TENANT_ASSETS_URL}/1/tailor_fit_home_bg.png`,
    playVideo: `${Config.TENANT_ASSETS_URL}/2/play_icon.png`,
    addToBagWhite: `${Config.TENANT_ASSETS_URL}/3/bag_icon.png`,
  },
  foxy_match_pill_icon: `${APP_ASSET_URL}/743/foxy_match_icon.png`,
  black_check_box: `${APP_ASSET_URL}/835/tick_02.png`,
  medal: {
    '1_medal': `${APP_ASSET_URL}/818/image_230.png`,
    '2_medal': `${APP_ASSET_URL}/819/image_231.png`,
    '3_medal': `${APP_ASSET_URL}/820/image_232.png`,
  },
  contactUsIcon: `${APP_ASSET_URL}/363/phone_white.png`,
  desktop: {
    placeholderProfileImage: `${APP_ASSET_URL}/898/placeholder_profile_image.png`,
    accountOptionDivider: `${APP_ASSET_URL}/899/accountoptiondivider.png`,
    searchIcon: `${APP_ASSET_URL}/901/search_icon.png`,
    accountIcon: `${APP_ASSET_URL}/902/account_icon.png`,
    hundredPercentPromise: `${APP_ASSET_URL}/920/100percentfoxypromise.png`,
    freeDeliveryFoxyPromise: `${APP_ASSET_URL}/921/freedeliveryfoxypromise.png`,
    hassleFreeReturnsFoxyPromise: `${APP_ASSET_URL}/922/hasslefreereturnsfoxypromise.png`,
    securePaymentsFoxyPromise: `${APP_ASSET_URL}/923/securepaymentsfoxypromise.png`,
    appleStoreFooterIcon: `${APP_ASSET_URL}/924/applestorefootericon.png`,
    googlePlayFooterIcon: `${APP_ASSET_URL}/925/googleplayfootericon.png`,
  },
  socialIcons: {
    instagram: `${APP_ASSET_URL}/926/instagramicon.png`,
    youtube: `${APP_ASSET_URL}/933/youtubeicon.png`,
    fb: `${APP_ASSET_URL}/928/facebookicon.png`,
    twitter: `${APP_ASSET_URL}/929/twittericon.png`,
    discord: `${APP_ASSET_URL}/1011/discord.png`,
    whatsapp: `${APP_ASSET_URL}/1110/whatsapp_2.png`,
  },
  tabIndicator: `${APP_ASSET_URL}/876/indicator.png`,
  notificationBellIcon: `${APP_ASSET_URL}/869/notification.png`,
  notificationDefaultImage: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
  error_exclamation_icon: `${APP_ASSET_URL}/871/red_exclamation_icon.png`,
  login_offer: `${APP_ASSET_URL}/888/user_1.png`,
  invite_from_offers: `${APP_ASSET_URL}/889/friendship_1.png`,
  mailIcon: `${APP_ASSET_URL}/930/mailicon.png`,
  phoneIcon: `${APP_ASSET_URL}/931/phoneicon.png`,
  locationPinIcon: `${APP_ASSET_URL}/932/locationicon.png`,
  whiteUpArrow: `${APP_ASSET_URL}/934/whiteuparrow.png`,
  whiteDownArrow: `${APP_ASSET_URL}/935/whitedownarrow.png`,
  reviews: `${APP_ASSET_URL}/936/reviews.png`,
  brandsInactiveIcon: `${APP_ASSET_URL}/937/brandsinactiveicon.png`,
  brandsActiveIcon: `${APP_ASSET_URL}/938/brandsactiveicon.png`,
  webHeaderLogo: { uri: Config.APP_HEADER_LOGO },
  foxyEdgeLogo: `${APP_ASSET_URL}/940/foxyedgelogo.png`,
  edgeFeatures: `${APP_ASSET_URL}/941/edgefeatures.png`,
  appHeaderLogo: Config.HEADER_LOGO || `${APP_ASSET_URL}/949/foxyheaderlogo.png`,
  appInstallPrompt: `${APP_ASSET_URL}/955/appinstallprompt.png`,
  privacyConcerns: {
    account: Config.ACCOUNT_SS,
    accountSettings: `${APP_ASSET_URL}/957/privacyconcernsaccountsettings.png`,
    deleteAccount: `${APP_ASSET_URL}/958/privacyconcernsdeleteaccount.png`,
    deleteAccountReasons: `${APP_ASSET_URL}/959/privacyconcernsdeleteaccountreasons.png`,
    deleteSelfie: `${APP_ASSET_URL}/960/privacyconcernsdeleteselfie.png`,
    deleteSelfieReasons: `${APP_ASSET_URL}/961/privacyconcernsdeleteselfiereasons.png`,
  },
  androidIcon: `${APP_ASSET_URL}/962/androidicon.png`,
  appStoreIcon: `${APP_ASSET_URL}/974/appstoreicon.png`,
  uploadProfileImagePlaceholder: `${APP_ASSET_URL}/965/uploadimageplaceholder.png`,
  otherPlatform: `${APP_ASSET_URL}/230/domain.png`,
  socialMediaHolder: `${APP_ASSET_URL}/246/social_media_holder.png`,
  plus_button_circle: `${APP_ASSET_URL}/966/plus_button.png`,
  play_video: `${APP_ASSET_URL}/967/play_button.png`,
  email: `${APP_ASSET_URL}/968/email_icon.png`,
  share_black: `${APP_ASSET_URL}/969/share_black.png`,
  follower_reach_star: `${APP_ASSET_URL}/970/follower_star.png`,
  foxy_logo_small: `${APP_ASSET_URL}/971/foxy_logo_icon.png`,
  one_link_footer: `${APP_ASSET_URL}/972/one_link_footer.png`,
  copy_blue: `${APP_ASSET_URL}/973/copy_blue.png`,
  edit_icon: `${APP_ASSET_URL}/975/edit_icon.png`,
  pro_invite: `${APP_ASSET_URL}/976/pro_invite_image.png`,
  curve_design: `${APP_ASSET_URL}/977/curve.png`,
  video_play: `${APP_ASSET_URL}/978/video_play.png`,
  video_play_container: `${APP_ASSET_URL}/980/video_outer.png`,
  pop_up_close: `${APP_ASSET_URL}/981/popup_close.png`,
  forYouPic1: `${APP_ASSET_URL}/982/foryoupic1.png`,
  forYouPic2: `${APP_ASSET_URL}/983/foryoupic2.png`,
  downloadAppStore: `${APP_ASSET_URL}/984/downloadappstore.png`,
  downloadPlayStore: `${APP_ASSET_URL}/985/downloadplaystore.png`,
  selfieAnalysis: `${APP_ASSET_URL}/986/selfieanalysis.png`,
  instantRewardsAndGifts: `${APP_ASSET_URL}/987/instantrewardsgifts.png`,
  honestReviewsAndExpertContent: `${APP_ASSET_URL}/988/honestreviewsexpertvideo.png`,
  sustainableBusiness: `${APP_ASSET_URL}/989/sustainablebusiness.png`,
  foxyPromiseFreeDelivery: `${APP_ASSET_URL}/990/freedelivery.png`,
  foxyPromiseHassleFreeReturn: `${APP_ASSET_URL}/991/hasslefreereturn.png`,
  foxyPromiseSecurePayments: `${APP_ASSET_URL}/992/securepayments.png`,
  wallet: `${APP_ASSET_URL}/900/wallet.png`,
  return_order_image: `${APP_ASSET_URL}/993/return_confirm_image.png`,
  cancel_order_image: `${APP_ASSET_URL}/1075/cancel_order.png`,
  routines_selected_tab: `${APP_ASSET_URL}/998/rotuines_tab_unselected.png`,
  routines_unselected_tab: `${APP_ASSET_URL}/997/rouines_tab_selected_27.png`,
  edge_selected: `${APP_ASSET_URL}/1008/edge_selected.png`,
  edge_unselected: `${APP_ASSET_URL}/1009/edge_unselected.png`,
  foxy_header_image: `${APP_ASSET_URL}/1010/foxy_logo.png`,
  addFileIcon: `${APP_ASSET_URL}/1014/add_file.png`,
  videoFileIcon: `${APP_ASSET_URL}/1016/video.png`,
  pdfIcon: `${APP_ASSET_URL}/1017/pdf.png`,
  imageIcon: `${APP_ASSET_URL}/1022/image_file.png`,
  saveProgress: `${APP_ASSET_URL}/1021/save_progress.png`,
  mapLocationIcon: `${APP_ASSET_URL}/1029/location.png`,
  whatsapp_green_icon: `${APP_ASSET_URL}/1015/whatsapp_green.png`,
  mapPin: `${APP_ASSET_URL}/1032/map_pin.png`,
  sizeChart: `${APP_ASSET_URL}/1053/image_887.png`,
  bottom_rating: {
    filled_star: `${APP_ASSET_URL}/1036/filled_star.png`,
    empty_star: `${APP_ASSET_URL}/1037/defaultgreystar.png`,
  },
  star_icon: `${APP_ASSET_URL}/1035/vector__1_.png`,
  certificate_icon: `${APP_ASSET_URL}/1034/image_193.png`,
  edit_icon: `${APP_ASSET_URL}/1063/edit.png`,
  share_icon: `${APP_ASSET_URL}/1064/share_icon.png`,
  open_link_icon: `${APP_ASSET_URL}/1065/link_icon.png`,
  doctor_image: `${APP_ASSET_URL}/1066/doctor.png`,
  dummy_phd_degree: `${APP_ASSET_URL}/1067/phd.png`,
  dummy_mbbs_degree: `${APP_ASSET_URL}/1068/mbbs.png`,
  verified_tick: `${APP_ASSET_URL}/1069/verified_tick.png`,
  selfie_image: `${APP_ASSET_URL}/1070/selfie_image.png`,
  dermat_signup: `${APP_ASSET_URL}/1071/dermat_signup.png`,
  dermat_icon_1: `${APP_ASSET_URL}/1072/dermat_icon_1.png`,
  dermat_invite_icon: `${APP_ASSET_URL}/1073/dermat_icon_2.png`,
  skin_twin_tag: `${APP_ASSET_URL}/1100/skin_twin_tag.png`,
  most_helpful_tag: `${APP_ASSET_URL}/1101/most_helpful_tag.png`,
  foxy_rx: `${APP_ASSET_URL}/1104/foxy_rx.png`,
  insta: `${APP_ASSET_URL}/1113/insta.png`,
  whatsapp: `${APP_ASSET_URL}/1114/whatsapp.png`,
  youtube: `${APP_ASSET_URL}/1115/logo.png`,
  video_icon: `${APP_ASSET_URL}/1116/video.png`,
  profile_place_holder: `${APP_ASSET_URL}/101/profileplaceholder`,
  skin_clinic_sample_image: `${APP_ASSET_URL}/1117/skin_clinic.jpeg`,
  ekstore_credit_footer: `${APP_ASSET_URL}/1074/ekstore_credit_footer.png`,
  place_holder: `${APP_ASSET_URL}/40/profile_empty_state_3x.png`,
  location_black_icon: `${APP_ASSET_URL}/1119/location_black_icon.png`,
  dermat_placeholder: `${APP_ASSET_URL}/1124/dermat_dummy.png`,
  outOfStock: `${APP_ASSET_URL}/1123/forbidden.png`,
  barcode_icon: `${APP_ASSET_URL}/1156/image__10_.png`,
  rewards_earned: `${APP_ASSET_URL}/1165/rewards_icon.png`,
  rewards_used: `${APP_ASSET_URL}/1176/rewards_used.png`,
  rightArrow: `${APP_ASSET_URL}/1166/rail_right_arrow.png`,
  leftArrow: `${APP_ASSET_URL}/1167/rail_left_arrow.png`,
  live_icon: `${APP_ASSET_URL}/1182/live_icon.png`,
};

export default images;
