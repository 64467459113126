import React, { PureComponent } from 'react';
import { View, StyleSheet, Animated, Text } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import NumberCounter from '../../helpers/NumberCounter';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import Header from '../header/Header';
import SortingFilteringOptions from '../sort-filter-options/SortingFilteringOptions';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop, isBlank } from '../../utils/BooleanUtility';

export default class ListsFilter extends PureComponent {
  headerContainer = (props) => {
    const { itemData, scrollY } = this.props;
    let styleObject = {};
    if (Utility.isPresent(scrollY)) {
      styleObject = {
        transform: [
          {
            translateY: scrollY.interpolate({
              inputRange: [0, 400],
              outputRange: [0, -100],
            }),
          },
        ],
        opacity: scrollY.interpolate({
          inputRange: [100, 400],
          outputRange: [1, 0],
        }),
      };
    }
    return (
      <Animated.View style={styleObject}>
        <Header item={itemData} />
      </Animated.View>
    );
  };

  renderCounter = ({
    counter_max_value,
    counter_current_value,
    counter_suffix,
  }) => {
    if (counter_max_value <= 0) {
      return null;
    }
    return (
      <NumberCounter
        counter_max_value={counter_max_value}
        counter_current_value={counter_current_value}
        counter_suffix={counter_suffix}
        showRedColor
      />
    );
  };

  sortingFilteringOptionsRef = (ref) => {
    const { getQuickFiltersListRef, quickFiltersRef } = this.props;
    this.quickFiltersRef = ref;
    getQuickFiltersListRef(this.quickFiltersRef);
  };

  onTapQuickFilters = (item, added, index, resetQuickFilter) => {
    const { onFiltersPress = () => {} } = this.props;
    onFiltersPress(item, added, index, resetQuickFilter);
  };

  render() {
    const {
      getQuickFiltersListRef,
      scrollY,
      itemData: { quick_filters = [], filters = [], name = '' },
      selectedFilters,
      quickFiltersRef,
      onFiltersPress = () => {},
      index,
      counter_max_value,
      counter_current_value,
      counter_suffix,
      combo_offer,
      showPersonalisedDetails,
      navigation,
      filtersFromDeepLink = [],
      previousScreen = '',
    } = this.props;
    if (isBlank(quick_filters) && isBlank(filters)) return null;
    const isFoxyMatchFilterSelected =
      Utility.getIndexOfFoxyMatch(selectedFilters) !== -1;
    let rootViewStyle = {
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: Utility.isBlank(scrollY) ? '#ffffff' : '',
    };
    if (!combo_offer) {
      rootViewStyle = {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: Utility.isBlank(scrollY) ? '#ffffff' : '',
        height: 55,
        marginLeft: isDesktop() ? 25 : 0,
      };
    }
    if (isDesktop()) {
      rootViewStyle.width = getScreenWidth();
      rootViewStyle.alignSelf = 'center';
      rootViewStyle.boxShadow = '0px 1px 5px 1px rgba(229,229,229,0.8)';
    }
    let dividerStyles = [];

    if (Utility.isPresent(scrollY)) {
      rootViewStyle = [
        rootViewStyle,
        {
          backgroundColor: scrollY.interpolate({
            inputRange: [0, index * 100],
            outputRange: ['#ffffff00', '#ffffff'],
          }),
        },
      ];

      dividerStyles = [
        styles.divider,
        {
          opacity: scrollY.interpolate({
            inputRange: [100, 500],
            outputRange: [0, 1],
          }),
        },
      ];
    }
    return (
      <Animated.View style={rootViewStyle}>
        {!!combo_offer && (
          <View style={styles.quickFiltersHeadingContainer}>
            <Text
              style={styles.quickFiltersTitle}
              numberOfLines={1}
              ellipsizeMode='tail'
            >
              {name}
            </Text>
            <this.renderCounter
              counter_max_value={counter_max_value}
              counter_current_value={counter_current_value}
              counter_suffix={'added'}
            />
          </View>
        )}
        {/* <this.headerContainer /> */}
        {Utility.isPresent(quick_filters) && (
          <SortingFilteringOptions
            ref={this.sortingFilteringOptionsRef}
            quickFilters={quick_filters}
            filters={filters}
            // ignoreChipTap
            unselectedChipBackgroundColor={
              Config.FILTER_MODAL_UNSELECTED_CHIP_BACKGROUND_COLOR
            }
            selectedChipBackGroundColor={
              Config.FILTER_MODAL_SELECTED_CHIP_BACKGROUND_COLOR
            }
            onSortingFilterApplied={() => {}}
            onQuickFilterPress={this.onTapQuickFilters}
            selectedFilters={selectedFilters}
            showPersonalisedDetails={showPersonalisedDetails}
            navigation={navigation}
            isFoxyMatchFilterSelected={isFoxyMatchFilterSelected}
            filtersFromDeepLink={filtersFromDeepLink}
            previousScreen={previousScreen}
          />
        )}

        <Animated.View style={dividerStyles} />
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  divider: {
    position: 'absolute',
    height: 1,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.border,
  },
  quickFiltersContainer: { marginTop: 10 },
  quickFiltersHeadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 12,
    marginBottom: 8,
    marginTop: 16,
  },
  quickFiltersTitle: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginLeft: 16,
    width: '70%',
  },
});
