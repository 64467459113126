import StyleSheet from 'react-native-media-query';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { isDesktop } from '../../utils/BooleanUtility';

export const cardHeight = (Utility.getScreenWidth() - 24) / 1.32;
export const { ids, styles } = StyleSheet.create({
  flexC: { marginVertical: 8 },
  featureContainer: {
    height: 120,
    width: Utility.getDynamicWidth(1, 0),
    flex: 1,
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
  featureCardButton: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    right: -1,
    height: 36,
    alignSelf: 'center',
    backgroundColor: colors.foxyPink,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  exploreButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.background,
  },
  featureImageAndDescContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    marginRight: 0,
  },
  featureImage: {
    marginLeft: 19,
    marginRight: 15,
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 65,
    height: 65,
    borderRadius: 3,
  },
  newFeatureDescriptionContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: 0,

    width: '80%',
  },
  featureHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 12,
    marginRight: 16,
  },
  featureSubHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 4,
    marginRight: 0,
    width: '80%',
  },
  featureCancelContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 15,
    height: 15,
  },
  featureCross: { tintColor: '#9D9D9D', height: 15, width: 15 },

  selfieFeatureCardContainer: {
    height: 80,
    width: Utility.getDynamicWidth(1, 0),
    flexDirection: 'row',
    marginLeft: Utility.padding,
    justifyContent: 'center',
    marginRight: Utility.padding,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
  selfieImageStyle: {
    height: 50,
    width: 50,
  },

  selfieCardColumn: {
    flexDirection: 'column',
  },
  centerAlignedRow: { flexDirection: 'row', alignItems: 'center' },
  chevronContainer: {
    borderRadius: 30,
    backgroundColor: colors.foxyBlack,
    justifyContent: 'center',
    padding: 5,
    height: 15,
    width: 15,
    right: 10,
    alignItems: 'center',
  },

  chevronStyle: { height: 12, width: 12, tintColor: colors.white },
  followCardContainer: {
    height: 130,
    width: '100%',
    flex: 1,
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
    padding: Utility.padding,
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    marginLeft: 12,
    fontSize: size.h2,
  },

  followCardDescriptionText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
    marginTop: 8,
  },
  buttonContainer: {
    height: 25,
    borderWidth: 1,
    width: 90,
    borderColor: colors.foxyPink,
    backgroundColor: colors.foxyPink,
    borderRadius: 4,
    // marginTop: 10,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },

  followButtonText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,

    color: colors.white,
  },
  largeBanner: {
    height: cardHeight,
    borderColor: colors.border,
    backgroundColor: colors.white,
    borderRadius: 4,
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    marginHorizontal: Utility.padding,
    marginVertical: 8,
    '@media (min-width: 800px)': {
      width: Utility.getScreenWidth(),
      height: cardHeight / 2 - 30,
      marginVertical: 0,
      alignSelf: 'center',
    },
    ':hover': {
      transform: 'scale(0.98)',
    },
  },
  largeBannerRail: {
    height: cardHeight,
    borderColor: colors.border,
    backgroundColor: colors.white,
    borderRadius: 4,
    width: Utility.getScreenWidth() - 58,
    marginHorizontal: 8,
    marginVertical: 8,
  },
  postBanner: {
    // height: cardHeight,
    borderColor: colors.border,
    backgroundColor: colors.white,
    borderRadius: 4,
    width: Utility.getScreenWidth() - 48,
    marginHorizontal: Utility.padding,
    marginVertical: 8,
    flexDirection: 'row',
    alignSelf: 'center',
  },
  largeBannerImage: {
    height: (Utility.getScreenWidth() - 24) / 1.7,
    overflow: 'hidden',
    borderColor: colors.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  largeBannerImageDesktop: {
    height: (Utility.getScreenWidth() - 24) / 1.7 / 2,
  },
  postBannerImage: {
    height: (Utility.getScreenWidth() - 24) / 1.7,
    overflow: 'hidden',
    borderColor: colors.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '40%',
    borderRadius: 8,
  },
  referralLargeBannerImage: {
    height: cardHeight,
    overflow: 'hidden',
    borderColor: colors.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  largeBannerBottomView: {
    backgroundColor: colors.black,
    height: 40,
  },
  bannerDescriptionStyle: {
    height: (Utility.getScreenWidth() - 24) / 6,
  },
  miniBanner: {
    height: 112,
    overflow: 'hidden',
    borderColor: colors.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  referralMiniBannerImage: {
    height: 112,
    borderColor: colors.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  flexContainer: {
    flex: 1,
    '@media (min-width: 800px)': {
      paddingHorizontal: '25%',
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 8,
    },
    marginLeft: isDesktop() ? 12 : 0,
  },
});
