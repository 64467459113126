// Dependencies
import React, { PureComponent } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
// Components
import _ from 'lodash';
import Feature from '../../containers/feature/Feature';
import { ORIENTATION } from '../../config/Constants';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

import ErrorBoundaryComponent from '../shared/ErrorBoundaryComponent';
import { FlatListPerformanceView } from '../../libraries/ReactNativePerformanceShopify';
import { getListSource } from '../../utils/PerfUtility';

const style = StyleSheet.create({
  flatListStyle: {
    width: Utility.getScreenWidth(),
    overflow: 'visible',
    marginBottom: Utility.isAndroid() ? 10 : 0,
  },
  contentContainerStyle: {
    paddingRight: 12,
  },
});

class CardSequence extends PureComponent {
  Components = {
    feature: Feature,
  };

  static getComponentHeight(item) {
    const { objects = [] } = item;
    const itemCount = objects.length;
    if (itemCount === 0) return 0;
    return itemCount * Feature.getComponentHeight(objects[0]);
  }

  getComponent = (item, index) => {
    const { listData, onFoxyLiveRedirection, listIndex } = this.props;
    if (Utility.isBlank(item)) {
      return null;
    }
    const ContainerComponent = this.Components[item.type];
    if (ContainerComponent === null || ContainerComponent === undefined) {
      return null;
    }

    const { navigation, onPress, previousScreen, isRoutineSubscribed } =
      this.props;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          navigation={navigation}
          itemData={item}
          listData={listData}
          listIndex={listIndex}
          id={item.id}
          type={item.type}
          size={item.size}
          orientation={ORIENTATION.VERTICAL}
          layout={item.display}
          onPress={onPress}
          onFoxyLiveRedirection={onFoxyLiveRedirection}
          previousScreen={previousScreen}
          isUserSubscribed={isRoutineSubscribed}
        />
      </ErrorBoundaryComponent>
    );
  };

  getItemLayout = (item, index) => {
    const height = Feature.getComponentHeight(item[index]);
    return {
      length: height,
      offset: height * index,
      index,
    };
  };

  render() {
    const { previousScreen = '' } = this.props;
    const isHorizontal =
      previousScreen === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE ||
      previousScreen === SCREEN_CONSTANTS.PRE_ROUTINE;
    const contentContainerStyle = isHorizontal
      ? style.contentContainerStyle
      : {};
    return (
      <FlatList
        horizontal={isHorizontal}
        data={this.props.item.filter((item) => !!item)}
        renderItem={({ item, index }) => this.getComponent(item, index)}
        keyExtractor={(item) => `${item.type}_${item.id}`}
        style={style.flatListStyle}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={contentContainerStyle}
      />
    );
  }
}

export default CardSequence;
