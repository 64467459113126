import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from '../feature/TallCardStyles';
import Utility from '../../utils/Utility';
import { debounce } from 'lodash';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

export class TallCard extends Component {
  constructor(props) {
    super(props);
    const {
      itemData: {
        image_url = '',
        cta_color,
        background_color,
        arguments: args = {},
      } = {},
    } = props;

    this.debouncedNavigate = debounce(this.onActionPressed, 200, {
      leading: true,
      trailing: false,
    });

    this.newImageUrl = getMinifiedImage(
      image_url,
      styles.tallCardImage.width,
      styles.tallCardImage.height,
    );

    const backgroundColor = Utility.isBlank(background_color)
      ? colors.white
      : background_color;
    this.tallCardInfoStyle = [styles.tallCardInfo, { backgroundColor }];

    this.textStyle = styles.tallCardCtaText;

    let titleColor = cta_color;
    if (Utility.isPresent(args)) {
      titleColor = args?.title_color;
      if (args?.cta_text_color) {
        this.textStyle = [this.textStyle, { color: args?.cta_text_color }];
      }
    }
    this.titleStyle = [styles.tallCardTitle, { color: titleColor }];
    this.ctaStyle = [styles.tallCardCta, { backgroundColor: cta_color }];
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: {
        destination: slug, // webview reads data from destination
        slug,
        extra,
      },
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      listId = '',
      previousScreen,
      listIndex = 0,
      itemData: {
        destination,
        dynamic_destination,
        slug,
        external_link,
        title = '',
      } = {},
      allVideosId = '',
      listContent,
      listSlug = '',
      listDisplay = '',
      listName,
      index,
      parentListsData,
    } = this.props;

    const url = Utility.isPresent(external_link) ? external_link : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listDisplay,
        Utility.isPresent(listName) ? listName : title,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
        AnalyticsUtility.getParentsList(parentListsData),
      );
    }
    const link = `${url}?utm_source=internal&utm_medium=internal`;
    if (Utility.isBlank(dynamic_destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        link,
        this.navigateToScreen,
      );
    } else {
      navigation.push('PaginatedList', {
        id: allVideosId,
        dynamicDestinationSlug: dynamic_destination,
      });
    }
  };

  render() {
    const { itemData: { title = '', cta_text = '' } = {}, index, listData: { objects = [] } = {} } = this.props;
    const tallCardStyling = objects.length - 1 === index ? styles.lastTallCardStyle : styles.tallCardStyle;
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <View style={tallCardStyling}>
          <FastImageView
            source={this.newImageUrl}
            style={styles.tallCardImage}
          />
          <View style={this.tallCardInfoStyle}>
            <Text
              numberOfLines={1}
              ellipsizeMode='tail'
              style={this.titleStyle}
            >
              {title}
            </Text>
            <DebouncedTouchableOpacity
              {...this.props}
              style={this.ctaStyle}
              onPress={this.debouncedNavigate}
            >
              <Text numberOfLines={1} style={this.textStyle}>
                {cta_text}
              </Text>
            </DebouncedTouchableOpacity>
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(TallCard);
