import { NativeModules } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { messaging, analytics } from '../libraries/Firebase';
import AnalyticsManager from '../analytics/AnalyticsManager';
import { getStoreRef } from '../store/StoreUtility';
import RNAdvertisingId from 'react-native-advertising-id';
import { postDeviceInformation } from '../actions/ActionTypes';
import { isAndroid, isBlank, isIOS, isPresent, isWeb } from './BooleanUtility';
import EventType from '../analytics/AnalyticsEventType';

class DeviceInfoUtils {
  static retrieveDeviceInfo = () => {
    if (isWeb()) return;
    NativeModules.DeviceDetails.returnDeviceInfo()
      .then((data) => {
        //FIXME: check if IOS also gives data in string.
        this.sendDeviceInfoToServer(data);
      })
      .catch((error) => console.tron.log(`Error in Promise ${error}`));
  };

  static sendDeviceInfoToServer = (data) => {
    //TODO: refactor this code to check messagin permission in app.js only
    this.checkFcmMessagingPermission().then((tokens = {}) => {
      const deviceDataJson = JSON.parse(data);

      //Update user properties to analytics

      AnalyticsManager.setUserProperty('model', `${deviceDataJson?.model}`);
      AnalyticsManager.setUserProperty(
        'manufacturer',
        deviceDataJson?.manufacturer,
      );
      AnalyticsManager.setUserProperty(
        'os_api_level',
        deviceDataJson?.os_api_level,
      );
      AnalyticsManager.setUserProperty('os', deviceDataJson?.os);
      AnalyticsManager.setUserProperty(
        'os_version',
        deviceDataJson?.os_version,
      );

      if (isPresent(tokens?.fcm_token)) {
        deviceDataJson.fcm_token = tokens.fcm_token;
        AnalyticsManager.setUserProperty('fcm_token', tokens.fcm_token);
      }
      if (isPresent(tokens?.apns_token)) {
        deviceDataJson.apns_token = tokens.apns_token;
        AnalyticsManager.setUserProperty('apns_token', tokens.apns_token);
      }
      if (isPresent(tokens?.pseudo_user_id)) {
        deviceDataJson.pseudo_user_id = tokens.pseudo_user_id;
        AnalyticsManager.setUserProperty(
          'pseudo_user_id',
          tokens.pseudo_user_id,
        );
      }
      if (isPresent(deviceDataJson.ssid)) {
        AnalyticsManager.setUserProperty('ssid', deviceDataJson.ssid);
      }

      RNAdvertisingId.getAdvertisingId()
        .then((response) => {
          deviceDataJson.ad_id = response.advertisingId;

          let userId;

          if (isIOS()) {
            userId = isPresent(deviceDataJson.ssid)
              ? deviceDataJson.ssid
              : deviceDataJson.ad_id;
          } else {
            userId = isPresent(deviceDataJson.ad_id)
              ? deviceDataJson.ad_id
              : deviceDataJson.ssid;
          }

          if (isBlank(userId) || userId?.toLowerCase()?.includes('unknown')) {
            userId = deviceDataJson.pseudo_user_id;
          }
          if (isAndroid()) {
            AnalyticsManager.setUserId(userId);
          }
          AnalyticsManager.setUserProperty('ad_id', deviceDataJson.ad_id);
          getStoreRef().dispatch(
            postDeviceInformation(JSON.stringify(deviceDataJson)),
          );
        })
        .catch((error) => {
          AnalyticsManager.setUserId(deviceDataJson.ssid);
        });
    });
  };

  static checkFcmMessagingPermission = async () => {
    const enabled = await messaging().hasPermission();
    if (!enabled) {
      this.requestFcmMessagingPermission();
    }
    const tokens = {
      fcm_token: (await messaging().getToken()) || '',
      apns_token: (await messaging().getAPNSToken()) || '',
      pseudo_user_id: (await analytics().getAppInstanceId()) || '',
    };
    return tokens;
  };

  static requestFcmMessagingPermission = async () => {
    try {
      await messaging().requestPermission();
      // User has authorised
    } catch (error) {
      // User has rejected permissions
    }
  };
}

export const getDeviceStats = async () => {
  try {
    // Total internal storage
    const totalInternalStorageBytes = await DeviceInfo.getTotalDiskCapacity();
    const totalInternalStorageMB = (totalInternalStorageBytes / (1024 * 1024)).toFixed(2);
    // Free internal storage
    const freeInternalStorageBytes = await DeviceInfo.getFreeDiskStorage();
    const freeInternalStorageMB = (freeInternalStorageBytes / (1024 * 1024)).toFixed(2);

    const totalRamBytes = await DeviceInfo.getTotalMemory();
    const totalRamMB = (totalRamBytes / (1024 * 1024)).toFixed(2);

    const batteryLevel = await DeviceInfo.getBatteryLevel();
    const batteryPercentage = (batteryLevel * 100).toFixed(2); // Convert to a percentage

    // Collect data in an object
    const response = {
      total_internal_storage: totalInternalStorageMB,
      free_internal_storage: freeInternalStorageMB,
      total_ram: totalRamMB,
      battery_percentage: `${batteryPercentage}%`,
    };

    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.INFO_DEVICE_MEMORY, {
      ...response,
    });
  } catch (error) {
    console.error('Error collecting storage and memory info:', error);
  }
};


export default DeviceInfoUtils;
