import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Svg, Circle, Mask, Rect, Defs, Line } from 'react-native-svg';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { CURRENCY_SYMBOL, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { jsonParser } from '../../utils/JsonUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import LinearGradient from 'react-native-linear-gradient';
import images from '../../theme/Images';
import { useNavigation } from '@react-navigation/native';
import { memoize } from 'lodash';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isBlank } from '../../utils/BooleanUtility';

const MaskedCard = ({ fillColor }) => {
  return (
    <Svg height={'100%'} width={'100%'}>
      <Defs>
        <Mask id={`clip`}>
          <Rect fill={colors.white} width='100%' height='100%' />
          <Circle r={'12'} cx={`${getScreenWidth() - 36 - 54}`} cy={'0'} fill={colors.black} />
          <Circle r={'12'} cx={`${getScreenWidth() - 36 - 54}`} cy={'120'} fill={colors.black} />
        </Mask>
      </Defs>
      <Rect width='100%' height='100%' fill={fillColor} mask={`url(#clip)`} />
    </Svg>
  );
};

const TiltedCardContainer = ({ fillColor }) => {
  return (
    <View style={styles.cardContainerTilted}>
      <MaskedCard fillColor={fillColor} />
    </View>
  );
};

const BenefitsText = ({ benefitText }) => {
  return (
    <Text style={styles.verticalRightText} numberOfLines={3} ellipsizeMode={'tail'}>
      {benefitText}
    </Text>
  );
};

const VerticalRightContainer = ({ benefitText, fillColor }) => {
  return (
    <View style={styles.verticalRightContainer}>
      <Svg height={'100%'} width={'100%'}>
        <Defs>
          <Mask id={`clip`}>
            <Rect fill={colors.white} width='100%' height='100%' />
            <Circle r={'12'} cx={'0'} cy={'0'} fill={colors.black} />
            <Circle r={'12'} cx={'0'} cy={'120'} fill={colors.black} />
          </Mask>
        </Defs>
        <Rect width='100%' height='100%' fill={fillColor} mask={`url(#clip)`} />
      </Svg>
      <Svg height={708} width={'100%'}>
        <Line
          x1='0'
          y1='250'
          x2='0'
          y2='340'
          stroke={colors.black}
          strokeWidth='1'
          strokeDasharray='4, 4' // Creates a dashed effect
        />
      </Svg>
      <BenefitsText benefitText={benefitText} />
    </View>
  );
};

const MilestoneGoldenTicket = (props) => {
  const navigation = useNavigation();
  const {
    previousScreen = '',
    itemData: {
      image_url = '',
      arguments: args,
      id = '',
      type = '',
      display = '',
      title = '',
      subtitle = '',
      body = '',
      cta_text = '',
    } = {},
  } = props;
  const itemArguments = isBlank(args) ? {} : args;
  const {
    primary_card_color: primaryCardColor = '#FFBF1F',
    secondary_card_color: secondaryCardColor = '#FFFCEE',
    benefit_text = '',
    remaining_amount = 0,
    total_milestones = 0,
  } = itemArguments;

  const OfferCardContainer = () => {
    return (
      <View style={styles.cardContainer}>
        <MaskedCard fillColor={secondaryCardColor} />
        <VerticalRightContainer benefitText={benefit_text} fillColor={secondaryCardColor} />
        <View style={styles.offerCardContentContainer}>
          <View style={styles.horizontalLeftCard}>
            <View style={styles.leftCardTitleContainer}>
              <Text style={styles.firstTitle}>{body}</Text>
              <Text style={styles.cardTitle}>{title}</Text>
            </View>
            <View style={styles.cardBodyContainer}>
              <Text style={styles.bodyText}>
                {CURRENCY_SYMBOL}
                {remaining_amount} spends away
              </Text>
              <View style={styles.horizontalLine} />
            </View>
            <FastImageView
              style={styles.image}
              source={getMinifiedImage(image_url, styles.image.width, styles.image.height)}
            />
            <View
              style={memoize(
                () => [styles.buttonContainer, { borderColor: primaryCardColor }],
                () => [primaryCardColor],
              )()}
            >
              <Text style={styles.buttonText}>{cta_text}</Text>
            </View>
          </View>
        </View>
        <LinearGradient colors={[primaryCardColor, colors.white]} style={styles.linearContainer}>
          <FastImageView source={images.chevronRightBlack} style={styles.chevronRight} />
        </LinearGradient>
      </View>
    );
  };

  const onPress = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: previousScreen,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_TYPE]: type,
      [EventParameterKey.ITEM_NAME]: title,
      [EventParameterKey.DISPLAY]: display,
    });
    navigation.push('MilestoneGoldenTicketModal', {
      previousScreen,
      total_milestones,
    });
  };

  return (
    <ScaleAnimate {...props} onPress={onPress}>
      <View style={styles.container}>
        <TiltedCardContainer fillColor={primaryCardColor} />
        <OfferCardContainer />
      </View>
    </ScaleAnimate>
  );
};

export default MilestoneGoldenTicket;

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginBottom: 6,
    alignSelf: 'center',
    justifyContent: 'center',
    height: 150,
    width: '100%',
  },
  horizontalLeftCard: {
    position: 'absolute',
    width: getScreenWidth() - 24 - 66,
    height: 120,
    overflow: 'hidden',
  },
  verticalContainerCornerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    position: 'absolute',
    top: -12,
    right: -8,
    backgroundColor: '#5B6986',
  },
  leftCardTitleContainer: {
    marginLeft: 20,
    marginTop: 12,
  },
  firstTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4_5,
    height: 16,
  },
  cardTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h0_24,
  },
  cardBodyContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 4,
  },
  horizontalLine: {
    height: 2,
    width: 33,
    backgroundColor: colors.foxyBlack,
    marginTop: 8,
    marginLeft: 6,
  },
  bodyText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    fontWeight: '400',
    color: colors.foxyBlack,
  },
  image: {
    height: 50,
    width: 50,
    position: 'absolute',
    top: 12,
    right: 10,
  },
  imagePlaceholder: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 12,
    right: 10,
    borderRadius: 20,
  },
  verticalRightText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4_5,
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 2,
    textAlign: 'center',
    transform: [{ rotate: '-90deg' }],
    width: '100%',
  },
  buttonContainer: {
    position: 'absolute',
    borderWidth: 1,
    borderRadius: 16,
    height: 20,
    bottom: 12,
    marginLeft: 20,
  },
  buttonText: {
    paddingHorizontal: 16,
    paddingVertical: 2,
    height: 18,
    alignSelf: 'center',
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontWeight: '500',
    fontSize: size.h5,
  },
  cardContainer: {
    width: getScreenWidth() - 24,
    height: 120,
    alignSelf: 'center',
    backgroundColor: colors.transparent,
    marginVertical: 12,
    borderRadius: 20,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
  },
  cardContainerTilted: {
    width: getScreenWidth() - 24,
    height: 120,
    alignSelf: 'center',
    backgroundColor: colors.transparent,
    marginVertical: 12,
    borderRadius: 20,
    overflow: 'hidden',
    transform: [{ rotate: '-4.0deg' }],
    position: 'absolute',
    top: 0,
  },
  verticalRightContainer: {
    height: 120,
    width: 66,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    // overflow: 'hidden',
    position: 'absolute',
    right: 0,
  },
  offerCardContentContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    backgroundColor: colors.transparent,
  },
  titlePlaceholder: {
    height: 16,
    width: 108,
    borderRadius: 4,
  },
  subTitlePlaceholder: {
    height: 24,
    width: 142,
    marginTop: 12,
    borderRadius: 4,
  },
  buttonPlaceholder: {
    height: 20,
    width: 85,
    borderRadius: 16,
    marginTop: 20,
    left: 20,
  },
  linearContainer: {
    padding: 12,
    borderRadius: 40,
    position: 'absolute',
    right: 78,
    top: 32,
  },
  chevronRight: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
});
